import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, useTheme, alpha } from "@mui/system";
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { useViewInputsQuery, useViewCompChartDataQuery, IViewInput, useUpdateView, useViewQuery, PeriodLookbackType, CompareChartType } from '../../api/views';
import { getIndexColor } from "../../components/Charts/utils"
import {
	ArcElement,
	BarElement,
	// CategoryScale,
	Chart as ChartJS,
	Legend,
	LinearScale,
	LineElement,
	PointElement, TimeScale, TimeSeriesScale, Title,
	Tooltip
} from 'chart.js';
import LinearProgress from "@mui/material/LinearProgress";
import { Chart } from "react-chartjs-2";
import annotationPlugin from "chartjs-plugin-annotation"
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

ChartJS.register(
	LinearScale,
	TimeScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	BarElement,
	ArcElement,
	annotationPlugin

);



function CompareChart(props: {
	view_id: number;
	input_id: number;
	getChartColor: (label: string, index: number) => string;
	chartType: CompareChartType;
	periodLookback: PeriodLookbackType;
	displayLegend?: boolean;
	displayXAxis?: boolean;
	displayYAxis?: boolean;
	disableAnimations?: boolean;
	getDataColor?: (value: number, label: string) => string,
}) {

	const chartDataQuery = useViewCompChartDataQuery(props.view_id, props.input_id,
		props.periodLookback, props.chartType);

	const theme = useTheme();
	const displayLegend =
		props.displayLegend === undefined ? true : props.displayLegend; // default prop to true
	const displayXAxis =
		props.displayXAxis === undefined ? true : props.displayXAxis; // default prop to true
	const displayYAxis =
		props.displayYAxis === undefined ? true : props.displayYAxis; // default prop to true

	const disableAnimations =
		props.disableAnimations === undefined ? false : props.disableAnimations; // default prop to true

	const getDataColor = props.getDataColor === undefined ? (val: number, label: string) => "white" : props.getDataColor; // default

	if (chartDataQuery.isLoading || chartDataQuery.isFetching || !chartDataQuery.data) {
		return (
			<Box sx={{ width: "100%" }}>
				<LinearProgress />
			</Box>
		);
	}

	const allDataMissing = chartDataQuery.data?.datasets.filter((x) => x.data.length > 0).length === 0;

	if (allDataMissing) {
		return (
			<Typography color="text.secondary" sx={{ mt: 1, }}>No data available</Typography >
		)
	}

	// if there are negative values show a wrning, and dont fix scale at 0 min
	const hasNegatives = Math.min(...chartDataQuery.data?.datasets.map((x) => Math.min(...x.data.map(value => value.y))) || 0) < -0.001;

	const chartDatasets = chartDataQuery.data?.datasets.map((x, i) => ({
		...x,
		// for line types, we pass a single color for the entire line
		borderColor: props.getDataColor
			? x.data.map((val) => getDataColor(val, x.label))
			: props.getChartColor(x.label, i),
		backgroundColor: props.getDataColor
			? x.data.map((val) => getDataColor(val, x.label))
			: alpha(props.getChartColor(x.label, i), 0.70),

		type: "line" as any,
		pointRadius: 0,
		borderWidth: 2,
		// fill for mkt share or stacked area
		fill: props.chartType === "mkt_share" || props.chartType === "stacked" ? true : false,
	}));

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				display: displayLegend,
				position: "top" as any,
				labels: {
					color: theme.palette.text.primary,
					boxHeight: 5,
					boxWidth: 30,
				},
			},
			// title: {
			// 	display: false,
			// 	// display: props.title !== undefined ? true : false,
			// 	// text: props.title,
			// 	color: theme.palette.text.secondary,
			// 	// font: { size: 14.8, weight: "400", lineHeight: 1.75 },
			// 	// align: 'start' as const,
			// 	// padding: 2
			// },
			tooltip: {
				// mode: "nearest" as any,
				mode: 'index',
				// position: 'average',
				intersect: false,
			},
			// hover: {
			//     mode: 'nearest' as any,
			//     // intersect: false
			// },
			// interaction: {
			//     mode: 'x'
			// }

			// annotation: {
			// 	// if has negative values for mkt share or stacked, display a warning annotation
			// 	annotations: (hasNegatives && (props.chartType === "mkt_share" || props.chartType === "stacked")

			// 	) ? [
			// 		{
			// 			type: 'label',
			// 			content: 'Warning: Negative values may not render properly for stacked charts',
			// 			// backgroundColor: theme.palette.warning.light,
			// 			color: theme.palette.warning.light,
			// 			// position: 'right',
			// 			font: {
			// 				size: 11,
			// 			},
			// 			display: true,

			// 		}
			// 	] : [],
			// },
		},

		scales: {
			x: {
				type: 'time' as any,
				parsing: false,
				time: {
					unit: 'day',
					tooltipFormat: 'M/d/yyyy',
				},
				grid: {
					display: false,
					drawOnChartArea: true,
					color: theme.palette.divider,
				},
				ticks: {
					display: displayXAxis,
					color: theme.palette.text.secondary,
					font: {
						size: 10,
					},
				},
			},
			y: {
				position: "left",
				parsing: false,
				stacked: props.chartType === "mkt_share" || props.chartType === "stacked" ? "single" : false,
				grid: {
					display: true,
					color: theme.palette.divider,
				},
				ticks: {
					display: displayYAxis,
					color: theme.palette.text.primary,
					fontSize: 6,
				},
				max: props.chartType === "mkt_share" ? 100 : undefined,
				min: (props.chartType === "mkt_share" || props.chartType === "stacked") && !hasNegatives ? 0 : undefined,
			},
		},

		animation: {
			duration: disableAnimations ? 0 : 800, // general animation time
		},
		responsiveAnimationDuration: 0, // animation duration after a resize
	};

	return (
		<Chart
			type='line'
			options={options}
			data={{
				datasets: chartDatasets || []
			}
			}
		/>
	);
}




function InputFieldSelect(props: {
	selectedInputs: IViewInput[],
	tabValue: number,
	setTabValue: React.Dispatch<React.SetStateAction<number>>,
	fontSize?: string,
}) {

	const displayCompany = props.selectedInputs.find(i => i.id === props.tabValue);
	const [searchParams, setSearchParams] = useSearchParams();

	if (displayCompany !== undefined) {
		return (

			<Autocomplete
				options={props.selectedInputs}
				getOptionLabel={(option) => option.name || ""}
				isOptionEqualToValue={(option, value) => option.id === value.id}
				filterSelectedOptions
				disableClearable
				size="small"
				sx={{
					width: 300,
					input: {
						fontSize: props.fontSize || "medium",
					},
				}}
				componentsProps={{
					paper: {
						sx: {
							minWidth: 250
						}
					}
				}}
				value={displayCompany}
				onChange={(event: React.SyntheticEvent, newValue) => {
					if (newValue) {
						props.setTabValue(newValue.id);

						// set search param so we save the ticker selection
						searchParams.set("input", String(newValue.id));
						setSearchParams(searchParams);
					}
				}}
				renderInput={(params) => (
					<TextField
						{...params}
						placeholder="Select Data"
					/>
				)}
			/>
		)

	}

	return (
		<></>
	)

}





export default function ViewCompareChart(props: {
	view_id: number,
	title?: string,
	ToolbarComponent?: JSX.Element,
	compact?: boolean,
}) {

	const [searchParams, setSearchParams] = useSearchParams();
	const viewInputsQuery = useViewInputsQuery(props.view_id);

	const inputIdTabSelect: number = parseInt(searchParams.get("input") || "0");
	const [tabValue, setTabValue] = useState<number>(inputIdTabSelect);

	const compact = props.compact === undefined ? false : props.compact;

	// get and set the chart type from the view query
	const viewQuery = useViewQuery(props.view_id);
	const updateView = useUpdateView(props.view_id);

	const compareChartType: CompareChartType = viewQuery.data?.params?.compare_chart_type || "line";

	// once we get the data, update the selected ticker tab if no selection is set (e.g. first time on page)
	useEffect(() => {
		const inputsData = viewInputsQuery.data;

		if (inputsData && inputsData.length > 0) {

			const inputExists = inputsData.some((el: any) => el.id === tabValue);

			if (tabValue === 0 || !inputExists) {
				setTabValue(inputsData[0].id);

				searchParams.set("input", String(inputsData[0].id));
				setSearchParams(searchParams);

			}

		}
	}, [viewInputsQuery.data])

	if (tabValue === 0 || viewQuery.isLoading) {
		return <></>;
	}

	return (
		<Box sx={{

			height: "95%",
			width: '100%',

		}}>
			<Stack direction="row" justifyContent="space-between"
				alignItems="center">
				{props.title && <Typography>{props.title}</Typography>}

				<Stack direction="row" spacing={1}>
					{(viewInputsQuery.data && viewInputsQuery.data.length > 0) &&
						<InputFieldSelect tabValue={tabValue}
							setTabValue={setTabValue}
							selectedInputs={viewInputsQuery.data}
							// fontSize={compact ? "small" : "large"}
							fontSize={"small"}
						/>
					}

					{/* add select for chart type (line, stacked area, or market share) */}
					{props.compact !== true &&
						<Select
							displayEmpty={true}
							value={compareChartType}
							variant="outlined"
							size="small"
							sx={{ mr: 1 }}
							onChange={(event: SelectChangeEvent<string>) => {
								if (event.target.value !== "") {
									const newChartType = event.target.value as CompareChartType;
									updateView.mutate({ params: { compare_chart_type: newChartType } });
								}
							}}

						>
							<MenuItem disabled value={""}>
								<em>Chart Type</em>
							</MenuItem>

							<MenuItem value={"line"}>Line Chart</MenuItem>
							<MenuItem value={"stacked"}>Stacked Chart</MenuItem>
							<MenuItem value={"mkt_share"}>Market Share</MenuItem>

						</Select>
					}

					{props.ToolbarComponent && props.ToolbarComponent}
				</Stack>

			</Stack>

			<CompareChart
				view_id={props.view_id}
				input_id={tabValue}
				chartType={compareChartType}
				periodLookback={viewQuery.data?.period_lookback || "1y"}
				getChartColor={getIndexColor}
				displayLegend={true}
				disableAnimations={true}
			/>

		</Box >

	)

}
