import React from 'react'
// import useSettings from 'app/hooks/useSettings'
import { createTheme } from '@mui/material/styles'
import { red } from '@mui/material/colors';
import { Autocomplete } from '@mui/material';

declare module '@mui/material/styles' {
    interface Theme {
        status: {
            danger: string;
        };
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
        status?: {
            danger?: string;
        };
    }

    interface Palette {
        card: {
            background: string | undefined;
        }
    }
    interface PaletteOptions {
        card: {
            background: string | undefined;
        }
    }


}

export const DarkTheme = createTheme({
    status: {
        danger: red[500],
    },
    palette: {
        mode: 'dark',

        // custom dark background
        // background: {
        //     default: 'gray',
        // },
        background: {
            // paper: '#1A2027'
        },

        card: {
            background: '#1A2027'
        }
    },
    typography: {
        "fontFamily": `"Segoe UI", "Roboto", "Helvetica", "Arial", sans-serif`,
        "fontSize": 13,
        "button": {
            textTransform: 'none'
        }
        // "fontWeightLight": 300,
        // "fontWeightRegular": 400,
        // "fontWeightMedium": 500
    },


    components: {
        MuiDialog: {
            styleOverrides: {
                paper: {
                    backgroundColor: '#1A2027'
                }
            }

        },
        // add border to AutoComplete paper
        MuiAutocomplete: {
            styleOverrides: {
                paper: {
                    border: '1px solid',
                    borderColor: "rgba(255, 255, 255, 0.25)",
                }
            }
        }
    }

});
