import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Button from '@mui/material/Button';

const LoginAuth0Button = () => {
	const { loginWithRedirect } = useAuth0();

	return (

		<Button
			variant="outlined"
			color="info"
			type="submit"
			size="large"
			onClick={() => loginWithRedirect()}
			sx={{ width: 140 }}
		>
			Log in
		</Button>
	);

};

export default LoginAuth0Button;