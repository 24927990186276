import React from 'react';
import { GridColumns } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { createSvgIcon } from '@mui/material/utils';

const ExportIcon = createSvgIcon(
	<path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
	'SaveAlt',
);



export default function ExportCSVButton(props: {
	rows: any[],
	columns: { headerName?: string, field: string }[],
	fileName: string,
}) {

	function handleExportCSV() {

		if (!props.rows || !props.columns) {
			return;
		}
		const csvContent = [
			props.columns.map(col => col.headerName).join(','), // header row
			...props.rows.map(row => props.columns.map(col => row[col.field]).join(',')) // data rows
		].join('\n');

		// Create a Blob from the CSV content
		const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
		const url = URL.createObjectURL(blob);

		// Create a temporary link element
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', `${props.fileName}.csv`); // file name for download
		document.body.appendChild(link);
		link.click();

	}


	return (
		<Tooltip title="Export CSV">
			<IconButton onClick={handleExportCSV} color="primary">
				<ExportIcon />
			</IconButton>
		</Tooltip>
	)
}


