import apiClient from "../services/apiClient";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { IAlertTriggered, IAlert } from "../interfaces/alerts"

interface IUserSelection {
	[key: string]: number
}

// interface INewUserSelection {
// 	component: string;
// 	value: number;
// }

export function useUserSelectionQuery() {

	async function getUserSelection() {
		const { data } = await apiClient.get(`/user-selection/`)
		// turn data into key value lookup (from array of objects)
		// { 'key': value }
		const userSelection: IUserSelection = {}
		data.forEach((item: any) => {
			userSelection[item.component] = item.value
		})

		return userSelection
	}

	return useQuery<IUserSelection>(['user_selection'], getUserSelection,
		{ refetchOnWindowFocus: false, refetchOnMount: true, staleTime: Infinity }
	);
}



export function useUpdateUserSelection() {

	async function updateUserSelection(selection: IUserSelection) {

		const { data } = await apiClient.post('/user-selection/', selection);
		return data
	}

	const queryClient = useQueryClient()

	return useMutation((selection: IUserSelection) =>
		updateUserSelection(selection),
		{

			onMutate: async selection => {
				// Cancel any outgoing refetches (so they don't overwrite our optimistic update)
				await queryClient.cancelQueries(['user_selection'])

				// Snapshot the previous value
				const previousCompanies = queryClient.getQueryData(['user_selection'])

				// Optimistically update to the new value
				queryClient.setQueryData(['user_selection'], (old: any) => {
					// update key value pair, with new selection
					return { ...old, ...selection }
				})

				// Return a context object with the snapshotted value
				return { previousCompanies }
			},

			onSuccess: () => {
				queryClient.invalidateQueries(['user_selection']);
			},

		}
	)

}
