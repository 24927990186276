import { green, grey, red } from '@mui/material/colors';
import { alpha, Box, styled } from '@mui/system';
import { DataGrid, GridToolbarExport, GridCellParams, gridClasses, GridColDef, GridRenderCellParams, GridToolbar, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import clsx from 'clsx';
import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useModelCorrelationsQuery, useModelInputOptionsAllQuery } from "../../api/models";
import { renderScoreCell } from "../Tables/renderCellFunctions"
import Button from '@mui/material/Button';
import { ModelCorrChartDialog } from "../../components";

const ODD_OPACITY = 0.13;

const FeatureDataGrid = styled(DataGrid)(({ theme }) => ({

    // '& .MuiDataGrid-cell:focus-within': {
    //     outline: 'none'
    // },

    '& .MuiDataGrid-columnHeaders': {
        // backgroundColor: '#121212ba'
        backgroundColor: alpha(theme.palette.card.background, 0.73)
    },

    '& .super-app': {
        // fontWeight: '500',
        fontSize: 13,
    },
    '& .super-app.negative-1': {
        color: red[300],
    },
    '& .super-app.negative-2': {
        color: red[300],
    },
    '& .super-app.negative-3': {
        color: red[400],
    },
    '& .super-app.negative-4': {
        color: red[400],
    },
    '& .super-app.negative-5': {
        color: red[500],
    },

    '& .super-app.positive-1': {
        color: green[100],
    },
    '& .super-app.positive-2': {
        color: green[200],
    },
    '& .super-app.positive-3': {
        color: green[300],
    },
    '& .super-app.positive-4': {
        color: green[400],
    },
    '& .super-app.positive-5': {
        color: green[500],
    },


    '& .super-app.std-1': {
        color: green[600],
    },
    '& .super-app.std-2': {
        color: green[600],
    },
    '& .super-app.std-3': {
        color: green[500],
    },
    '& .super-app.std-4': {
        color: green[500],
    },
    '& .super-app.std-5': {
        color: green[400],
    },
    '& .super-app.std-6': {
        color: green[400],
    },
    '& .super-app.std-7': {
        color: green[300],
    },
    '& .super-app.std-8': {
        color: green[200],
    },
    '& .super-app.std-9': {
        color: green[100],
    },
    '& .super-app.std-10': {
        color: green[100],
    },
    '& .super-app.std-11': {
        color: theme.palette.text.secondary,
    },
    [`& .${gridClasses.row}.even`]: {
        backgroundColor: theme.palette.mode === 'dark' ? '#161a20' : grey[300],
        '&:hover, &.Mui-hovered': {
            backgroundColor: alpha(theme.palette.mode === 'dark' ? '#161a20' : grey[300], ODD_OPACITY),
        },
        '&.Mui-selected': {
            backgroundColor: alpha(
                theme.palette.primary.main,
                ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
            '&:hover, &.Mui-hovered': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    ODD_OPACITY +
                    theme.palette.action.selectedOpacity +
                    theme.palette.action.hoverOpacity,
                ),
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        ODD_OPACITY + theme.palette.action.selectedOpacity,
                    ),
                },
            },
        },
    },

}))



function getCellClassName(val: number) {
    if (val < -0.80) { return "negative-5" }
    else if (val < -0.60) { return "negative-4" }
    else if (val < -0.40) { return "negative-3" }
    else if (val < -0.20) { return "negative-2" }
    else if (val < 0) { return "negative-1" }
    else if (val < 0.20) { return "positive-1" }
    else if (val < 0.40) { return "positive-2" }
    else if (val < 0.60) { return "positive-3" }
    else if (val < 0.80) { return "positive-4" }
    else if (val <= 1.0) { return "positive-5" }
    else { return "inherit" }

}

function getCellClassNameStd(val: number) {
    if (val < 0.01) { return "std-1" }
    else if (val < 0.02) { return "std-2" }
    else if (val < 0.03) { return "std-3" }
    else if (val < 0.04) { return "std-4" }
    else if (val < 0.05) { return "std-5" }
    else if (val < 0.06) { return "std-6" }
    else if (val < 0.07) { return "std-7" }
    else if (val < 0.08) { return "std-8" }
    else if (val < 0.09) { return "std-9" }
    else if (val <= 0.10) { return "std-10" }
    else { return "std-11" }

}


function renderSymbolCell(params: GridRenderCellParams) {

    // Name links to Model/Company drilldown page. Pass in URL param with Ids

    return (
        <Button
            size="large"
            color="primary"
            component={Link}
            to={`/company/${params.row.company_id}?metric=${params.row.metric_id}&display=forecasts`}
            variant="text">
            {params.value}
        </Button>
    )

}


const baseColumns: GridColDef[] = [
    { field: 'symbol', headerName: 'Symbol', flex: 2, renderCell: renderSymbolCell },
    { field: 'metric', headerName: 'Metric', flex: 2 },
];

const endColumns: GridColDef[] = [
    { field: 'fit_score', headerName: 'Combined Fit Score', renderCell: renderScoreCell, flex: 2 },
];






function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarQuickFilter
                debounceMs={500}
                // variant="outlined"
                placeholder="Search all tickers and metrics ..."
                fullWidth={true}
                sx={{ width: "40%" }}
                autoComplete='off'
            />

            <Box sx={{ marginLeft: "auto" }}>
                <GridToolbarExport size="medium" printOptions={{ disableToolbarButton: true }} csvOptions={{ fileName: 'Correlations' }}
                />
            </Box>

        </GridToolbarContainer>
    );
}


interface IChartDialogProps {
    isOpen: boolean,
    company_id: number,
    metric_id: number,
    field_id: number,
    titleHeader: string,
}

export default function ModelCorrTable(props: {
    model_map_id: number
}) {

    const [searchParams, setSearchParams] = useSearchParams(); // ex: period comp/max period settings

    const period_compare = searchParams.get("period_compare") || '1';
    const max_periods = searchParams.get("max_periods") || '8';
    const statistic = searchParams.get("statistic") || 'corr';
    const watchlist_id: number = parseInt(searchParams.get("watchlist") || "0");

    const modelInputOptionsAllQuery = useModelInputOptionsAllQuery();

    const modelCorrelationsQuery = useModelCorrelationsQuery(props.model_map_id,
        {
            ...period_compare && { period_compare: period_compare },
            ...statistic && { statistic: statistic },
            ...(max_periods && parseInt(max_periods)) && { max_periods: max_periods },
            ...(watchlist_id && watchlist_id > 0) && { watchlist_id: watchlist_id },
        });


    const [chartDialog, setChartDialog] = React.useState<IChartDialogProps>({
        isOpen: false,
        company_id: 0,
        metric_id: 0,
        field_id: 0,
        titleHeader: ""
    });

    function getFieldIdFromName(fieldName: string) {
        const field = modelInputOptionsAllQuery.data?.find((field) => field.name === fieldName);
        return field?.id;
    }


    function renderFeatureCell(params: GridRenderCellParams) {
        if (params.value === undefined || params.value === null) {
            return <></>;
        }

        let cellValue = "";

        if (statistic === 'corr') {
            cellValue = params.value.toFixed(2);
        }

        cellValue = (params.value * 100).toFixed(1) + "%";

        const fieldName = params.colDef.field.slice(6);
        const field_id = getFieldIdFromName(fieldName);

        if (!field_id) {
            return <span>{cellValue}</span>;
        }

        return (
            <span
                className="corr-cell"
                onClick={() => {
                    setChartDialog({
                        isOpen: true,
                        company_id: params.row.company_id,
                        metric_id: params.row.metric_id,
                        field_id: field_id,
                        titleHeader: params.row.symbol + " - " + params.row.metric + ' / ' + fieldName
                    });
                }}
            >
                {cellValue}
            </span>
        )

    }


    function featureCellClassName(params: GridCellParams<number>) {

        if (params.value == null) {
            return '';
        }

        if (statistic === 'corr') {
            return clsx('super-app', getCellClassName(params.value));
        } else {
            return clsx('super-app', getCellClassNameStd(params.value));
        }
    }

    // get column names from row data
    const corrColNames = modelCorrelationsQuery.data ? Object.keys(modelCorrelationsQuery.data[0]).filter(col => col.startsWith("corr__")) : [];
    const feature_cols: GridColDef[] = corrColNames.map(function (col) { return { field: col, headerName: col.replace('corr__', ''), flex: 1, cellClassName: featureCellClassName, renderCell: renderFeatureCell, type: "number" } });

    return (

        <>
            <div style={{ display: 'flex', height: '90%' }}>
                <div style={{ flexGrow: 1 }}>

                    <FeatureDataGrid
                        rows={modelCorrelationsQuery.data || []}
                        loading={modelCorrelationsQuery.isLoading || modelCorrelationsQuery.isFetching}
                        columns={feature_cols ? baseColumns.concat(feature_cols, endColumns) : []}
                        density="compact"
                        autoHeight={false}
                        hideFooter={true}
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                        }

                        sx={{
                            '& .MuiDataGrid-cell:focus-within': {
                                outline: 'none'
                            },
                            // for corr-cell,
                            '& .corr-cell': {
                                cursor: 'pointer',
                                // on hover make underline
                                '&:hover': {
                                    fontWeight: 500,
                                },

                            },
                        }}
                        components={{ Toolbar: CustomToolbar }}
                    />

                </div>
            </div>


            <ModelCorrChartDialog
                isOpen={chartDialog.isOpen}
                company_id={chartDialog.company_id}
                metric_id={chartDialog.metric_id}
                field_id={chartDialog.field_id}
                titleHeader={chartDialog.titleHeader}
                max_periods={parseInt(max_periods) || 8}
                handleClose={() => setChartDialog({ ...chartDialog, isOpen: false })}
            />

        </>

    );

};
