import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTrendDataOptionsQuery, useCompaniesWithFieldId } from "../../api/reference";


export default function CompanyDataSelections(props: { parent_company_id: number },) {

	const parent_company_id = props.parent_company_id;

	const [searchParams, setSearchParams] = useSearchParams();

	const trend_field_id: number = parseInt(searchParams.get("trend_field") || "0");
	const segment_id: number = searchParams.get("segment") ? parseInt(searchParams.get("segment") || "0") : parent_company_id; // default to parent company

	//queries
	const companiesWithFieldIdQuery = useCompaniesWithFieldId(parent_company_id, trend_field_id);
	const trendDataOptionsQuery = useTrendDataOptionsQuery(parent_company_id);

	const selectedTrendField = trendDataOptionsQuery.data?.find((option) => option.id === trend_field_id);
	const selectedSegment = companiesWithFieldIdQuery.data?.find((option) => option.id === segment_id);

	// choose default field id for trends
	useEffect(() => {

		const fieldTrendData = trendDataOptionsQuery.data;
		if (fieldTrendData && fieldTrendData.length > 0) {
			const fieldExists = fieldTrendData.some((el: any) => el.id === trend_field_id);
			if (trend_field_id === 0 || !fieldExists) {
				searchParams.set("trend_field", String(fieldTrendData[0].id));
				setSearchParams(searchParams);
			}

		}
	}, [props.parent_company_id, trend_field_id, trendDataOptionsQuery.data]);


	// choose default segment id for trends
	useEffect(() => {

		const segmentData = companiesWithFieldIdQuery.data;
		if (segmentData && segmentData.length > 0) {
			const segmentExists = segmentData.some((el: any) => el.id === segment_id);
			if (segment_id === 0 || !segmentExists) {
				searchParams.delete("segment");
				setSearchParams(searchParams);
			}

		}
	}, [props.parent_company_id, segment_id, companiesWithFieldIdQuery.data]);


	return (

		<Stack direction='row' spacing={2}
			sx={{
				mb: 1,
				borderLeft: "1px solid",
				borderLeftColor: (theme) => theme.palette.divider,
				pl: 2
			}}
		>

			{(selectedTrendField && trend_field_id > 0 && trendDataOptionsQuery.data && !trendDataOptionsQuery.isLoading) &&
				<Autocomplete
					disableClearable
					options={trendDataOptionsQuery.data || []}
					getOptionLabel={(option) => option.name}
					value={selectedTrendField}
					onChange={(event, newValue) => {
						searchParams.set("trend_field", String(newValue.id));
						setSearchParams(searchParams);
					}}
					groupBy={(option) => option.vendor_name}

					isOptionEqualToValue={(option, value) => value.id === option.id}
					ChipProps={{ deleteIcon: <></> }}
					sx={{ width: 250 }}
					renderInput={(params) => (
						<>
							<TextField
								{...params}
								hiddenLabel
								label={"Dataset"}
								// variant="filled"
								// size="small"
								color="info"
								sx={{
									fontSize: "small",

									'.MuiInputBase-input': {
										fontSize: 'small',
									},
								}}
							/>
						</>

					)}

					renderOption={(props, option) => (
						<Stack component="li" direction="row" spacing={1}  {...props}>
							<Typography sx={{
								whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"
							}} fontSize={13} color="text.primary">{option.name}</Typography>

						</Stack>
					)}

				/>

			}


			{/* if more than one company, show selection for sub company */}
			{(selectedSegment && segment_id > 0 && companiesWithFieldIdQuery.data && companiesWithFieldIdQuery.data.length > 1 && !companiesWithFieldIdQuery.isLoading) &&
				<Autocomplete
					disableClearable
					options={companiesWithFieldIdQuery.data || []}
					getOptionLabel={
						// add - PARENT suffix, if parent co is selected
						(option) => option.id === parent_company_id ? "PARENT" : option.symbol
					}
					value={selectedSegment}
					onChange={(event, newValue) => {
						if (newValue.id === parent_company_id) {
							// if parent is selected, remove segment
							searchParams.delete("segment");
							setSearchParams(searchParams);
						} else {
							searchParams.set("segment", String(newValue.id));
							setSearchParams(searchParams);
						}
					}}
					isOptionEqualToValue={(option, value) => value.id === option.id}
					ChipProps={{ deleteIcon: <></> }}
					sx={{ width: 250 }}
					renderInput={(params) => (
						<>
							<TextField
								{...params}
								hiddenLabel
								label={"Segment"}
								// variant="filled"
								// size="small"
								color="info"
								sx={{
									fontSize: "small",

									'.MuiInputBase-input': {
										fontSize: 'small',
									},
								}}
							/>
						</>

					)}

					renderOption={(props, option) => (
						<Stack component="li" direction="row" spacing={1}  {...props}>
							<Typography sx={{
								whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"
							}} fontSize={13} color="text.primary">
								{option.symbol}

							</Typography>
							{(option.id === parent_company_id) &&
								<Box display="flex" justifyContent="flex-end" flexGrow={1}>
									<Chip label="PARENT" color="warning" size="small" variant="outlined" />
								</Box>
							}

						</Stack>

					)}

				/>

			}

		</Stack>

	);

}