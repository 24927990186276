import React from 'react';
import { useSearchParams } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';


export default function SelectQueryParam(props: {
	defaultValue: string,
	queryKey: string,
	options: { label: string | number, value: string }[],
}) {

	const [searchParams, setSearchParams] = useSearchParams(); // set

	return (
		<Select
			value={searchParams.get(props.queryKey) || props.defaultValue}
			variant="outlined"
			size="small"
			// disableUnderline={true}
			sx={{ ml: 2, mr: 2, }}
			onChange={(event: SelectChangeEvent<string>) => {
				searchParams.set(props.queryKey, event.target.value);
				setSearchParams(searchParams);
			}}
		>

			{props.options.map((option) => {
				return <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
			})}

		</Select>
	)

}