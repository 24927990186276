import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import React, { useState, useEffect } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { useViewUniverseCompaniesQuery, useViewInputsQuery, DisplayType, useViewQuery, useUpdateView } from '../api/views';
import { ViewCompactCharts, ViewTable, ViewTickerChart, ViewToolbar, ViewCompareChart } from "../components";


const CardItem = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: theme.palette.card.background,
    border: "1px solid",
    borderColor: theme.palette.divider,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
}))


export default function ViewViewer() {

    const navigate = useNavigate();
    const urlParams = useParams(); // view id

    const view_id = parseInt(urlParams.view_id !== undefined ? urlParams.view_id : "0");

    const viewQuery = useViewQuery(view_id);
    const viewUniverseCompanies = useViewUniverseCompaniesQuery(view_id);
    const viewInputsQuery = useViewInputsQuery(view_id);

    const updateView = useUpdateView(view_id);

    const displaySelect: DisplayType = viewQuery.data?.display_type || "list";

    useEffect(() => {

        // if no Companies or inputs, navigate to view editor
        if ((viewInputsQuery.data && viewInputsQuery.data?.length === 0) || (viewUniverseCompanies.data && viewUniverseCompanies.data?.length === 0)) {
            navigate(`/view-builder/${view_id}`);
        }

    }, [viewInputsQuery.data, viewUniverseCompanies.data])


    return (

        <Box sx={{ ml: 1, mr: 1, mt: 2 }}>

            <CardItem>
                <ViewToolbar
                    view_id={view_id}
                    displayValue={displaySelect}
                    displayOnChange={(newValue: DisplayType) => {
                        updateView.mutate({ display_type: newValue });
                    }}
                />

            </CardItem>
            <CardItem className="chartWorksheet" sx={{ height: "calc(100vh - 200px)", overflowY: "auto" }}>

                {
                    (!viewUniverseCompanies.data || viewUniverseCompanies.data?.length === 0 || viewUniverseCompanies.isLoading) ?
                        <></> :
                        displaySelect === "list" ?
                            <ViewTickerChart view_id={view_id} />
                            : displaySelect === "compact" ?
                                <ViewCompactCharts view_id={view_id} />
                                : displaySelect === "compare" ?
                                    <ViewCompareChart view_id={view_id} />
                                    : <ViewTable view_id={view_id} selectedInputs={[]} />
                }


            </CardItem>
        </Box>

    );


};
