import * as React from 'react';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Box } from '@mui/system';
import { useAlphaCrossCorrelationQuery } from '../../api/strategy';
import { green, red, grey, blue, orange } from '@mui/material/colors';
import LinearProgress from '@mui/material/LinearProgress';
import { Typography } from '@mui/material';



function renderCorrCell(params: GridRenderCellParams) {

	if (params.value === undefined || params.value === null) {
		return <></>;
	}

	let color: string = grey[500];

	if (params.value >= 0.5) {
		color = green[500];
	} else if (params.value >= 0.25) {
		color = green[300];
	} else if (params.value >= 0.01) {
		color = green[100];
	}

	else if (params.value <= -0.5) {
		color = red[500];
	} else if (params.value <= -0.25) {
		color = red[300];
	}
	else if (params.value <= -0.01) {
		color = red[100];
	}
	else {
		color = grey[400];
	}


	return (
		<Box sx={{
			lineHeight: "26px",
			backgroundColor: color,
			width: "100%",
			// height: "100%",
			borderRadius: 0.5,
			paddingLeft: 0.5,
			paddingRight: 0.5,
			paddingTop: 0.3,
			paddingBottom: 0.3,
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			fontWeight: 700,
			fontSize: 13,
			color: grey[900],

		}}>{params.value.toFixed(2)}</Box>
	);

}
function renderFactorCell(params: GridRenderCellParams) {

	if (params.value === undefined || params.value === null) {
		return <></>;
	}

	return (
		<Typography fontSize={11}>{params.value}</Typography>
	);

}




export default function StrategyAlphaCrossCorrelation(props: {
	strategy_id: number
}) {


	const alphaCrossCorrelationQuery = useAlphaCrossCorrelationQuery(props.strategy_id);

	const gridColumns: GridColDef[] = alphaCrossCorrelationQuery.data?.columns.map((x, i) => ({
		...x,
		disableColumnMenu: true,
		filterable: false,
		flex: 1,
		sortable: false,
		renderCell: x.field === "factor" ? renderFactorCell : renderCorrCell,
		headerClassName: 'corr-table-header',

	})) || [];




	return (
		<Box
			sx={{
				width: "100%",
				mt: 2,
				height: "100%",
				overflow: "auto",
				// backgroundColor: (theme) => theme.palette.card.background,
			}}
		>

			{(alphaCrossCorrelationQuery.isLoading || alphaCrossCorrelationQuery.isFetching) ?
				<Box sx={{ width: '100%' }}>
					<LinearProgress />
				</Box>
				:

				<DataGrid
					loading={alphaCrossCorrelationQuery.isLoading}
					rows={alphaCrossCorrelationQuery.data?.rows || []}
					columns={gridColumns}
					autoHeight={true}
					// autoPageSize={true}
					hideFooter={true}
					// density='compact'

					sx={{
						'& .MuiDataGrid-cell:focus-within': {
							outline: 'none'
						},
						height: "100%",
						width: "100%",
						backgroundColor: (theme) => theme.palette.card.background,

						'& .corr-table-header': {
							// backgroundColor: 'rgba(255, 7, 0, 0.55)',
							fontSize: 11
						},

					}}

				/>
			}

		</Box>
	);

};

