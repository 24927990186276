import apiClient from "../services/apiClient";
import { useQuery } from '@tanstack/react-query';
import { ICompany } from "../interfaces";

export interface IDataInput {
	underlying_id: number;
	name: string;
	title: string;
	type: string;
	group: string;
	source_name?: string;
}

export interface IDataInputByCategory {
	category: string;
	// data: IDataInput[] | {name: string, data: IDataInput[]}[];
	data: { name: string, inputs: IDataInput[] }[];
}


export function useInputDataOptionsGroupedQuery() {

	async function getInputDataOptionsGrouped() {
		const { data } = await apiClient.get(`/ref/data/grouped`);
		return data
	}

	return useQuery<IDataInputByCategory[]>(['input_options_grouped'], () => getInputDataOptionsGrouped())
}

export function useInputDataOptionsQuery() {

	async function getInputDataOptions() {
		const { data } = await apiClient.get(`/ref/data`);
		return data
	}

	return useQuery<IDataInput[]>(['input_options'], () => getInputDataOptions())
}


export function useCompaniesQuery() {

	async function getCompanies() {
		const { data } = await apiClient.get(`/ref/companies`);
		return data
	}

	return useQuery<ICompany[]>(['companies'], () => getCompanies())
}


export function useDataTransformOptionsQuery(dataset_id: number, dataset_type: string, table_type: "view" | "screener") {

	async function getDataTransformOptionsQuery() {
		const { data } = await apiClient.get(`/ref/data/transform-options/${dataset_id}`,
			{
				params: {
					input_type: dataset_type,
					table_type: table_type
				}
			});
		return data
	}

	return useQuery<string[]>(['data_transform_options', dataset_id, dataset_type, table_type], () => getDataTransformOptionsQuery(),
		{ refetchOnWindowFocus: false, refetchOnMount: false }
	)
}



export function useTrendDataOptionsQuery(company_id: number) {

	async function getTrendDataOptions() {
		const { data } = await apiClient.get(`/ref/data/trend/options/${company_id}`);
		return data
	}

	return useQuery<{ id: number, name: string, vendor_id: number, vendor_name: string }[]>(['data_trend_options', company_id], () => getTrendDataOptions(),
		{ enabled: company_id > 0, refetchOnWindowFocus: false, refetchOnMount: false }
	)
}


export function useCompaniesWithFieldId(company_id: number, field_id: number) {

	async function getCompaniesWithFieldId() {
		const { data } = await apiClient.get(`/ref/companies/field/${company_id}/${field_id}`);
		return data
	}

	return useQuery<ICompany[]>(['companies_with_field', company_id, field_id], () => getCompaniesWithFieldId(),
		{
			enabled: company_id > 0 && field_id > 0, refetchOnWindowFocus: false, refetchOnMount: false
		}
	)
}
