import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import apiClient from "../../services/apiClient";
import { useAuth0 } from "@auth0/auth0-react";


export default function PageLogger() {

	const location = useLocation();

	useEffect(() => {

		const logPageView = async () => {

			if (location.pathname === "/login" || location.pathname === "/") {
				return null
			}

			await apiClient.post("/page_views/", {
				page: location.pathname,
			})

		};

		logPageView();
	}, [location.pathname]);

	return null; // This component does not render anything
};
