
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { DataGrid, GridColDef, GridSelectionModel, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import {
	AppCard, AppCardTitle, AppPageHeader, NewDialog, WatchlistAddCompaniesDialog
} from '../components';
import { useWatchlistsQuery, useRemoveWatchlistCompanies, useWatchlistCompaniesQuery, useRemoveWatchlist } from "../api/watchlist"


function WatchlistChip(props: { name: string, isActive: boolean, handleClick: () => void }) {
	return (

		<Chip sx={{ mr: 1.5 }} color="primary" variant={props.isActive ? "filled" : "outlined"} label={props.name} onClick={props.handleClick} />
	)
}



function SettingsButton(props: { watchlist_id: number, onDeleteSuccess: () => void }) {


	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const removeWatchlist = useRemoveWatchlist();
	const navigate = useNavigate();

	return (
		<>
			<IconButton
				sx={{ ml: 1 }}
				onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}
				aria-haspopup="true">
				<SettingsIcon color="disabled" />
			</IconButton>

			<Menu
				open={open}
				onClose={() => setAnchorEl(null)}
				anchorEl={anchorEl}
			>
				<Box>
					<MenuItem onClick={() => {
						removeWatchlist.mutate(props.watchlist_id, { onSuccess: () => navigate('/watchlist') });
						props.onDeleteSuccess();
						setAnchorEl(null);
					}}>
						Delete Watchlist
					</MenuItem>
				</Box>
			</Menu>
		</>

	)
}


function CustomToolbar() {
	return (
		<GridToolbarContainer sx={{ mb: 1 }}>
			<GridToolbarQuickFilter
				debounceMs={500}
				// variant="outlined"
				placeholder="Search all tickers ..."
				// fullWidth={true}
				sx={{ width: "40%" }}
				autoComplete='off'
			/>
			<Box sx={{ marginLeft: "auto" }}>
				<GridToolbarColumnsButton />
				{/* <GridToolbarFilterButton /> */}
				{/* <GridToolbarDensitySelector /> */}
				<GridToolbarExport printOptions={{ disableToolbarButton: true }} />
			</Box>

		</GridToolbarContainer>
	);
}


export default function Watchlist() {

	const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([]);
	const [newDialogOpen, setNewDialogOpen] = useState<boolean>(false);
	const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false);
	const [deleteSuccessMsg, setDeleteSuccessMsg] = useState<string>("");

	const navigate = useNavigate();

	// queries
	const queryClient = useQueryClient();
	const watchlistsQuery = useWatchlistsQuery();

	// url params for selected watchlist id. If none selected, auto select first
	const [searchParams, setSearchParams] = useSearchParams();
	let watchlist_id = parseInt(searchParams.get("watchlist") || "0");
	let watchlist_name = watchlistsQuery.data?.find(i => i.id === watchlist_id)?.name || "";

	if (!watchlistsQuery.isLoading && watchlistsQuery.data !== undefined) {

		if (watchlist_id === 0 && watchlistsQuery.data.length > 0 && !searchParams.has("watchlist")) {
			watchlist_id = watchlistsQuery.data[0].id;
			watchlist_name = watchlistsQuery.data[0].name;
		}
	}

	// mutations (add/remove)
	const watchlistCompaniesQuery = useWatchlistCompaniesQuery(watchlist_id);
	const removeWatchlistCompanies = useRemoveWatchlistCompanies(watchlist_id);

	const tableColumns: GridColDef[] = [
		{ field: 'symbol', headerName: 'Symbol', flex: 2 },
		{ field: 'name', headerName: 'Name', flex: 2 },
		{ field: 'sector', headerName: 'Sector', flex: 2 },
		{ field: 'industry', headerName: 'Industry', flex: 2 },
	];

	function handleRemoveCompanies() {

		if (watchlist_id === 0) {
			return
		}

		const selectedIDs = selectionModel as number[];
		removeWatchlistCompanies.mutate(selectedIDs);

	}


	// once we get the data, update the selected watchlist query param if no selection is set (e.g. first time on page)
	useEffect(() => {
		const watchlistsData = watchlistsQuery.data;

		// if default selected watchlist is not selected, set to first one
		if (watchlist_id === 0 && watchlistsData && watchlistsData.length > 0) {
			// searchParams.set("watchlist", result.data[0].id);
			setSearchParams({ watchlist: String(watchlistsData[0].id) });
		}

	}, [watchlistsQuery.data])


	return (
		<>
			<Box>

				<AppPageHeader>
					<div>
						<Typography sx={{ mb: 2 }} variant="h5">Watchlists</Typography>


						<Box sx={{ mb: 2 }}>
							<>
								{watchlistsQuery.data?.map((watchlist) => {
									return (
										<WatchlistChip key={watchlist.id} name={watchlist.name} isActive={watchlist.id === watchlist_id}
											handleClick={() => { setSearchParams({ watchlist: String(watchlist.id) }); }} />
									)
								})}
							</>

							<Button onClick={() => setNewDialogOpen(true)} startIcon={<AddIcon />}>New</Button>

						</Box>


						<Divider />

					</div>

				</AppPageHeader>

				{watchlist_id !== 0 && watchlistsQuery.data && watchlistsQuery.data?.length > 0 ?

					<AppCard sx={{ height: "78vh" }}>

						<Stack direction="row">
							<AppCardTitle variant="h6">{watchlist_name}</AppCardTitle>
							<Box display="flex" justifyContent="flex-end" alignItems="center" alignContent="center" flexGrow={1}>
								{selectionModel.length > 0 && <Button sx={{ mr: 2 }} color="error" variant="contained" onClick={handleRemoveCompanies} >Remove Selected</Button>}

								<Button variant="contained" onClick={() => setAddDialogOpen(true)} >Add Companies</Button>
								<SettingsButton watchlist_id={watchlist_id} onDeleteSuccess={() => setDeleteSuccessMsg(`Successfully deleted ${watchlist_name}`)} />

							</Box>

						</Stack>

						<Box sx={{ height: "90%", mt: 2 }}>
							<DataGrid
								rows={watchlistCompaniesQuery.data || []}
								columns={tableColumns}
								hideFooter={false}
								density="compact"
								loading={watchlistCompaniesQuery.isLoading}
								disableSelectionOnClick={true}
								checkboxSelection={true}
								onSelectionModelChange={(newSelectionModel) => {
									setSelectionModel(newSelectionModel);
								}}
								selectionModel={selectionModel}
								sx={{
									'& .MuiDataGrid-cell:focus-within': {
										outline: 'none'
									},
								}}
								components={{
									NoRowsOverlay: () => (
										<Stack height="100%" alignItems="center" justifyContent="center">
											No companies selected
										</Stack>
									),
									NoResultsOverlay: () => (
										<Stack height="100%" alignItems="center" justifyContent="center">
											Filter returned no results
										</Stack>
									),
									Toolbar: CustomToolbar
								}}
							/>
						</Box>

					</AppCard >

					: <></>
				}
			</Box>

			<NewDialog
				title="New Watchlist"
				isOpen={newDialogOpen}
				handleDialogClose={() => setNewDialogOpen(false)}
				navigate={navigate}
				postApiUrl="/watchlist/"
				successNavigatetUrl="/watchlist/?watchlist="
				onSuccess={() => queryClient.invalidateQueries(['watchlists'])}
			/>

			<WatchlistAddCompaniesDialog
				watchlistId={watchlist_id}
				watchlistName={watchlist_name}
				isOpen={addDialogOpen}
				handleDialogClose={() => setAddDialogOpen(false)}
				navigate={navigate}
			/>

			<Snackbar open={deleteSuccessMsg !== ""} autoHideDuration={4000}
				onClose={() => setDeleteSuccessMsg("")}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			>
				<MuiAlert
					severity="info"
					variant="filled"
					onClose={() => setDeleteSuccessMsg("")}
					sx={{ width: '100%' }}>
					{deleteSuccessMsg}
				</MuiAlert>
			</Snackbar>

		</>
	);

};
