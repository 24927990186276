import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';
import { useAddAlert, useRemoveAlert, useViewAlertsQuery } from '../../api/alerts';
import { useViewInputsQuery, useViewUniverseCompaniesQuery } from "../../api/views";
import { IAlert, IAlertForm } from "../../interfaces/alerts";
import { ICompany } from "../../interfaces";



function formatOperator(alert: IAlert) {
    if (alert.value === undefined) {
        return '';
    }
    if (alert.operator === "gt") {
        return ` > ${alert.value}`;
    }
    if (alert.operator === "lt") {
        return ` < ${alert.value}`;
    }
    return '';
}


function Alerts(props: {
    alerts: IAlert[],
    onNewAlertButton: () => void,
    onRemoveAlert: (alertId: number) => void,
    isLoading: boolean
}) {

    function AddAlertButton() {
        return (
            <Button fullWidth variant="outlined" onClick={() => props.onNewAlertButton()}>
                Add Alert
            </Button>
        )
    }

    if (props.isLoading) {
        return (
            <Box sx={{ width: '100%' }}>
                <LinearProgress />
            </Box>
        )
    }

    return (
        <Box>
            <List dense={true}>
                {
                    props.alerts.map((alert) => {
                        return (

                            <div key={alert.id}>
                                <ListItem disablePadding
                                    secondaryAction={
                                        <IconButton edge="end" aria-label="delete"

                                            onClick={() => props.onRemoveAlert(alert.id)}
                                        >
                                            <CloseIcon sx={{
                                                fontSize: 16,
                                                // color: (theme) => theme.palette.error.light,
                                            }} />
                                        </IconButton>
                                    }
                                >

                                    <ListItemText
                                        primary={
                                            <>
                                                <Typography color="primary" display="inline">{alert.field}</Typography>
                                                {/* <Typography color="text.secondary" display="inline"> : {alert.symbol}</Typography> */}
                                                <Chip sx={{ ml: 2 }} label={alert.symbol} size="small" />


                                            </>
                                        }
                                        secondary={formatOperator(alert)}
                                    />


                                </ListItem>

                                <Divider component="li" />
                            </div>

                        )
                    })
                }


            </List>

            <List>
                <ListItem disablePadding>
                    <AddAlertButton />
                </ListItem>
            </List>

        </Box>
    )
}


function NewAlertForm(props: {
    view_id: number,
    dataInputs: { user_input_id: number, name: string, type: string }[],
    alertForm: IAlertForm,
    setAlertForm: React.Dispatch<React.SetStateAction<IAlertForm>>,
}) {

    const universeCompaniesQuery = useViewUniverseCompaniesQuery(props.view_id);
    const allCompaniesOption: ICompany = { id: 0, symbol: "All Tickers" }

    return (
        <Box sx={{ mt: 1, mb: 1 }}>

            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{ ml: 2, mb: 2 }}
            >
                <Typography variant="subtitle1" sx={{ color: 'text.secondary', fontWeight: 500 }}>
                    Condition
                </Typography>

                <Select
                    displayEmpty={true}
                    value={props.alertForm.user_input_id ? props.alertForm.user_input_id.toString() : ""}
                    variant="outlined"
                    size="small"
                    sx={{ mr: 1 }}
                    onChange={(event: SelectChangeEvent<string>) => {
                        if (event.target.value !== "") {
                            props.setAlertForm(prevState => ({ ...prevState, user_input_id: parseInt(event.target.value) }))
                        }
                    }}

                >
                    <MenuItem disabled value={""}>
                        <em>Data</em>
                    </MenuItem>


                    {
                        // only show datainputs where type = data_field
                        props.dataInputs.filter((input) => input.type === "data_field").map((input) => {
                            return (
                                <MenuItem key={input.user_input_id} value={input.user_input_id}>{input.name}</MenuItem>
                            )
                        })
                    }

                </Select>

                {props.alertForm.user_input_id &&
                    <>
                        <Select
                            displayEmpty={true}
                            value={props.alertForm.operator || ""}
                            variant="outlined"
                            size="small"
                            sx={{ mr: 2 }}
                            IconComponent={() => null}
                            inputProps={{ sx: { pr: '16px !important' } }}
                            onChange={(event: SelectChangeEvent<string>) => {
                                if (event.target.value !== "") {
                                    props.setAlertForm(prevState => ({ ...prevState, operator: event.target.value }))
                                }
                            }}

                        >
                            <MenuItem disabled value="">
                                <em>Operator</em>
                            </MenuItem>

                            <MenuItem value={"gt"}>&gt;</MenuItem>
                            <MenuItem value={"lt"}>&lt;</MenuItem>

                        </Select>

                        <TextField
                            autoComplete="off"
                            sx={{ maxWidth: 140, mr: 2 }}
                            placeholder='Value'
                            variant="outlined"
                            size="small"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                props.setAlertForm(prevState => ({ ...prevState, value: event.target.value }))
                            }}
                            value={props.alertForm.value || ""}

                        />
                    </>

                }


            </Stack>


            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{ ml: 2, mb: 2 }}
            >
                <Typography variant="subtitle1" sx={{ color: 'text.secondary', fontWeight: 500 }}>
                    Ticker
                </Typography>


                <Autocomplete
                    loading={universeCompaniesQuery.isLoading}
                    // disableListWrap
                    // popupIcon={""}
                    disableClearable
                    id="ticker-search"
                    filterSelectedOptions
                    options={universeCompaniesQuery.data ? [allCompaniesOption].concat(universeCompaniesQuery.data) : [allCompaniesOption]}
                    getOptionLabel={(option: ICompany) => option.symbol}
                    defaultValue={{ id: 0, symbol: "All tickers" }}
                    onChange={(event: React.SyntheticEvent, value: ICompany) => props.setAlertForm(prevState => ({ ...prevState, company_id: value.id }))}
                    isOptionEqualToValue={(option: ICompany, value: ICompany) => value.id === option.id}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder="Select ticker"
                            variant="outlined"
                            size="small"
                            sx={{ minWidth: 150 }}
                        />)}

                />



            </Stack>

        </Box>

    )
}


export default function AlertsConfigureDialog(
    props: {
        view_id: number,
        isOpen: boolean,
        handleClose: ((event: {}, reason: string) => void),
    }) {


    const isNumeric = (num: any) => (typeof (num) === 'number' || typeof (num) === "string" && num.trim() !== '') && !isNaN(num as number);

    // get alerts react query
    const viewAlertsQuery = useViewAlertsQuery(props.view_id);
    const viewInputsQuery = useViewInputsQuery(props.view_id);

    const addAlert = useAddAlert();
    const removeAlert = useRemoveAlert();

    const [newAlertView, setNewAlertView] = useState<boolean>(false); // toggle if should show new alert form
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [alertFormData, setAlertFormData] = useState<IAlertForm>({ operator: "gt" });

    function handleNewAlertFormSubmit() {

        setErrorMessage("");

        if (alertFormData.user_input_id === undefined || alertFormData.operator === undefined || alertFormData.value === undefined || !isNumeric(alertFormData.value)) {
            setErrorMessage("Plesae select a valid input")
            return
        }

        const postData = {
            view_id: props.view_id,
            company_id: (alertFormData.company_id && alertFormData.company_id > 0) ? alertFormData.company_id : undefined,
            value: parseFloat(alertFormData.value), // string from Form/TextField to number
            operator: alertFormData.operator,
            user_input_id: alertFormData.user_input_id,
        }
        addAlert.mutate(postData, { onSuccess: () => setNewAlertView(false) });

    }

    return (

        <Dialog open={props.isOpen} onClose={props.handleClose} fullWidth maxWidth='sm'

            PaperProps={{
                sx: {
                    backgroundImage: 'none'
                }
            }}
        >
            <DialogTitle>
                Alerts
                <IconButton
                    aria-label="close"
                    onClick={(event) => props.handleClose(event, "userClose")}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent>

                <>
                    {errorMessage &&
                        <FormHelperText sx={{ fontSize: 12 }} error={true}>
                            {errorMessage}
                        </FormHelperText>
                    }

                    {
                        newAlertView ?
                            <NewAlertForm view_id={props.view_id} alertForm={alertFormData} setAlertForm={setAlertFormData}
                                dataInputs={viewInputsQuery.data || []}
                            />
                            :
                            <Alerts alerts={viewAlertsQuery.data || [] as IAlert[]}
                                onNewAlertButton={() => {
                                    setNewAlertView(true);
                                    setAlertFormData({ operator: "gt" });
                                }}
                                onRemoveAlert={(alertId) => removeAlert.mutate({ alert_id: alertId, view_id: props.view_id })}
                                isLoading={viewAlertsQuery.isLoading}
                            />
                    }

                </>

            </DialogContent>

            {newAlertView && !addAlert.isLoading ?
                <DialogActions>
                    <Button size="small" variant="text" onClick={() => {
                        setNewAlertView(false)
                    }}>Back</Button>
                    <Button size="small" variant="contained" onClick={() => {
                        handleNewAlertFormSubmit();
                    }}>Save</Button>
                </DialogActions>
                : <></>
            }

            {addAlert.isLoading &&
                <DialogActions>
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box>
                </DialogActions>
            }


        </Dialog>


    );

}
