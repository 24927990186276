import './styles.scss';
import React from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<BrowserRouter>
    <App />
</BrowserRouter >);

// OLD WAY:
// https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html#updates-to-client-rendering-apis
// ReactDOM.render(
//     <BrowserRouter>
//         <App />
//     </BrowserRouter >
//     ,
//     document.getElementById('root')
// );