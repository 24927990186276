import { styled } from '@mui/system'
import Typography from '@mui/material/Typography';


export const AppCard = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.card.background,
    border: "1px solid rgba(56, 56, 56, 1)",
    padding: 25,
    borderRadius: 3,
    marginTop: 30,
    marginBottom: 20,
    marginLeft: 40,
    marginRight: 40

}))

export const AppPageHeader = styled('div')(({ theme }) => ({
    marginTop: 30,
    marginLeft: 40,
    marginRight: 40

}))


export const AppCardTitle = styled(Typography)(({ theme }) => ({
    marginBottom: 16,
    // color: theme.palette.text.secondary,
    fontWeight: 600
}));

export const AppPageFooter = styled('div')(({ theme }) => ({
    marginTop: 30,
    marginLeft: 40,
    marginRight: 50,
    marginBottom: 30,
    textAlign: 'right',

}))


export const AppChartContainer = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.card.background,
    height: "90%",
    padding: 5,
    marginTop: 20,
    marginBottom: 5,
    marginLeft: 10,
    marginRight: 10,
    border: "1px solid rgba(56, 56, 56, 1)"

}))