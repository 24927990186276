import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import React from 'react';
import { useModelCompanyMetricsQuery, useUpdateUserModelMetricSelection } from "../../api/models";
import Chip from '@mui/material/Chip';
import { AxiosError } from 'axios'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import { useErrorMessageAlert } from "../../components/Errors/useErrorMessageAlert";
import Link from '@mui/material/Link';


export default function AddMetricsToSummaryDialog(props: {
	companySymbol: string,
	company_id: number,
	currentMetricIds: number[],
	field_id: number,
	model_family_id: number,
	watchlist_id: number,
	isOpen: boolean,
	handleClose: ((event: {}, reason: string) => void),
}) {

	const { setErrorMessage, ErrorMessageAlert, setErrorMessageFromResponse } = useErrorMessageAlert();

	// remove any string after "-" from symbol to get parent symbol
	const parentSymbol = props.companySymbol.split("-")[0];

	// store key:value (metric_id: is_selected). Default to current metric ids

	const defaultSelectState = props.currentMetricIds.reduce((acc: { [key: number]: boolean }, num: number) => {
		acc[num] = true;
		return acc;
	}, {});

	const [selectedMetrics, setSelectedMetrics] = React.useState<{ [key: number]: boolean }>(defaultSelectState);
	const [selectionHasChanged, setSelectionHasChanged] = React.useState<boolean>(false);
	// get metric ids selected. Start with default and update based on user selection
	const modelCompanyMetricsQuery = useModelCompanyMetricsQuery(props.company_id);
	const updateUserModelMetricSelection = useUpdateUserModelMetricSelection();

	const primaryMetricsIds = modelCompanyMetricsQuery.data?.filter(metric => metric.is_primary).map(metric => metric.metric_id);

	// if there are multiple metric vendors, show the vendor name next to the checkbox/metric.
	const showVendorName = Array.from(new Set(modelCompanyMetricsQuery.data?.map((metric) => metric.vendor_name) || [])).length > 1;

	function submitUpdateMetrics(event: {}, reason: string) {

		setErrorMessage("");

		if (selectedMetrics === undefined || Object.keys(selectedMetrics).length === 0) {
			setErrorMessage("No metrics updated.");
			return;
		}

		// make sure at least one metric is selected
		if (!Object.values(selectedMetrics).includes(true)) {
			setErrorMessage("At least one metric must be selected.");
			return;
		}

		// create array of objects as {metric_id: 1, is_selected: true} from the key value pairs
		// exclude if metric_id is a primary metric since this is set elsewhere
		const selectedMetricsToUpdate = Object.entries(selectedMetrics).map(([key, value]) => {
			return { metric_id: parseInt(key), is_selected: value }
		}).filter((metric) => !primaryMetricsIds?.includes(metric.metric_id));

		updateUserModelMetricSelection.mutate(selectedMetricsToUpdate, {
			onSuccess: () => { props.handleClose(event, reason) },
			onError: (error: any | AxiosError) => setErrorMessageFromResponse(error)

		});

	}

	return (
		<>
			<Dialog open={props.isOpen} onClose={props.handleClose} fullWidth

				PaperProps={{
					sx: {
						// width: "50%",
						// maxHeight: 300
						height: 550,
						backgroundImage: 'none'
						// backgroundColor: 'text.primary'
					}
				}}
			>
				<DialogTitle>
					<Box>
						<Stack direction="row" alignItems="center" spacing={1}>
							<Typography variant="h6" color="warning.main">{parentSymbol}</Typography>
							<Typography variant="h6">: Add Metrics to Forecast Summary Table</Typography>
						</Stack>

						<Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
							Your primary metrics are always displayed by default.
							If no primary metric is selected, a metric is selected automatically for each ticker.
							You can set your primary metric with the Forecast Settings on the
							&nbsp;
							<Link href={`/company/${props.company_id}?display=forecasts`}
								target="_blank">
								Company
							</Link>
							&nbsp;
							page.
						</Typography>

					</Box>
					<IconButton
						aria-label="close"
						onClick={(event) => props.handleClose(event, 'buttonClose')}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}>
						<CloseIcon />
					</IconButton>

				</DialogTitle>


				<DialogContent>

					<Box
						sx={{ height: 300 }}
					>

						{!modelCompanyMetricsQuery.isLoading && modelCompanyMetricsQuery.data && modelCompanyMetricsQuery.data.length > 0 &&
							<Stack direction="row" spacing={2} sx={{ mt: 2 }}>
								<FormGroup>

									{modelCompanyMetricsQuery.data.map((metric) => {
										const existingSelection = selectedMetrics[metric.metric_id];
										const is_selected = existingSelection !== undefined ? existingSelection : props.currentMetricIds.includes(metric.metric_id);
										return (
											<Stack direction={"row"} alignItems={"center"} key={metric.metric_id}>
												<FormControlLabel
													control={
														<Checkbox
															checked={is_selected}
															disabled={metric.is_primary}
															onChange={(event) => {
																setSelectedMetrics({ ...selectedMetrics, [metric.metric_id]: event.target.checked });
																setSelectionHasChanged(true);
															}}
														/>
													}
													label={metric.metric_name}
													key={metric.metric_id}

												/>

												{metric.is_primary && <Chip label="PRIMARY" color="warning" size="small" variant="outlined" />}
												{showVendorName && <Typography sx={{ ml: 0.5 }} variant="caption" color="text.disabled">{metric.vendor_name}</Typography>}

											</Stack>


										)
									})}
								</FormGroup>

							</Stack>
						}

						{(modelCompanyMetricsQuery.isLoading || modelCompanyMetricsQuery.isFetching) &&
							<Box sx={{ mt: 2, flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
								<CircularProgress />
							</Box>
						}


					</Box>


				</DialogContent>

				<DialogActions>
					<Button sx={{ width: 100 }} disabled={updateUserModelMetricSelection.isLoading} variant="outlined" onClick={(event) => { props.handleClose(event, "buttonCancel"); setErrorMessage(""); }}>Cancel</Button>
					<Button sx={{ width: 100 }} disabled={!selectionHasChanged || updateUserModelMetricSelection.isLoading} variant="contained" onClick={(event) => { submitUpdateMetrics(event, "buttonSubmit"); }}>Save</Button>
				</DialogActions>

			</Dialog >

			{ErrorMessageAlert}

		</>

	);

}
