import React, { useEffect } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { styled, Box } from '@mui/system'
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';

import {
    ModelCorrTable,
    SelectQueryParam,
    ModelFeaturesBlocks
} from '../components';
import { useWatchlistsQuery } from "../api/watchlist"
import { useModelMappingQuery } from "../api/models"
import TextField from '@mui/material/TextField';
import {
    ModelFeaturesAddDialogMapping
} from "../components/Model/ModelFeaturesAddDialog";
import Button from '@mui/material/Button';



const FeatureAppCard = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.card.background,
    padding: 10,
    borderRadius: 3,
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 10,
    marginRight: 10,
    border: "1px solid rgba(56, 56, 56, 1)"

}))


export default function ModelFeatures() {


    const [searchParams, setSearchParams] = useSearchParams();
    const urlParams = useParams(); // model map id
    const navigate = useNavigate();

    const watchlist_id: number = parseInt(searchParams.get("watchlist") || "0");
    const watchlistsQuery = useWatchlistsQuery();
    const watchlistOptions = watchlistsQuery.data ? [{ id: 0, name: 'All Tickers' }].concat(watchlistsQuery.data) : [];

    //  get list of model mappings the user can choose from
    const modelMappingQuery = useModelMappingQuery();
    const model_map_id = (urlParams.model_map_id != undefined) ? parseInt(urlParams.model_map_id) : 0;

    // check if the entered model_map_id exists
    const mapExists = modelMappingQuery.data && modelMappingQuery.data.find((map) => map.id === model_map_id);

    // open the Update Model Dataset dialog
    const [modelDatasetDialogOpen, setModelDatasetDialogOpen] = React.useState(false);

    // choose default metric vendor
    useEffect(() => {
        if (model_map_id === 0 || !mapExists) {
            if (modelMappingQuery.data && modelMappingQuery.data.length > 0) {
                // set to first
                navigate(`/model-features/${modelMappingQuery.data[0].id}`);
            }
        }

    }, [model_map_id, modelMappingQuery.data]);

    return (
        <>
            <ModelFeaturesAddDialogMapping
                model_map_id={model_map_id}
                isOpen={modelDatasetDialogOpen}
                handleClose={() => setModelDatasetDialogOpen(false)} />

            <Box
                sx={{
                    marginTop: 3,
                    marginBottom: 3,
                    marginLeft: 4,
                    marginRight: 4
                }}>

                <Stack direction="row" justifyContent='space-between' alignItems="center" >

                    <Typography variant="h5">Model Features</Typography>


                    <Stack direction="row" spacing={3}>
                        {(watchlistOptions && watchlistOptions.length > 0) &&
                            <Autocomplete
                                size="small"
                                disableClearable
                                options={watchlistOptions}
                                getOptionLabel={(option) => option.name}
                                value={watchlistOptions.find((option) => option.id === watchlist_id) || watchlistOptions[0]}
                                onChange={(event, newValue) => {

                                    searchParams.set("watchlist", String(newValue.id));
                                    setSearchParams(searchParams);

                                }}

                                isOptionEqualToValue={(option, value) => value.id === option.id}
                                ChipProps={{ deleteIcon: <></> }}
                                sx={{ width: 200 }}
                                renderInput={(params) => (
                                    <>
                                        <TextField
                                            {...params}
                                            label="Watchlist"
                                            color="info"
                                        />
                                    </>

                                )}

                            />
                        }

                        {(modelMappingQuery.data && modelMappingQuery.data.length > 0) &&
                            <Autocomplete
                                size="small"
                                disableClearable
                                options={modelMappingQuery.data}
                                getOptionLabel={(option) => option.name}
                                value={modelMappingQuery.data.find((option) => option.id === model_map_id) || modelMappingQuery.data[0]}
                                onChange={(event, newValue) => {

                                    if (newValue.id && newValue.id !== 0) {
                                        navigate({
                                            pathname: `/model-features/${newValue.id}`,
                                            // preserve the existing query params in the url
                                            search: location.search
                                        });
                                    }


                                }}

                                isOptionEqualToValue={(option, value) => value.id === option.id}
                                ChipProps={{ deleteIcon: <></> }}
                                sx={{ width: 200 }}
                                renderInput={(params) => (
                                    <>
                                        <TextField
                                            {...params}
                                            label="Dataset Mapping"
                                            color="info"
                                        />
                                    </>

                                )}

                            />
                        }

                    </Stack>

                </Stack>
            </Box >


            <Box sx={{
                height: '83vh', display: "flex", flexDirection: "column",
                pt: 0, pb: 0, pl: 3, pr: 3,
                backgroundColor: 'inherit'
            }}>


                <FeatureAppCard >


                    <Stack direction='row' justifyContent={'space-between'} >
                        <Box>
                            <Typography variant="subtitle1" sx={{ mr: 1 }}>Selected Features:</Typography>
                        </Box>

                        <Button variant="contained" color="primary" size='small' onClick={() => setModelDatasetDialogOpen(true)} >Update Datasets</Button>

                    </Stack>


                    <ModelFeaturesBlocks />

                </FeatureAppCard>


                <FeatureAppCard flex={1}>

                    <Stack direction="row" mb={1} >

                        <Box sx={{ flexGrow: 1, display: 'flex' }}>
                            <Typography gutterBottom={true} variant="subtitle1">Correlations vs. Target Metrics</Typography>
                        </Box>


                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center"
                        }}>

                            <Typography variant="subtitle2" >Max Periods:</Typography>

                            <SelectQueryParam defaultValue="8" queryKey="max_periods"
                                options={[
                                    { label: "None", value: "none" },
                                    { label: "4", value: "4" },
                                    { label: "5", value: "5" },
                                    { label: "6", value: "6" },
                                    { label: "7", value: "7" },
                                    { label: "8", value: "8" },
                                    { label: "9", value: "9" },
                                    { label: "10", value: "10" },
                                    { label: "11", value: "11" },
                                    { label: "12", value: "12" },
                                    { label: "13", value: "13" },
                                    { label: "14", value: "14" },
                                    { label: "15", value: "15" },
                                    { label: "16", value: "16" },
                                ]}
                            />

                            <Typography variant="subtitle2" >Statistic:</Typography>
                            <SelectQueryParam defaultValue="corr" queryKey="statistic"
                                options={[
                                    { label: "Correlation", value: "corr" },
                                    { label: "Standard Dev", value: "std_dev" },
                                ]}
                            />

                        </Box>



                    </Stack>

                    {(model_map_id !== 0 && mapExists) && <ModelCorrTable model_map_id={model_map_id} />}

                </FeatureAppCard>

            </Box>




        </>
    );

};
