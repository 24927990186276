import React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { ICompanyWithCoverage } from "../../api/watchlist";
import InsightsIcon from '@mui/icons-material/Insights';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';


export default function CompanyCoverageControls(props: {
	companyCoverage: ICompanyWithCoverage,
	onClickCallback?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void

}) {

	const navigate = useNavigate();

	return (


		<Box display="flex" justifyContent="flex-end" flexGrow={1}>

			{props.companyCoverage.has_trends &&
				<Tooltip title="Trends" >
					<IconButton
						onClick={(e) => {
							props.onClickCallback && props.onClickCallback(e);
							navigate(`/company/${props.companyCoverage.company_id}?display=trends`);
						}}
					>
						<SignalCellularAltIcon
							sx={{
								color: "text.disabled",
								"&:hover": {
									color: "primary.main"
								}
							}}

						/>
					</IconButton>
				</Tooltip>
			}

			{props.companyCoverage.has_models &&
				<Tooltip title="Forecasts" >

					<IconButton
						onClick={(e) => {
							props.onClickCallback && props.onClickCallback(e);
							navigate(`/company/${props.companyCoverage.company_id}?display=forecasts`);
						}}
					>
						<StackedLineChartIcon
							sx={{
								color: "text.disabled",
								"&:hover": {
									color: "success.main"
								}
							}}
						/>
					</IconButton>
				</Tooltip>
			}

			{props.companyCoverage.has_views &&
				<Tooltip title="Views" >

					<IconButton
						onClick={(e) => {
							props.onClickCallback && props.onClickCallback(e);
							navigate(`/company/${props.companyCoverage.company_id}?display=views`);
						}}

					>
						<InsightsIcon

							sx={{
								color: "text.disabled",
								"&:hover": {
									color: "warning.main"
								}
							}}
						/>
					</IconButton>
				</Tooltip>
			}

		</Box>


	)
}