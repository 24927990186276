import React from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';


export default function ChartSelect(props: {
    menuTitle: string,
    value: string,
    setValue: (val: string) => void,
    options: { label: string, value: string }[],
}) {
    return (
        <FormControl size="small">
            <Select
                value={props.value}
                variant="outlined"
                size="small"
                sx={{ mr: 1, fontSize: "small" }}
                onChange={(event: SelectChangeEvent<string>) => {
                    props.setValue(event.target.value);
                }}
                MenuProps={{
                    style: {
                        maxHeight: 400,
                    },
                }}
            >
                <MenuItem disabled value="">
                    <em>{props.menuTitle}</em>
                </MenuItem>

                {props.options.map((option) => {
                    return <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                })}

            </Select>
        </FormControl>
    )
}

