import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import {
    AlertsConfigureDialog, DatasetDialog,
    DataSidebar, ViewCompactCharts, ViewDataBlocks,
    ViewTable, ViewTickerChart, ViewCompareChart, ViewToolbar,
    TickerSelect
} from "../components";
import { AxiosError } from 'axios'
import { ICompany } from "../interfaces";
import Stack from '@mui/material/Stack';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useParams, useSearchParams } from 'react-router-dom';
import {
    useViewInputsQuery, useViewUniverseOptionsQuery, useViewUniverseQuery,
    useViewUniverseCompaniesQuery, useAddViewUniverse, useRemoveViewUniverse,
    useAddViewInput, useRemoveViewInput, DisplayType, useViewQuery,
    useUpdateView
} from '../api/views';
import { IDataInput } from '../api/reference';
import { LinearProgress, Typography } from '@mui/material';



const CardItem = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: theme.palette.card.background,
    border: "1px solid",
    borderColor: theme.palette.divider,
}))


export default function ViewBuilder() {

    // state for adding dataset dialog
    const [datasetDialog, setDatasetDialog] = useState<{ isOpen: boolean, axis: number, input?: IDataInput, error?: string }>({ isOpen: false, axis: 1 });

    const urlParams = useParams(); // view id
    const view_id = parseInt(urlParams.view_id !== undefined ? urlParams.view_id : "0");
    const [alertsDialogOpen, setAlertsDialogOpen] = useState<boolean>(false);

    // queries
    const viewUniverseOptionsQuery = useViewUniverseOptionsQuery();
    const viewInputsQuery = useViewInputsQuery(view_id);
    const viewUniverseQuery = useViewUniverseQuery(view_id);
    const viewUniverseCompanies = useViewUniverseCompaniesQuery(view_id);
    const viewQuery = useViewQuery(view_id);

    // mutations
    const addViewUniverse = useAddViewUniverse(view_id);
    const removeViewUniverse = useRemoveViewUniverse(view_id);
    const addViewInput = useAddViewInput(view_id);
    const removeViewInput = useRemoveViewInput(view_id);
    const updateView = useUpdateView(view_id);

    const displaySelect: DisplayType = viewQuery.data?.display_type || "list";

    function handleAddInput(input: IDataInput, axis: number) {
        if (input.type === "user" || input.type === "event") {
            addViewInput.mutate({ underlying_id: input.underlying_id, name: input.name, axis: axis, type: input.type });
        } else {
            setDatasetDialog({ isOpen: true, input: input, axis: axis });
        }

    }

    if (viewQuery.isLoading || viewUniverseQuery.isLoading || viewUniverseOptionsQuery.isLoading || viewInputsQuery.isLoading || viewUniverseCompanies.isLoading) {
        return <LinearProgress />
    }

    if (!viewQuery.data) {
        return <></>
    }

    return (

        <DndProvider backend={HTML5Backend}>


            <Box sx={{ ml: 2, mr: 2, mt: 2 }}>

                <Box
                    display="flex"
                    flexDirection="row"
                    sx={{ height: "100%", width: "100%" }}
                >

                    <CardItem sx={{ minWidth: 550, maxWidth: 550 }}>

                        <DataSidebar onSearchSelect={(item) => handleAddInput(item, 1)} />

                    </CardItem>

                    <Box flexGrow={2} sx={{ ml: 2 }}>

                        <Grid container direction="column" spacing={2}>

                            <Grid item xs={2}>
                                <CardItem >

                                    <ViewToolbar
                                        view_id={view_id}
                                        displayValue={displaySelect}
                                        displayOnChange={(newValue: DisplayType) => {
                                            updateView.mutate({ display_type: newValue });
                                        }}
                                        handleOpenAlertsDialog={() => setAlertsDialogOpen(true)}

                                    />

                                    <Stack direction="row">
                                        <Box width="100%">

                                            {(viewUniverseOptionsQuery.data.length > 0 && viewUniverseQuery.data && !viewUniverseQuery.isLoading && !viewUniverseOptionsQuery.isLoading) ?
                                                <TickerSelect
                                                    title="Tickers"
                                                    // disableChipDelete={false}
                                                    loading={viewUniverseOptionsQuery.isLoading}
                                                    tickerOptions={viewUniverseOptionsQuery.data}
                                                    tickersSelected={viewUniverseQuery.data || []}
                                                    handleTickerSelect={(event: React.SyntheticEvent, newValue: ICompany[]) => {
                                                        //Find values that are in result1 but not in result2
                                                        const selectedUniverse = viewUniverseQuery.data;

                                                        var additions = newValue.filter(function (obj) {
                                                            return !selectedUniverse.some(function (obj2) {
                                                                return (obj.id === obj2.id && obj.group === obj2.group);
                                                            });
                                                        });
                                                        var deletions = selectedUniverse.filter(function (obj) {
                                                            return !newValue.some(function (obj2) {
                                                                return (obj.id === obj2.id && obj.group === obj2.group);
                                                            });
                                                        });

                                                        // save to DB >>
                                                        // add new Companies
                                                        additions.forEach((i) => {
                                                            addViewUniverse.mutate({
                                                                id: i.id,
                                                                group: i.group,
                                                            });
                                                        });

                                                        deletions.forEach((i) => {
                                                            removeViewUniverse.mutate({
                                                                id: i.id,
                                                                group: i.group,
                                                            });
                                                        });

                                                    }}
                                                />
                                                : <></>}
                                        </Box>

                                    </Stack>

                                    {(!viewInputsQuery.isLoading && viewInputsQuery.data) ?
                                        <ViewDataBlocks
                                            datasets={viewInputsQuery.data}
                                            onDrop={(input: IDataInput, axis: number) => handleAddInput(input, axis)}
                                            handleRemove={(id: number) => {
                                                removeViewInput.mutate(id);
                                            }}
                                            showAxes={
                                                // for table and compare, dont show axes drop boxes
                                                displaySelect === "table" || displaySelect === "compare" ? false : true
                                            }

                                        />
                                        : <></>
                                    }

                                </CardItem>
                            </Grid>


                            <Grid item xs={10}>

                                <CardItem sx={{ height: "calc(100vh - 410px)" }}>


                                    {((viewInputsQuery.data && viewInputsQuery.data?.length === 0) || (viewUniverseCompanies.data && viewUniverseCompanies.data?.length === 0)) &&
                                        <Box sx={{
                                            border: "2px solid",
                                            borderColor: (theme) => theme.palette.divider,
                                            borderRadius: 1,
                                            width: "100%",
                                            paddingTop: 2, paddingBottom: 2, paddingLeft: 2, paddingRight: 2,
                                            display: "flex"
                                        }}>
                                            <Typography variant="h6" sx={{ textAlign: "center", color: (theme) => theme.palette.warning.light, opacity: 0.8 }}>Add a ticker and data field to build a View</Typography>
                                        </Box>

                                    }
                                    {
                                        (!viewInputsQuery.data || viewInputsQuery.data?.length === 0 || viewInputsQuery.isLoading || !viewUniverseCompanies.data || viewUniverseCompanies.data?.length === 0 || viewUniverseCompanies.isLoading) ?
                                            <></> :
                                            displaySelect === "list" ?
                                                <ViewTickerChart view_id={view_id} />
                                                : displaySelect === "compact" ?
                                                    <ViewCompactCharts view_id={view_id} />
                                                    : displaySelect === "compare" ?
                                                        <ViewCompareChart view_id={view_id} />
                                                        : <ViewTable view_id={view_id} selectedInputs={viewInputsQuery.data} />
                                    }



                                </CardItem>
                            </Grid>

                        </Grid>

                    </Box>


                </Box >
            </Box>

            {/* only render if IsOpen, otherwise the default name state wont set properly  */}
            {
                (datasetDialog.isOpen && view_id !== 0 && datasetDialog.input) &&
                <DatasetDialog
                    tableType="view"
                    axis={datasetDialog.axis}
                    isOpen={datasetDialog.isOpen}
                    handleClose={() => setDatasetDialog({ isOpen: false, axis: 1 })}
                    dataInput={datasetDialog.input}
                    errorMsg={datasetDialog.error}
                    addInput={
                        (addedInput) => addViewInput.mutate(addedInput, {
                            onSuccess: () => {
                                setDatasetDialog({ isOpen: false, axis: 1 });
                            },
                            onError: (error: any | AxiosError) => {
                                setDatasetDialog({ ...datasetDialog, error: "There was an issue adding this input. Please check all parameters and try again." });
                            }
                        })
                    }
                />
            }

            <AlertsConfigureDialog
                view_id={view_id}
                isOpen={alertsDialogOpen}
                handleClose={() => setAlertsDialogOpen(false)}
            />


        </DndProvider >
    );

};
