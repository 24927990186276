import React, { FC } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { AppLayout } from './components';
import { useAuth0 } from "@auth0/auth0-react";
import {
    Dashboard, Home, ViewBuilder, ViewViewer, Login,
    ModelFeatures, ModelResults, NotFound, AdminPage, StrategyBuilder, Watchlist,
    VendorAccess, Screener, Company
} from './views';
import { checkUserIsAdmin } from './utils/auth';


function RequireAuth() {

    const { user, isAuthenticated, isLoading } = useAuth0();

    if (isLoading) {
        return null
    }
    if (!isAuthenticated) {
        return <Navigate to="/login" />;
    }
    return <Outlet />;

}

function RequireAuthAdmin() {

    const { user, isAuthenticated, isLoading } = useAuth0();

    if (isLoading) {
        return null
    }
    if (!isAuthenticated || !user) {
        return <Navigate to="/login" />;
    }
    if (!checkUserIsAdmin(user)) {
        return null;
    }
    return <Outlet />;

}


export const AppRoutes: FC = () => {

    return (
        <Routes>

            <Route path="/" element={<Home />} />

            <Route path="/login" element={<Login />} />

            <Route element={<AppLayout />} >
                <Route element={<RequireAuth />}>
                    {/* protected routes: */}
                    {/* <Route path="/dashboard" element={<DashboardPage />}></Route> */}
                    {/* <Route path="/alpha-builder" element={<BuilderPage />}></Route> */}
                    <Route path="/dashboard" element={<Dashboard />}></Route>
                    <Route path="/watchlist" element={<Watchlist />}></Route>

                    <Route path="/view-builder/:view_id" element={<ViewBuilder />}></Route>
                    <Route path="/view-display/:view_id" element={<ViewViewer />}></Route>
                    <Route path="/company/:company_id" element={<Company />}></Route>
                    <Route path="/model-results" element={<ModelResults />}></Route>

                    {/* if no model_map_id, will auto load the first one fetched */}
                    <Route path="/model-features" element={<ModelFeatures />}></Route>
                    <Route path="/model-features/:model_map_id" element={<ModelFeatures />}></Route>

                    <Route path="/screener/:screener_id" element={<Screener />}></Route>
                    {/* <Route element={<RequireAuthAdmin />}> */}
                    <Route path="/strategy-builder/:displaySelect/:strategy_id" element={<StrategyBuilder />}></Route>
                    {/* </Route> */}

                </Route>
            </Route>

            <Route element={<RequireAuthAdmin />}>
                <Route path="/admin" element={<AdminPage />}></Route>
            </Route>

            <Route element={<RequireAuth />}>
                <Route path="/vendor-access" element={<VendorAccess />}></Route>
            </Route>

            <Route path='*' element={<NotFound />} />

        </Routes>
    );
};
