
import React from 'react';
import { AppRoutes } from './Routes';
import { CssBaseline, ThemeProvider } from '@mui/material'
import { DarkTheme } from './components/Theme/DarkTheme'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Auth0Provider } from '@auth0/auth0-react';
import AuthTokenLayer from './AuthTokenLayer'
import PageLogger from "./components/PageLogger/PageLogger";

// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
const MAX_RETRIES = 3;

const queryClient = new QueryClient({

	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,

			// only retry for status codes 5xx, then try max 3 times
			retry: (failureCount: any, error: any) => {

				if (failureCount && failureCount > MAX_RETRIES) {
					return false;
				}

				const status = error.response?.status ?? 0;

				if (status < 500) {
					return false;
				}

				return true;

			}

		},
	},

})


function getBaseUrl() {

	if (process.env.NODE_ENV === "development") {
		return "http://localhost:3000";
	}

	const env = process.env.REACT_APP_ENV;

	if (env === "prod") {
		return "https://app.arbinsights.com";
	}

	return `https://${env}.app.arbinsights.com`;
}




export default function App() {


	const redirectUri = getBaseUrl();

	return (

		<Auth0Provider
			// domain="dev-giukk2g2lzqe3sbe.us.auth0.com"
			domain={process.env.REACT_APP_AUTH0_DOMAIN || ""}
			clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ""}
			authorizationParams={{
				redirect_uri: redirectUri,
				audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
				// scope: "read:current_user update:current_user_metadata"
			}}
		>
			<AuthTokenLayer />
			<QueryClientProvider client={queryClient}>

				<ThemeProvider theme={DarkTheme}>
					<CssBaseline />
					<PageLogger />
					<AppRoutes />

				</ThemeProvider>
			</QueryClientProvider>

		</Auth0Provider>
	);
}