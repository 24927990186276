import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { Box, styled } from '@mui/system';
import React from 'react';
import { DataChart } from "../../components";
import { getIndexColor, getModelChartColor } from "../../components/Charts/utils";



export default function ModelCorrChartDialog(props: {
	company_id: number,
	metric_id: number,
	field_id: number,
	titleHeader: string,
	isOpen: boolean,
	max_periods: number,
	handleClose: ((event: {}, reason: string) => void),
}) {



	return (
		<>
			<Dialog open={props.isOpen} onClose={props.handleClose} fullWidth maxWidth='lg'

				PaperProps={{
					sx: {
						// width: "50%",
						// maxHeight: 300
						minHeight: 650,
						backgroundImage: 'none'
						// backgroundColor: 'text.primary'
					}
				}}
			>
				<DialogTitle>
					<Box>
						{props.titleHeader}

					</Box>
					<IconButton
						aria-label="close"
						onClick={(event) => props.handleClose(event, 'buttonClose')}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<CloseIcon />
					</IconButton>

				</DialogTitle>


				<DialogContent>

					<Box sx={{ height: 600 }}>

						<DataChart
							title="Dataset Values vs. Target Metric (YoY%)"
							chartType='line'
							dataUrl={`/models/correlations/values/${props.company_id}/${props.metric_id}/${props.field_id}?max_periods=${props.max_periods}`}
							queryKey={['correlations', 'values', props.company_id, props.metric_id, props.field_id, props.max_periods]}
							getDatasetChartType="line"
							getYAxisId="y"
							getChartColor={getIndexColor}
							disableAnimations={false}
							showPointOnLine={true}

						/>


					</Box>

				</DialogContent>

			</Dialog >


		</>

	);

}

