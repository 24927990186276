import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Box, useTheme } from "@mui/system";
import {
  ArcElement,
  BarElement,
  // CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement, TimeScale, TimeSeriesScale, Title,
  Tooltip
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import React from "react";
import { Chart } from "react-chartjs-2";
import { useViewChartDataQuery, PeriodLookbackType } from "../../api/views";
import annotationPlugin from "chartjs-plugin-annotation"

ChartJS.register(
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  ArcElement,
  annotationPlugin

);


export default function ViewChart(props: {
  title?: string,
  view_id: number;
  company_id: number;
  chartType: "line" | "bar" | "pie" | "doughnut";
  periodLookback: PeriodLookbackType;
  getChartColor: (label: string, index: number) => string;
  getDatasetChartType: string | ((label: string, index: number) => string);
  displayLegend?: boolean;
  displayXAxis?: boolean;
  displayYAxis?: boolean;
  disableAnimations?: boolean;
  getDataColor?: (value: number, label: string) => string,
}) {

  const chartDataQuery = useViewChartDataQuery(props.view_id, props.company_id, props.periodLookback);

  const theme = useTheme();
  const displayLegend =
    props.displayLegend === undefined ? true : props.displayLegend; // default prop to true
  const displayXAxis =
    props.displayXAxis === undefined ? true : props.displayXAxis; // default prop to true
  const displayYAxis =
    props.displayYAxis === undefined ? true : props.displayYAxis; // default prop to true

  const disableAnimations =
    props.disableAnimations === undefined ? false : props.disableAnimations; // default prop to true

  const getDataColor = props.getDataColor === undefined ? (val: number, label: string) => "white" : props.getDataColor; // default

  const isArcElement = ["pie", "doughnut"].includes(props.chartType);

  // chart data (fetched from DB)
  // const [chartData, setChartData] = useState<{ datasets: IChartDataset[], labels: string[] }>({ datasets: [], labels: [] });
  // const [chartAnnotations, setChartAnnotations] = useState<IAnnotation[]>([]);

  if (chartDataQuery.isLoading || chartDataQuery.isFetching || !chartDataQuery.data) {
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    );
  }


  const allDataMissing = chartDataQuery.data?.datasets.filter((x) => x.data.length > 0).length === 0;

  if (allDataMissing) {
    return (
      <Typography color="text.secondary" sx={{ mt: 1, }}>No data available {props.title && ` for ${props.title}`}</Typography >
    )
  }

  const hasY2 = chartDataQuery.data?.datasets.map((i) => i.yAxisID).includes("y2");

  const chartDatasets = chartDataQuery.data?.datasets.map((x, i) => ({
    ...x,
    // if arc alement (i.e. pie, doughnut) we pass a single dataset with an array of colors
    // for line/bar types, we pass a single color for the entire line
    borderColor: isArcElement
      ? x.data.map((val, idx) => props.getChartColor(x.label, idx))
      : props.getDataColor
        ? x.data.map((val) => getDataColor(val, x.label))
        : props.getChartColor(x.label, i),
    backgroundColor: isArcElement
      ? x.data.map((val, idx) => props.getChartColor(x.label, idx))
      : props.getDataColor
        ? x.data.map((val) => getDataColor(val, x.label))
        : props.getChartColor(x.label, i),
    // backgroundColor: x.data.map((i) => i > 0 ? "green" : "red"),

    type:
      typeof props.getDatasetChartType === "string"
        ? props.getDatasetChartType
        : (props.getDatasetChartType(x.label, i) as any),
    pointRadius: 0,
    borderWidth: 2,
  }));

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: displayLegend,
        position: "top" as const,
        labels: {
          color: theme.palette.text.primary,
          boxHeight: 5,
          boxWidth: 30,
        },
      },
      title: {
        display: props.title !== undefined ? true : false,
        text: props.title,
        color: theme.palette.text.secondary,
        // font: { size: 14.8, weight: "400", lineHeight: 1.75 },
        // align: 'start' as const,
        // padding: 2
      },
      tooltip: {
        mode: "nearest" as any,
        // mode: 'index',
        // position: 'average',
        intersect: false,
      },
      // hover: {
      //     mode: 'nearest' as any,
      //     // intersect: false
      // },
      // interaction: {
      //     mode: 'x'
      // }

      annotation: {
        annotations: chartDataQuery.data?.annotations,
      },
    },

    scales: {
      x: {
        type: 'time',
        parsing: false,
        time: {
          unit: 'day',
          tooltipFormat: 'M/d/yyyy',
        },
        grid: {
          display: false,
          drawOnChartArea: true,
          color: theme.palette.divider,
        },
        ticks: {
          display: !isArcElement && displayXAxis,
          color: theme.palette.text.secondary,
          font: {
            size: 10,
          },
        },
      },
      y: {
        position: "left",
        parsing: false,
        grid: {
          display: !isArcElement,
          color: theme.palette.divider,
        },
        ticks: {
          display: !isArcElement && displayYAxis,
          color: theme.palette.text.primary,
          fontSize: 6,
        },
      },
      y2: {
        position: "right",
        parsing: false,
        grid: {
          display: false,// don't show yaxis gridline at all (can be confusing with too many gridlines)
        },
        ticks: {
          display: !isArcElement && hasY2 && displayYAxis,
          color: theme.palette.text.primary,
          fontSize: 6,
        },
      },
    },

    animation: {
      duration: disableAnimations ? 0 : 800, // general animation time
    },
    responsiveAnimationDuration: 0, // animation duration after a resize
  };

  return (
    <Chart
      type={props.chartType}
      options={options}
      data={{
        datasets: chartDatasets || []
      }
      }
    />
  );
}
