import React, { FC, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, styled, useTheme } from '@mui/system'
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


interface ISelectCardProps {
    id: number;
    title: string;
    description: string;
    is_coming_soon?: boolean;
}


interface ISelectCardPropsActive extends ISelectCardProps {
    active?: boolean;
    onClick: () => void;
}

const TypeBox = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.card.background,

    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    minHeight: 140,
    borderRadius: 3,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,


    ':hover': {
        cursor: 'pointer'
    },

    '&.active': {
        borderColor: theme.palette.info.light,
        backgroundImage: 'none',
    },

}));


const BoxTitle = styled(Typography)(({ theme }) => ({
    textAlign: 'left',
    color: theme.palette.text.primary,
    fontWeight: 600,


    '&.disabled': {
        color: theme.palette.text.secondary,
    },

}));

const BoxDescription = styled(Typography)(({ theme }) => ({
    textAlign: 'left',
    color: theme.palette.text.secondary,
    minHeight: 50

}));

const ActiveIcon = styled(CheckCircleIcon)(({ theme }) => ({
    fontSize: 23,
    color: theme.palette.info.light,
    position: 'absolute',
    right: -10,
    top: -10,
    backgroundColor: theme.palette.card.background,
}));


function SelectCard(

    props: ISelectCardPropsActive

) {

    return (
        <Grid item xs={12} md={6} lg={3} >
            <Box key={props.id} sx={{ position: 'relative' }} onClick={props.onClick}>
                {props.active && <ActiveIcon />}
                <TypeBox className={props.active ? 'active' : ''}>

                    <BoxTitle className={props.is_coming_soon ? "disabled" : ""} variant="subtitle1" mb={1} >
                        {props.title}
                        {props.is_coming_soon && <Chip sx={{ ml: 2 }} label="Coming Soon" color="warning" size="small" variant="outlined" />}
                    </BoxTitle>
                    <BoxDescription variant="body2">{props.description}</BoxDescription>
                </TypeBox>
            </Box>
        </Grid>
    );

}


export default function useSelectCards(
    options: ISelectCardProps[],
    defaultValue?: number
): [number | null, React.FC] {

    const [activeId, setActiveId] = useState<number | null>(defaultValue || null);

    function onCardClick(card: ISelectCardProps) {
        if (card.is_coming_soon === true) {
            return;
        }
        setActiveId(card.id);
    }

    const SelectCards: React.FC = () => (
        <Grid
            container
            direction="row"
            justifyContent="left"
            alignItems="left"
            spacing={2}
        >
            {
                options.map((cardProps) => (

                    <SelectCard active={cardProps.id === activeId} key={cardProps.id} {...cardProps} onClick={() => onCardClick(cardProps)} />
                ))
            }
        </Grid>
    );

    return [activeId, SelectCards]


}

