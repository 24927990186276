import apiClient from "../services/apiClient";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { IAlertTriggered, IAlert } from "../interfaces/alerts"

interface INewAlert {
    view_id?: number;
    model_id?: number;
    strategy_id?: number;
    company_id?: number;
    value: number;
    operator: string;
    user_input_id: number;
}

interface IDeletedAlert {
    alert_id: number;
    view_id?: number;
    model_id?: number;
    strategy_id?: number;
}


function getAlertTypeAndId(alert: INewAlert | IDeletedAlert) {
    const alertType = alert.view_id ? 'view' : alert.model_id ? 'model' : alert.strategy_id ? 'strategy' : null;
    const subId = alert.view_id || alert.model_id || alert.strategy_id;
    return [alertType, subId]
}


export function useTriggeredAlertsQuery() {

    async function getTriggeredAlerts() {
        const { data } = await apiClient.get(`/alert/triggered`)
        return data
    }

    return useQuery<IAlertTriggered[]>(['alerts-triggered'], getTriggeredAlerts);
}


export function useViewAlertsQuery(view_id: number) {

    async function getViewAlerts() {
        const { data } = await apiClient.get(`/alert/view/${view_id}`)
        return data
    }

    return useQuery<IAlert[]>(['alerts', 'view', view_id], () => getViewAlerts());
}



export function useSetViewed() {

    // mutation for setting alerts to "viewed"
    async function setViewedAlerts(triggered_id: number) {
        const { data } = await apiClient.put(`/alert/triggered/${triggered_id}/viewed`)
        return data
    }

    const queryClient = useQueryClient()

    return useMutation((triggered_id: number) =>
        setViewedAlerts(triggered_id),
        {

            onMutate: async triggered_id => {
                // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
                await queryClient.cancelQueries(['alerts-triggered'])

                // Snapshot the previous value
                const previousAlerts = queryClient.getQueryData(['alerts-triggered'])

                // Optimistically update to the new value
                queryClient.setQueryData(['alerts-triggered'], (old: any) => {
                    return old.filter((e: any) => (e.id !== triggered_id));
                })

                // Return a context object with the snapshotted value
                return { previousAlerts }
            },

            onSuccess: () => {
                queryClient.invalidateQueries(['alerts-triggered']);
            },

        }
    )

}


export function useAddAlert() {

    async function addAlert(newAlert: INewAlert) {
        // const postData = {..newAlert, view_id: view_id }
        const { data } = await apiClient.post('/alert/', newAlert);
        return data
    }

    const queryClient = useQueryClient()

    return useMutation((newAlert: INewAlert) =>
        addAlert(newAlert),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['alerts']);
            },
        }
    )

}



export function useRemoveAlert() {

    async function removeAlert(alert_id: number) {
        const { data } = await apiClient.put(`/alert/${alert_id}/deactivate`)
        return data
    }

    const queryClient = useQueryClient()

    return useMutation((deletedAlert: IDeletedAlert) =>
        removeAlert(deletedAlert.alert_id),
        {

            onMutate: async deletedAlert => {

                const [alertType, subId] = getAlertTypeAndId(deletedAlert);

                // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
                await queryClient.cancelQueries(['alerts', alertType, subId])

                // Snapshot the previous value
                const previousAlerts = queryClient.getQueryData(['alerts', alertType, subId])

                // Optimistically update to the new value
                queryClient.setQueryData(['alerts', alertType, subId], (old: any) => {
                    return old.filter((e: any) => (e.id !== deletedAlert.alert_id));
                })

                // Return a context object with the snapshotted value
                return { previousAlerts }
            },

            // If the mutation fails,
            // use the context returned from onMutate to roll back
            // onError: (err, variables, context) => {
            //     if (context?.previousTodos) {
            //     queryClient.setQueryData<Todos>(['todos'], context.previousTodos)
            //     }
            // },

            // Always refetch after error or success:
            onSettled: () => {
                queryClient.invalidateQueries(['alerts'])
            },

        }
    )

}


