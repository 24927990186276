import { IChartResponse } from "../../interfaces";

type OutputType = Record<string, number | string>; // Allows mixed types for dynamic keys and the 'date'


export function convertDataForExport(chartData: IChartResponse) {

	if (!chartData || !chartData.datasets || !chartData.datasets.length) {
		return { "rows": [], "columns": [] };
	}

	// Step 1: Get the length of the data arrays (assuming they are of equal length)
	const input = chartData.datasets;
	const indexNames = chartData.labels;

	const result = input[0].data.map((_, index) => {
		// Start with the date field
		const row: OutputType = { index: indexNames[index] };

		// Add the dataset values dynamically
		input.forEach(obj => {
			row[obj.label] = obj.data[index];
		});

		return row;
	});

	// now get the headers for the columns. Array of objects with field and headerName. Also include the "index" field
	// which is the date field
	const columns = input.map((ds) => ({ field: ds.label, headerName: ds.label }));
	columns.unshift({ field: "index", headerName: "Date" });

	return { "rows": result, "columns": columns };

}
