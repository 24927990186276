import apiClient from "../services/apiClient";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ICompany } from "../interfaces";

interface IWatchlist {
	id: number;
	name: string;
}

export interface ICompanyWithCoverage {
	company_id: number;
	symbol: string;
	name: string;
	has_views: boolean;
	has_models: boolean;
	has_trends: boolean;
}


export function useWatchlistsQuery() {

	async function getWatchlists() {
		const { data } = await apiClient.get('/watchlist/')
		return data
	}

	// return data.map((x: any) => ({ id: x.id, symbol: x.name, name: "Watchlist" }))
	return useQuery<IWatchlist[]>(['watchlists'], () => getWatchlists())
}

export function useWatchlistsByCompanyQuery(company_id: number) {

	async function getWatchlistsByCompany() {
		const { data } = await apiClient.get(`/watchlist/company/${company_id}`)
		return data
	}

	// return data.map((x: any) => ({ id: x.id, symbol: x.name, name: "Watchlist" }))
	return useQuery<IWatchlist[]>(['watchlists', 'company', company_id], () => getWatchlistsByCompany())
}


export function useWatchlistCompaniesQuery(watchlist_id: number) {

	async function getWatchlistCompanies() {
		const { data } = await apiClient.get(`/watchlist/tickers/${watchlist_id}`)
		return data
	}

	return useQuery<ICompany[]>(['watchlist_companies', watchlist_id], () => getWatchlistCompanies(),
		{ enabled: watchlist_id !== null && watchlist_id !== 0 })
}



export function useRemoveWatchlistCompanies(watchlist_id: number) {

	async function removeWatchlistCompanies(company_ids: number[]) {

		const { data } = await apiClient.post(`/watchlist/tickers/multi/delete/${watchlist_id}`, company_ids);
		return data
	}

	const queryClient = useQueryClient()

	return useMutation((company_ids: number[]) =>
		removeWatchlistCompanies(company_ids),
		{

			onMutate: async company_ids => {
				// Cancel any outgoing refetches (so they don't overwrite our optimistic update)
				await queryClient.cancelQueries(['watchlist_companies', watchlist_id])

				// Snapshot the previous value
				const previousCompanies = queryClient.getQueryData(['watchlist_companies', watchlist_id])

				// Optimistically update to the new value
				queryClient.setQueryData(['watchlist_companies', watchlist_id], (old: any) => {
					return old.filter((e: any) => (!company_ids.includes(e.id)));
				})

				// Return a context object with the snapshotted value
				return { previousCompanies }
			},

			onSuccess: () => {
				queryClient.invalidateQueries(['watchlist_companies', watchlist_id]);
				queryClient.invalidateQueries(['watchlist_companies_coverage']);
			},

		}
	)

}




export function useAddWatchlistCompanies(watchlist_id: number) {

	async function addWatchlistCompanies(company_ids: number[]) {
		const { data } = await apiClient.post(`/watchlist/tickers/${watchlist_id}`, company_ids);
		return data
	}

	const queryClient = useQueryClient()

	return useMutation((company_ids: number[]) =>
		addWatchlistCompanies(company_ids),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['watchlist_companies', watchlist_id]);
				queryClient.invalidateQueries(['watchlist_companies_coverage']);
			},

		}
	)

}


export function useAddWatchlistCompaniesFile(watchlist_id: number) {

	async function addWatchlistCompaniesFile(file: File) {

		var formData = new FormData();
		formData.append("ticker_file", file);

		return await apiClient.post(`/watchlist/tickers/file/${watchlist_id}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
	}

	const queryClient = useQueryClient()

	return useMutation((file: File) =>
		addWatchlistCompaniesFile(file),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['watchlist_companies', watchlist_id]);
				queryClient.invalidateQueries(['watchlist_companies_coverage']);
			},

		}
	)

}




export function useRemoveWatchlist() {

	async function removeWatchlist(watchlist_id: number) {
		const { data } = await apiClient.put(`/watchlist/deactivate/${watchlist_id}`);
		return data
	}

	const queryClient = useQueryClient()

	return useMutation((watchlist_id: number) =>
		removeWatchlist(watchlist_id),
		{

			onMutate: async watchlist_id => {
				// Cancel any outgoing refetches (so they don't overwrite our optimistic update)
				await queryClient.cancelQueries(['watchlists'])

				// Snapshot the previous value
				const previousWatchlists = queryClient.getQueryData(['watchlists', watchlist_id])

				// Optimistically update to the new value
				queryClient.setQueryData(['watchlists'], (old: any) => {
					return old.filter((e: any) => (watchlist_id !== e.id));
				})

				// Return a context object with the snapshotted value
				return { previousWatchlists }
			},

			onSettled: () => {
				queryClient.invalidateQueries(['watchlists']);
			},

		}
	)

}


export function useWatchlistCompaniesWithCoverageQuery() {

	async function getWatchlistCompaniesWithCoverage() {
		const { data } = await apiClient.get(`/watchlist/tickers/coverage`)
		return data
	}

	return useQuery<ICompanyWithCoverage[]>(['watchlist_companies_coverage'], () => getWatchlistCompaniesWithCoverage(),
		{ refetchOnWindowFocus: false, refetchOnMount: false, staleTime: Infinity }
	)
}
