import apiClient from "../services/apiClient";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';


interface IModel {
	id: number;
	name: string;
}


export interface ICompanyMetric {
	metric_id: number;
	company_id: number;
	metric_name: string;
	company_name: string;
	is_parent: boolean;
	is_primary?: boolean;
	vendor_name?: string;

}

export interface ICompanyPeriod {
	company_id: number;
	period_id: string;
	fs_period: string;
	is_reported: boolean;
}



interface ICorrelation {
	id: number;
	company_id: number;
	symbol: string;
	metric: string;
	metric_id: number;
	fit_score: number;
}



export interface IModelResultsTableRecord {
	id: number;
	model_name: string;
	company_id: number;
	metric_id: number;
	metric: string;
	company: string;
	symbol: string;
	fiscal_period: string;
	period_end: string;
	data_date: string;
	model_prediction: number;
	consensus: number;
	reported: number;
	predicted_surprise: number;
	model_percent_error: number;
	consensus_percent_error: number;
	mae: number;
	mae_4: number;
	mae_2: number;
	std: number;
	combined_fit: number;

}


export interface IModelInputTableRecord {
	id: number;
	metric_id: number;
	period_id: number;
	period: string;
	actual: number;
	consensus: number;
	excluded?: boolean;
	feaure_1?: number;
	feaure_2?: number;
	feaure_3?: number;
	feaure_4?: number;
	feaure_5?: number;
	feaure_6?: number;
	feaure_7?: number;
	feaure_8?: number;
	feaure_9?: number;
	feaure_10?: number;

}

export interface IModelInput {
	id: number;
	name: string;
	title: string;
	vendor_id: number;
	vendor_name: string;
	data_type: string;
	is_selected?: boolean;
}

export interface IModelInputUpdate {
	field_id: number;
	is_selected: boolean;
}


interface IEarningsEstimate {
	period: string;
	estimate: number;
	est_change: number | null;
	est_surprise: number | null;

}

interface IModelFamily {
	id: number;
	name: string;
	title: string;
}

interface IModelMetricFieldMap {
	company_id: number;
	metric_id: number;
	field_id: number;
}


interface IModelMapping {
	id: number;
	name: string;
}


interface IUserModelMetricSelect {
	metric_id: number;
	is_selected: boolean;
}

interface IModelTableSettings {
	column_visibility: { [key: string]: boolean };
}


export function useModelCompanyMetricsQuery(company_id: number) {

	async function getModelCompanyMetrics() {
		const { data } = await apiClient.get(`/models/metrics/${company_id}`);
		return data
	}

	return useQuery<ICompanyMetric[]>(['model_metrics', company_id], () => getModelCompanyMetrics(),
		{ enabled: company_id > 0, refetchOnWindowFocus: false, refetchOnMount: false, staleTime: Infinity }
	)
}

export function useModelCompanyPeriodsQuery(metric_id: number) {

	async function getModelCompanyPeriods() {
		const { data } = await apiClient.get(`/models/periods/${metric_id}`);
		return data
	}

	return useQuery<ICompanyPeriod[]>(['model_periods', metric_id], () => getModelCompanyPeriods(),
		{ enabled: metric_id > 0, refetchOnWindowFocus: false, refetchOnMount: false, staleTime: Infinity })
}



export function useAddPrimaryCompanyMetric() {

	async function addPrimaryCompanyMetric(company_id: number, metric_id: number) {
		const { data } = await apiClient.post(`/models/metrics/primary/${company_id}/${metric_id}`);
		return data
	}

	const queryClient = useQueryClient()

	return useMutation((addedMetric: { company_id: number, metric_id: number }) =>
		addPrimaryCompanyMetric(addedMetric.company_id, addedMetric.metric_id),


		{
			// optimistically make primary
			onMutate: async addedMetric => {
				// Cancel any outgoing refetches (so they don't overwrite our optimistic update)
				await queryClient.cancelQueries(['model_metrics', addedMetric.company_id]);

				// Snapshot the previous value
				const previousMetrics: ICompanyMetric[] | undefined = queryClient.getQueryData(['model_metrics', addedMetric.company_id]);

				if (!previousMetrics) return;

				// set is_primary=true for the new metric
				const newMetrics = previousMetrics.map((metric: ICompanyMetric) => ({ ...metric, is_primary: metric.metric_id === addedMetric.metric_id }));

				// Optimistically update to the new value
				queryClient.setQueryData(['model_metrics', addedMetric.company_id], newMetrics);

				// Return a context object with the snapshotted value
				return { newMetrics }
			},

			// note, dont' pass any onSuccess here, since will be overwritten by onmutate call.
			onSettled: () => {
				queryClient.invalidateQueries(['model_summary_table']);
			}

		}



	)

}


export function useModelInputOptionsMetricQuery(metric_id: number) {

	async function getModelInputOptions() {
		const { data } = await apiClient.get(`/models/inputs/options/metric/${metric_id}`);
		return data
	}

	return useQuery<IModelInput[]>(['model_inputs_options', 'metric', metric_id], () => getModelInputOptions(),
		{ enabled: metric_id > 0, refetchOnWindowFocus: false, refetchOnMount: false, staleTime: Infinity })
}

export function useModelInputOptionsMappingQuery(model_map_id: number) {

	async function getModelInputOptionsByMapping() {
		const { data } = await apiClient.get(`/models/inputs/options/mapping/${model_map_id}`);
		return data
	}

	return useQuery<IModelInput[]>(['model_inputs_options', 'mapping', model_map_id], () => getModelInputOptionsByMapping(),
		{ enabled: model_map_id > 0, refetchOnWindowFocus: false, refetchOnMount: false, staleTime: Infinity })
}

export function useModelInputOptionsAllQuery() {

	async function getModelInputOptions() {
		const { data } = await apiClient.get(`/models/inputs/options`);
		return data
	}

	return useQuery<IModelInput[]>(['model_inputs_options'], () => getModelInputOptions(),
		{ refetchOnWindowFocus: false, refetchOnMount: false, staleTime: Infinity })
}


export function useModelMetricFieldMapQuery(company_id: number) {

	async function getModelMetricFieldMap() {
		const { data } = await apiClient.get(`/models/inputs/mapping/${company_id}`);
		return data
	}

	return useQuery<IModelMetricFieldMap[]>(['model_metric_field__map', company_id], () => getModelMetricFieldMap(),
		{ refetchOnWindowFocus: false, refetchOnMount: false, staleTime: Infinity })
}


export function useUpdateUserModelInputsGlobal() {

	async function updateUserModelInputs(input_updates: IModelInputUpdate[]) {
		const url = '/models/inputs';
		const { data } = await apiClient.post(url, input_updates)
		return data
	}

	const queryClient = useQueryClient();

	return useMutation((input_updates: IModelInputUpdate[]) =>
		updateUserModelInputs(input_updates),
		{

			onSuccess: () => {
				// TOOD: fix invalidations, if only metric_id is passed, then invalidate all queries that use metric_id
				queryClient.invalidateQueries(['model_inputs_options']);
				queryClient.invalidateQueries(['model_summary_table']);
				queryClient.invalidateQueries(['model_charts']);
				queryClient.invalidateQueries(['model_correlations']);
			},
		})

}
export function useUpdateUserModelInputsMetric(metric_id: number) {

	async function updateUserModelInputs(input_updates: IModelInputUpdate[]) {
		const url = `/models/inputs/${metric_id}`;
		const { data } = await apiClient.post(url, input_updates)
		return data
	}

	const queryClient = useQueryClient();

	return useMutation((input_updates: IModelInputUpdate[]) =>
		updateUserModelInputs(input_updates),
		{

			onSuccess: () => {
				// TOOD: fix invalidations, if only metric_id is passed, then invalidate all queries that use metric_id
				queryClient.invalidateQueries(['model_inputs_options', 'metric', metric_id]);
				queryClient.invalidateQueries(['model_summary_table']);
				queryClient.invalidateQueries(['model_charts', metric_id]);
			},
		})

}

export function useModelEarningsEstimate(metric_id: number, field_id: number, model_family_id: number) {

	async function getModelEarningsEstimate() {
		const { data } = await apiClient.get(`/model-charts/upcoming-earnings-estimate/${metric_id}/${field_id}/${model_family_id}`);
		return data
	}

	return useQuery<IEarningsEstimate[]>(['model_charts', metric_id, field_id, model_family_id, "upcoming_earnings_estimate"], () => getModelEarningsEstimate(),
		{ enabled: metric_id !== 0, refetchOnWindowFocus: false, refetchOnMount: false, staleTime: Infinity })
}



export function useModelCorrelationsQuery(model_map_id: number, params: { watchlist_id?: number | null, period_compare?: string | null, max_periods?: string | null }) {

	async function getModelCorrelations() {
		const { data } = await apiClient.get(`/models/correlations/${model_map_id}`,
			{
				params: params
			}
		);
		return data
	}

	return useQuery<ICorrelation[]>(['model_correlations', model_map_id, params], () => getModelCorrelations(),
		{ enabled: model_map_id > 0, refetchOnWindowFocus: false, refetchOnMount: false, staleTime: Infinity }
	)
}

export function useModelEstimateSummaryQuery(field_id: number, model_family_id: number, watchlist_id: number) {


	async function getModelEstSummary() {
		const { data } = await apiClient.get(`/model-charts/estimate-summary-table/${field_id}/${model_family_id}/${watchlist_id}`);
		return data
	}

	return useQuery<IModelResultsTableRecord[]>(['model_summary_table', field_id, model_family_id, watchlist_id], () => getModelEstSummary(),
		{ refetchOnWindowFocus: false, refetchOnMount: true, staleTime: Infinity }
	)
}



export function useMetricSensitivityQuery(metric_id: number) {

	async function getMetricSensitivity() {
		const { data } = await apiClient.get(`/models/metrics/sensitivity/${metric_id}`);
		return data
	}

	return useQuery<number>(['metric_sensitivity', metric_id], () => getMetricSensitivity(),
		{ enabled: metric_id !== 0, refetchOnWindowFocus: false, refetchOnMount: true, staleTime: Infinity }
	)
}


export function useModelFamilyQuery() {

	async function getModelFamily() {
		const { data } = await apiClient.get('/models/families');
		return data
	}

	return useQuery<IModelFamily[]>(['model_family'], () => getModelFamily(),
		{ refetchOnWindowFocus: false, refetchOnMount: false, staleTime: Infinity }
	)
}

export function useModelMappingQuery() {

	async function getModelMapping() {
		const { data } = await apiClient.get('/models/mappings');
		return data
	}

	return useQuery<IModelMapping[]>(['model_mappings'], () => getModelMapping(),
		{ refetchOnWindowFocus: false, refetchOnMount: false, staleTime: Infinity }
	)
}



export function useUpdateUserModelMetricSelection() {

	async function updateUserModelMetricSelection(metric_select_updates: IUserModelMetricSelect[]) {
		const url = '/models/metric-selection';
		const { data } = await apiClient.post(url, metric_select_updates)
		return data
	}

	const queryClient = useQueryClient();

	return useMutation((input_updates: IUserModelMetricSelect[]) =>
		updateUserModelMetricSelection(input_updates),
		{

			onSuccess: () => {
				queryClient.invalidateQueries(['model_summary_table']);
			},
		})

}

export function useModelSummaryTableSettings() {

	async function getModelTableSettings() {
		const { data } = await apiClient.get('/models/summary-table-settings');
		return data
	}

	return useQuery<IModelTableSettings>(['model_table_settings'], () => getModelTableSettings(),
		{ refetchOnWindowFocus: false, refetchOnMount: false, staleTime: Infinity }
	)
}


export function useUpdateModelSummaryTableSettings() {

	async function updateModelSummaryTableSettings(table_settings: IModelTableSettings) {
		const { data } = await apiClient.post('/models/summary-table-settings', table_settings)
		return data
	}

	const queryClient = useQueryClient();

	return useMutation((table_settings: IModelTableSettings) =>
		updateModelSummaryTableSettings(table_settings),
		{
			// optimistically update settings
			// note, this overwrites all settings, so must mutate all settings at once (not incrementally)
			onMutate: async table_settings => {
				// Cancel any outgoing refetches (so they don't overwrite our optimistic update)
				await queryClient.cancelQueries(['model_table_settings']);

				// Optimistically update to the new value
				queryClient.setQueryData(['model_table_settings'], table_settings);

				// Return a context object with the snapshotted value
				return { table_settings }
			},
		})

}
