import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import React from 'react';
import ModelResultsTable from '../components/Model/ModelResultsTable';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Autocomplete from '@mui/material/Autocomplete';
import { useModelInputOptionsAllQuery, useModelFamilyQuery, IModelInput } from "../api/models";
import { useWatchlistsQuery } from "../api/watchlist";
import { useUserSelectionQuery, useUpdateUserSelection } from "../api/user_selection";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SpokeIcon from '@mui/icons-material/Spoke';
import LinearProgress from '@mui/material/LinearProgress';


export default function ModelResults() {

    const [searchParams, setSearchParams] = useSearchParams();

    const field_id: number = parseInt(searchParams.get("data") || "0");
    const model_family_id: number = parseInt(searchParams.get("model_family") || "0");

    const userSelectionQuery = useUserSelectionQuery(); // get saved user selection for watchlist
    const updateSelection = useUpdateUserSelection();
    const modelFamilyQuery = useModelFamilyQuery();
    const modelInputOptionsQuery = useModelInputOptionsAllQuery();
    const watchlistsQuery = useWatchlistsQuery();
    const combinedModel: IModelInput = { id: 0, name: 'ARB COMBINED', title: '', vendor_id: 0, vendor_name: '', is_selected: true, data_type: '' };
    const modelOptions = modelInputOptionsQuery.data ? [combinedModel].concat(modelInputOptionsQuery.data) : [];
    const watchlistOptions = watchlistsQuery.data ? [{ id: 0, name: 'All Tickers' }].concat(watchlistsQuery.data) : [];

    const navigate = useNavigate();

    if (userSelectionQuery.data === undefined || userSelectionQuery.isLoading || modelFamilyQuery.isLoading || modelInputOptionsQuery.isLoading || watchlistsQuery.isLoading) {
        return (
            <Box sx={{ width: '100%' }}>
                <LinearProgress />
            </Box>
        );
    }

    // the selected watchlist is stored in the user selection DB, no need for search params
    const watchlist_id: number = userSelectionQuery.data['forecast_summary_watchlist'] || 0;

    return (
        <Box sx={{ height: "83vh" }}>

            <Box
                sx={{
                    marginTop: 3,
                    marginBottom: 3,
                    marginLeft: 4,
                    marginRight: 4
                }}>
                <Stack direction="row" justifyContent='space-between' alignItems="center" >

                    <Typography variant="h5">KPI Forecast Summary</Typography>

                    <Stack direction="row" spacing={2} >


                        {(watchlistOptions && watchlistOptions.length > 0) &&
                            <Autocomplete
                                id="forecast_summary_watchlist"
                                size="small"
                                disableClearable
                                options={watchlistOptions}
                                getOptionLabel={(option) => option.name}
                                value={watchlistOptions.find((option) => option.id === watchlist_id) || watchlistOptions[0]}
                                onChange={(event, newValue) => {

                                    // update user selection (in DB)
                                    const selection = { forecast_summary_watchlist: newValue.id };
                                    updateSelection.mutate(selection);


                                }}

                                isOptionEqualToValue={(option, value) => value.id === option.id}
                                ChipProps={{ deleteIcon: <></> }}
                                sx={{ width: 200 }}
                                renderInput={(params) => (
                                    <>
                                        <TextField
                                            {...params}
                                            label="Watchlist"
                                            color="info"
                                        />
                                    </>

                                )}

                            />
                        }


                        {(modelOptions && modelOptions.length > 0)
                            &&
                            <Autocomplete
                                size="small"
                                disableClearable
                                options={modelOptions}
                                getOptionLabel={(option) => option.name}
                                value={modelOptions.find((option) => option.id === field_id) || combinedModel
                                }
                                onChange={(event, newValue) => {
                                    searchParams.set("data", String(newValue.id));
                                    setSearchParams(searchParams);
                                }}
                                groupBy={(option) => option.vendor_name}
                                isOptionEqualToValue={(option, value) => value.id === option.id}
                                ChipProps={{ deleteIcon: <></> }}
                                sx={{
                                    width: 250,

                                    "& + .MuiAutocomplete-popper .MuiAutocomplete-option":
                                    {
                                        backgroundColor: "#363636",
                                    },

                                }}

                                renderInput={(params) => (
                                    <>
                                        <TextField
                                            {...params}
                                            hiddenLabel
                                            label={"Model Data"}
                                            color="info"
                                        />
                                    </>

                                )}

                            />

                        }

                        {(modelFamilyQuery.data && modelFamilyQuery.data.length > 0) &&
                            <Autocomplete
                                size="small"
                                disableClearable
                                options={modelFamilyQuery.data}
                                getOptionLabel={(option) => option.title}
                                value={modelFamilyQuery.data.find((option) => option.id === model_family_id) || modelFamilyQuery.data[0]}
                                onChange={(event, newValue) => {

                                    searchParams.set("model_family", String(newValue.id));
                                    setSearchParams(searchParams);

                                }}

                                isOptionEqualToValue={(option, value) => value.id === option.id}
                                ChipProps={{ deleteIcon: <></> }}
                                sx={{ width: 200 }}
                                renderInput={(params) => (
                                    <>
                                        <TextField
                                            {...params}
                                            label="Model"
                                            color="info"
                                        />
                                    </>

                                )}

                            />
                        }

                        <Button
                            color="primary"
                            onClick={() => navigate("/model-features")}
                            startIcon={<SpokeIcon />}
                            variant="outlined"
                        >
                            Correlations
                        </Button>

                    </Stack>

                </Stack>

            </Box>

            <Box
                height="100%"
                sx={{

                    // padding: 25,
                    marginTop: 3,
                    marginBottom: 3,
                    marginLeft: 4,
                    marginRight: 4
                }}
            >

                <ModelResultsTable field_id={field_id} model_family_id={model_family_id} watchlist_id={watchlist_id} />

            </Box >




        </Box >



    );

};
