import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const NotFound = () => (
    <Box sx={{ m: 4 }}>
        <Typography variant="h4">404 - Page Not Found</Typography>
        <Button size="large" component={Link} to="/">Go Home</Button>
    </Box>
);

export default NotFound;