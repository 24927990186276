import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/system';
import { useQueryClient } from '@tanstack/react-query';
import React, { useState, useEffect } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';

import {
	useStrategyUniverseOptionsQuery, useStrategyUniverseQuery,
	useAddStrategyUniverse, useAddStrategyAlpha, useRemoveStrategyAlpha, useStrategyQuery,
	useStrategyAlphasQuery, useRunStrategy, useStrategyLastSuccessRunQuery
} from '../api/strategy';
import {
	StrategyCard, StrategyFactorBlocks, StrategyPerformanceCharts,
	StrategyPositionDetails, StrategySettingsDialog
} from "../components";
import AddFactorDialog from "../components/Alphas/AddFactorDialog"
import { useErrorMessageAlert } from "../components/Errors/useErrorMessageAlert"
import { INewAlpha } from '../api/alphas';
import { ISymbol } from "../interfaces";
import { AxiosError } from 'axios'


function UniverseSelect(props: {
	options: ISymbol[],
	valueId: number | undefined,
	handleChange: (event: SelectChangeEvent) => void,
	disabled?: boolean,

}) {

	return (

		<Stack
			direction="row"
			justifyContent="flex-start"
			alignItems="center"
			spacing={2}
			sx={{ ml: 2, mb: 2 }}
		>
			<Typography variant="subtitle1" sx={{ color: 'text.primary', fontWeight: 500 }}>
				Universe:
			</Typography>


			<Select
				value={props.valueId ? String(props.valueId) : ""}
				onChange={props.handleChange}
				displayEmpty
				size="small"
				autoWidth
				disabled={props.disabled || false}
			>
				<MenuItem disabled value="">
					<em>Strategy Universe</em>
				</MenuItem>

				{props.options.map((item) => {
					return (
						<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
					)
				})}

				<Divider />
				<MenuItem component="a" href='/watchlist'>
					<ListItemIcon>
						<AddIcon color="success" fontSize="small" />
					</ListItemIcon>
					<ListItemText>New</ListItemText>
				</MenuItem>

			</Select>
		</Stack>
	)
}




export default function StrategyBuilder() {

	type strategyDisplayType = "performance" | "posiiton";

	const urlParams = useParams(); // strategy id
	const strategy_id = parseInt(urlParams.strategy_id !== undefined ? urlParams.strategy_id : "0");
	const displaySelect = (urlParams.displaySelect || "performance") as strategyDisplayType;

	const navigate = useNavigate();

	const { setErrorMessage, ErrorMessageAlert, setErrorMessageFromResponse } = useErrorMessageAlert();

	// track if dataset or security changed
	const [hasExistingRun, setHasExistingRun] = useState<boolean>(false);
	const [isAddDialogOpen, setIsAddDialogOpen] = useState<boolean>(false);

	// queries
	const queryClient = useQueryClient();

	const strategyAlphasQuery = useStrategyAlphasQuery(strategy_id);
	const strategyQuery = useStrategyQuery(strategy_id);
	const strategyLastSuccessRunQuery = useStrategyLastSuccessRunQuery(strategy_id);

	// mutations
	const strategyUniverseOptionsQuery = useStrategyUniverseOptionsQuery();
	const strategyUniverseQuery = useStrategyUniverseQuery(strategy_id);
	const addStrategyUniverse = useAddStrategyUniverse(strategy_id);
	const removeStrategyAlpha = useRemoveStrategyAlpha(strategy_id);
	const addStrategyAlpha = useAddStrategyAlpha(strategy_id);

	const [settingsDialogIsOpen, setSettingsDialogIsOpen] = useState<boolean>(false);
	// const [searchParams, setSearchParams] = useSearchParams();  // used for view type (performance summary vs position details)
	// const displaySelect: strategyDisplayType = searchParams.get("display") as strategyDisplayType || "performance";  // default to performance view

	const { runBacktestMutation, data, isRunning } = useRunStrategy(
		strategy_id,
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['strategy_performance', strategy_id]); // refetch also inactive queries , { refetchType: 'all' }
				queryClient.invalidateQueries(['strategy_positions', strategy_id]);
				queryClient.invalidateQueries(['strategy_run_success', strategy_id]);
				setHasExistingRun(true);
			},
			onError: (error: any | AxiosError) => {
				setErrorMessageFromResponse("There was an error running the backtest, please try again. If the problem persists, please contact support.");
			}
		}

	);

	function onBacktestRun() {
		runBacktestMutation.mutate();
	}

	function RunInitialButton(props: { disabled: boolean }) {

		return (
			<Button
				sx={{ minWidth: 340, minHeight: 45, margin: 1, fontSize: 16, }}
				size='large'
				disabled={props.disabled}
				onClick={onBacktestRun}
				variant="contained" endIcon={<PlayCircleIcon />}>
				Run Backtest
			</Button>
		);

	}


	function onAddStrategyAlpha(newAlpha: INewAlpha) {

		// add selected alpha to Strategy
		addStrategyAlpha.mutate({
			name: newAlpha.name,
			factor_sign: newAlpha.factor_sign,
			sub_alpha_ids: newAlpha.sub_alpha_ids
		},
			{
				onSuccess: () => {
					setIsAddDialogOpen(false);
				},
				onError: (error: any | AxiosError) => {
					setErrorMessageFromResponse(error);
				}
			}
		)

	}


	// if the user has selected the required item before we can run a backtest
	const runDisabled = strategyAlphasQuery.data?.length === 0 || strategyAlphasQuery.isLoading || !strategyUniverseQuery.data?.watchlist_id || strategyUniverseQuery.isLoading;

	// if the startegy has been run before, show the last run date
	useEffect(() => {

		setHasExistingRun(false);

		if (strategyLastSuccessRunQuery.data) {
			setHasExistingRun(true);
		}


	}, [strategy_id, strategyLastSuccessRunQuery.data]);

	// while we wait for fetching the basic infomation (existing runs) just show a loading spinner
	if (strategyQuery.isLoading || strategyAlphasQuery.isLoading || strategyLastSuccessRunQuery.isLoading) {
		return (
			<Box sx={{ width: '100%' }}>
				<LinearProgress />
			</Box>
		)

	}


	return (

		<>

			<Box sx={{ ml: 2, mr: 2, mt: 2 }}>

				<Box
					// container
					// spacing={2}
					display="flex"
					flexDirection="row"
					// justifyContent="center"
					// alignItems="center"
					sx={{ width: "100%" }}
				>

					<Box flexGrow={2} sx={{ ml: 2 }}>

						<Grid container direction="column" spacing={2}>

							<Grid item xs={2}>

								<Stack direction="row" spacing={2} sx={{ width: "100%" }}>

									<StrategyCard flexGrow={2} >

										<Stack direction="row" spacing={2} justifyContent='space-between' sx={{ mb: 2 }}>
											<Typography variant="h6" color="primary">{strategyQuery.data?.name || ""}</Typography>
											<Button onClick={() => setSettingsDialogIsOpen(true)} disabled={isRunning} variant='outlined'>Backtest Settings</Button>
										</Stack >

										{
											(!strategyUniverseQuery.isLoading && !strategyUniverseOptionsQuery.isLoading && strategyUniverseOptionsQuery.data && strategyUniverseOptionsQuery.data?.length > 0) ?

												<UniverseSelect
													options={strategyUniverseOptionsQuery.data || []}
													disabled={isRunning}
													valueId={strategyUniverseQuery.data && strategyUniverseQuery.data?.watchlist_id}
													handleChange={(event) => {
														// addStrategyUniverse.mutate(parseInt(event.target.value), { onSuccess: () => setHasChanged(true) }); // pass watchlist id
														addStrategyUniverse.mutate(parseInt(event.target.value)); // pass watchlist id
													}}
												/>
												: <></>}

										{!strategyAlphasQuery.isLoading &&
											<StrategyFactorBlocks
												alphas={strategyAlphasQuery.data}
												disabled={isRunning}
												setIsAddDialogOpen={setIsAddDialogOpen}
												handleRemove={(id) => {
													// removeStrategyAlpha.mutate(id, { onSuccess: () => setHasChanged(true) });
													removeStrategyAlpha.mutate(id);
												}}

											/>
										}



									</StrategyCard>


								</Stack>

							</Grid>


							<Grid item xs={10}>

								<StrategyCard className="chartWorksheet">


									{/* if no backtest was run for this strategy, just show single large button to run a first backtest   */}
									{(!hasExistingRun && !isRunning) && <RunInitialButton disabled={runDisabled} />}
									{(!hasExistingRun && isRunning) &&

										<Box sx={{ width: '100%' }}>
											<LinearProgress />
										</Box>
									}


									{hasExistingRun &&
										<Box>

											<Stack direction="row" alignContent="center" >

												<Box flexGrow={1}>
													<Select
														value={displaySelect}
														variant="outlined"
														size="small"
														onChange={(event: SelectChangeEvent<string>) => {
															// searchParams.set("display", event.target.value);
															// setSearchParams(searchParams);
															navigate(`/strategy-builder/${event.target.value}/${strategy_id}`);
														}}

													>

														<MenuItem value="performance">Performance Summary</MenuItem>
														<MenuItem value="position">Position Details</MenuItem>

													</Select>
												</Box>

												{/* if running show spinner, else show run button */}
												{/* {true ?
													<Stack direction="row">
														<Typography variant="subtitle1" sx={{ mt: 2, ml: 2 }}>Running backtest...</Typography>
														<CircularProgress />
													</Stack>
													:
													<Button
														onClick={onBacktestRun}
														variant="contained" endIcon={<PlayCircleIcon />}>
														Run Backtest
													</Button>
												} */}

												{strategyLastSuccessRunQuery.isFetching ? <Skeleton variant="rectangular" animation="wave" width={215} height={40} />

													:

													<Typography variant="subtitle1" color="text.secondary" fontWeight={500}>
														Last Run: &nbsp;
														{strategyLastSuccessRunQuery.data ? new Date(strategyLastSuccessRunQuery.data.created_at).toLocaleString() : "Never"}
													</Typography>

												}


												<Box sx={{ ml: 2 }} position='relative'>

													<Button

														variant="contained"
														sx={{
															minWidth: 180,
															...(!isRunning && {
																bgcolor: "primary.main",
																'&:hover': {
																	bgcolor: "primary.dark",
																},
															}),
														}}
														disabled={isRunning}
														onClick={onBacktestRun}
														endIcon={!isRunning ? <PlayCircleIcon /> : <></>}
													>
														{!isRunning ? "Run Backtest" : "Running Backtest..."}
													</Button>
													{isRunning && (
														<CircularProgress
															size={24}
															sx={{
																color: 'primary.main',
																position: 'absolute',
																top: '50%',
																left: '50%',
																marginTop: '-12px',
																marginLeft: '-12px',
															}}
														/>
													)}
												</Box>



											</Stack>

											{displaySelect === "performance" ?
												<StrategyPerformanceCharts strategy_id={strategy_id} />
												: <StrategyPositionDetails strategy_id={strategy_id} />
											}

										</Box>

									}



								</StrategyCard>

							</Grid>

						</Grid>

					</Box>


				</Box >
			</Box>

			<StrategySettingsDialog strategy_id={strategy_id}
				isOpen={settingsDialogIsOpen} handleClose={() => setSettingsDialogIsOpen(false)} />

			<AddFactorDialog
				onAddFactor={onAddStrategyAlpha}
				isOpen={isAddDialogOpen}
				handleClose={() => setIsAddDialogOpen(false)}
				isLoading={addStrategyAlpha.isLoading}
				setErrorMessage={setErrorMessage}
			/>

			{ErrorMessageAlert}


		</>

	);

};
