
import Typography from '@mui/material/Typography';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import LinearProgress from '@mui/material/LinearProgress';
import React from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useUpdateStrategy, useStrategyQuery, StrategyNormType, PortfolioType, StrategyPositionSizingType } from "../../api/strategy"
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import moment, { Moment } from 'moment';
import { IconButton } from '@mui/material';


const NORM_OPTIONS = [
	{ title: "Z-Score", value: "zscore" },
	{
		title: "Ranked Normalize",
		value: "rank_norm"
	},
];


const POSITION_SIZING_OPTIONS = [
	{ title: "Signal Weighted", value: "signal_weighted" },
	{ title: "Top - Bottom Quantile", value: "quantiles" },

];

const PORTFOLIO_TYPE_OPTIONS = [
	{ title: "Long Short", value: "long_short" },
	{ title: "Long Only", value: "long_only" },

];

const DATA_LAG_OPTIONS = [
	{ title: "0 Days", value: "0" },
	{ title: "1 Day", value: "1" },
	{ title: "2 Days", value: "2" },
	{ title: "3 Days", value: "3" },
	{ title: "4 Days", value: "4" },
	{ title: "5 Days", value: "5" },
	{ title: "6 Days", value: "6" },
	{ title: "7 Days", value: "7" }

];
const NUM_QUANTILES_OPTIONS = [
	{ title: "2", value: "2" },
	{ title: "3", value: "3" },
	{ title: "4", value: "4" },
	{ title: "5", value: "5" },
	{ title: "6", value: "6" },
	{ title: "7", value: "7" },
	{ title: "8", value: "8" },
	{ title: "9", value: "9" },
	{ title: "10", value: "10" },

];


function SettingsSelect(props: {
	title: string,
	selectedSetting: string,
	setSetting: (value: string) => void,
	options: { title: string, value: string }[]
	helperText?: string
}) {

	return (
		<Stack
			direction="row"
			justifyContent="flex-start"
			alignItems="center"
			spacing={1}
		>
			<Box sx={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				minWidth: 130
			}}>

				<Stack direction="row" alignItems="center" >
					<Typography>{props.title}:</Typography>

					{props.helperText &&
						<Tooltip
							title={<Typography fontSize={14}>{props.helperText}</Typography>}
						>
							<IconButton>
								<InfoOutlinedIcon color="disabled" sx={{ fontSize: 16 }} />
							</IconButton>
						</Tooltip>
					}

				</Stack>


			</Box>
			< Select
				value={props.selectedSetting}
				onChange={(event: SelectChangeEvent) => props.setSetting(event.target.value)}
				displayEmpty
				size="small"
			>

				{props.options.map((i) => {
					return (<MenuItem key={i.value} value={i.value}>{i.title}</MenuItem>)
				})}

			</Select>
		</Stack >
	)
}



function DateStartSelect(props: {
	startDate: string,
	setStartDate: (date_start: string) => void
}) {

	function handleChange(newValue: Moment | null) {
		if (newValue) {
			props.setStartDate(newValue?.format("YYYY-MM-DD"))
		}
	}

	return (
		<Stack
			direction="row"
			justifyContent="flex-start"
			alignItems="center"
			spacing={1}
		>
			<Box sx={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				minWidth: 130

			}}>

				<Typography>Backtest Start:</Typography>


			</Box>
			<LocalizationProvider dateAdapter={AdapterMoment}>
				<DesktopDatePicker
					inputFormat="YYYY-MM-DD"
					minDate={moment("2019-01-01")}
					maxDate={moment()}
					disableFuture={true}
					value={props.startDate}
					onChange={handleChange}
					renderInput={(params) => <TextField size="small" {...params} />}
				/>
			</LocalizationProvider>


		</Stack >
	)
}


function TcostSelect() {

	return (
		<Stack
			direction="row"
			justifyContent="flex-start"
			alignItems="center"
			spacing={2}
		>
			<Box sx={{
				mr: 3, display: "flex",
				flexDirection: "row",
				alignItems: "center",
			}}>

				<Typography sx={{ minWidth: 100 }}>Transaction Costs:</Typography>

			</Box>
			< Select
				value="0"
				label="DDDD"
				placeholder='DDD'
				disabled
				size="small"
			>
				<MenuItem value={"0"}>Coming Soon</MenuItem>
			</Select>
		</Stack >
	)
}



function StrategySettingsInputs(props: {
	strategy_id: number,

}) {

	const updateStrategy = useUpdateStrategy(props.strategy_id);
	const strategyQuery = useStrategyQuery(props.strategy_id);

	if (strategyQuery.isLoading || !strategyQuery.data) {
		return <Box sx={{ width: '100%' }}>
			<LinearProgress />
		</Box>
	}

	return (
		<>
			<Stack direction="row" justifyContent="space-between" sx={{ mb: 1 }}>
				<Typography mb={1} variant="subtitle1">Strategy Settings</Typography>

			</Stack>

			<Divider />

			<Stack direction="row" sx={{ mt: 2 }} justifyContent="space-between">

				<Stack direction="column" spacing={1.5} alignItems="flex-start" >

					<SettingsSelect
						title="Portfolio Type"
						options={PORTFOLIO_TYPE_OPTIONS}
						selectedSetting={strategyQuery.data?.portfolio_type}
						setSetting={ptype => {
							updateStrategy.mutate({ portfolio_type: ptype as PortfolioType });
						}} />

					<DateStartSelect
						startDate={strategyQuery.data?.backtest_start_date}
						setStartDate={dateStart => {
							updateStrategy.mutate({ backtest_start_date: dateStart });
						}}
					/>

					<SettingsSelect
						title="Normalization"
						options={NORM_OPTIONS}
						selectedSetting={strategyQuery.data?.norm_method}
						setSetting={normMethod => {
							updateStrategy.mutate({ norm_method: normMethod as StrategyNormType });
						}}
						helperText='Determines how each input of the strategy is normalzied before combining into a single factor.'
					/>


					<SettingsSelect
						title="Data Lag"
						options={DATA_LAG_OPTIONS}
						selectedSetting={strategyQuery.data?.data_lag.toString()}
						setSetting={dataLag => {
							updateStrategy.mutate({ data_lag: parseInt(dataLag) });
						}}
						helperText='The number of days to lag the data when aligning with daily returns .'
					/>


				</Stack>

				<Stack direction="column" spacing={1.5} alignItems="flex-start" >

					<TcostSelect />

					<SettingsSelect
						title="Position Sizing"
						options={POSITION_SIZING_OPTIONS}
						selectedSetting={strategyQuery.data?.position_sizing}
						setSetting={positionSizing => {
							updateStrategy.mutate({ position_sizing: positionSizing as StrategyPositionSizingType });
						}}
						helperText='The method used to size the positions after the factors are combined.'
					/>

					<SettingsSelect
						title="Num Quantiles"
						options={NUM_QUANTILES_OPTIONS}
						selectedSetting={strategyQuery.data?.num_quantiles.toString()}
						setSetting={numQ => {
							updateStrategy.mutate({ num_quantiles: parseInt(numQ) });
						}}
						helperText='The number of quantiles to use when ranking the factors. This is used to calculate the returns by quantile and the position sizes when using the quantile position sizing method.'
					/>

				</Stack>

			</Stack>

		</>

	)

}


export default function StrategySettingsDialog(props: {
	isOpen: boolean,
	handleClose: ((event: {}) => void)
	strategy_id: number,
}) {

	return (
		<Dialog open={props.isOpen} onClose={props.handleClose} fullWidth maxWidth='md'

			PaperProps={{
				sx: {
					backgroundImage: 'none'
				}
			}}
		>

			<DialogContent>
				<StrategySettingsInputs strategy_id={props.strategy_id} />
			</DialogContent>

			<DialogActions>
				<Button size="small" variant="outlined" onClick={(event) => { props.handleClose(event); }}>OK</Button>
			</DialogActions>

		</Dialog>
	);

}