import apiClient from "../services/apiClient";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { IAnnotation, IChartDataset, ICompany, ICellFormat, ICreateInput } from "../interfaces";
import {
	GridColumns,
} from '@mui/x-data-grid';

interface ITableData {
	[key: string]: string | number;
}

type TableDataResponse = { rows: ITableData[], columns: GridColumns<ITableData> };

export type DisplayType = "list" | "compact" | "table" | "compare";
export type PeriodLookbackType = "1m" | "3m" | "6m" | "ytd" | "1y" | "2y" | "3y";
export type CompareChartType = "line" | "stacked" | "mkt_share";

interface IViewParams {
	compare_chart_type?: CompareChartType;
}


export interface IView {
	id: number;
	name: string;
	display_type: DisplayType;
	period_lookback: PeriodLookbackType;
	params?: IViewParams;
}

export interface IUpdateView {
	name?: string;
	display_type?: DisplayType;
	period_lookback?: PeriodLookbackType;
	params?: IViewParams;
}


export interface IViewInput {
	id: number;
	user_input_id: number;
	view_id: number;
	axis: number;
	name: string;
	type: string;
	underlying_id: number;
	params?: object;
	source_name?: string;


}


export function useViewQuery(view_id: number) {

	async function getView() {
		const { data } = await apiClient.get(`/views/${view_id}`)
		return data
	}

	return useQuery<IView>(['views', view_id], () => getView(),
		{ enabled: view_id > 0, refetchOnWindowFocus: false, refetchOnMount: false }
	)
}



export function useViewUniverseQuery(view_id: number) {
	// get selected universe for this view_id (company ids or watchlists)
	async function getUniverseSelection() {
		const { data } = await apiClient.get(`/views/universe/${view_id}`)
		return data
	}

	return useQuery<ICompany[]>(['views_universe', view_id], () => getUniverseSelection(),
		{ refetchOnWindowFocus: false, refetchOnMount: false }
	)
}


export function useViewUniverseCompaniesQuery(view_id: number) {
	// get all underlying compaies, for this view_id universe
	async function getViewUniverseCompanies() {
		const { data } = await apiClient.get(`/views/universe/company/${view_id}`)
		return data
	}

	return useQuery<ICompany[]>(['views_universe_companies', view_id], () => getViewUniverseCompanies(),
		{ refetchOnWindowFocus: false, refetchOnMount: false })
}


export function useViewUniverseOptionsQuery() {
	async function getViewUniverseOptions() {
		const { data } = await apiClient.get(`/views/universe/options`)
		return data
	}

	return useQuery(['views_universe_options'], () => getViewUniverseOptions(),
		{ refetchOnWindowFocus: false, refetchOnMount: false }
	)
}


export function useViewInputsQuery(view_id: number) {

	async function getViewInputs() {
		const { data } = await apiClient.get(`/views/input/${view_id}`)
		return data
	}

	return useQuery<IViewInput[]>(['views_inputs', view_id], () => getViewInputs(),
		{ refetchOnWindowFocus: false, refetchOnMount: false })
}


export function useViewChartDataQuery(view_id: number, company_id: number, period_lookback: PeriodLookbackType) {

	async function getViewChartData() {
		const { data } = await apiClient.get(`/views/charts/${view_id}/${company_id}?period_lookback=${period_lookback}`)
		return data
	}

	// set to refetch when mount, only when stale (should not refetch, when just switching tabs view)
	return useQuery<{ datasets: IChartDataset[], annotations?: IAnnotation[] }>(["views_chart_data", view_id, company_id, period_lookback],
		() => getViewChartData(), { refetchOnWindowFocus: false, refetchOnMount: true, staleTime: Infinity })
}

export function useViewCompChartDataQuery(view_id: number, input_id: number,
	period_lookback: PeriodLookbackType, compare_chart_type: CompareChartType) {

	async function getViewChartData() {
		const { data } = await apiClient.get(`/views/charts-comp/${view_id}/${input_id}?period_lookback=${period_lookback}&compare_chart_type=${compare_chart_type}`)
		return data
	}

	// set to refetch when mount, only when stale (should not refetch, when just switching tabs view)
	return useQuery<{ datasets: IChartDataset[], annotations?: IAnnotation[] }>(["views_compare_chart_data", view_id, input_id, period_lookback, compare_chart_type],
		() => getViewChartData(), { refetchOnWindowFocus: false, refetchOnMount: true, staleTime: Infinity })
}


export function useViewTableDataQuery(view_id: number) {

	async function getViewTableData() {
		const { data } = await apiClient.get(`/views/table-data/${view_id}`)
		return data
	}

	// set to refetch when mount, only when stale (should not refetch, when just switching tabs view)
	return useQuery<TableDataResponse>(["views_table_data", view_id],
		() => getViewTableData(), { refetchOnWindowFocus: false, refetchOnMount: true, staleTime: Infinity })
}





export function useAddViewUniverse(view_id: number) {

	async function addViewUniverse(newUniverse: { id: number, group: string | undefined }) {
		const { data } = await apiClient.post(`/views/universe/${view_id}`, newUniverse);
		return data
	}

	const queryClient = useQueryClient()

	return useMutation((newUniverse: { id: number, group: string | undefined }) =>
		addViewUniverse(newUniverse),
		{

			onMutate: async newUniverse => {
				// Cancel any outgoing refetches (so they don't overwrite our optimistic update)
				await queryClient.cancelQueries(['views_universe', view_id])

				// Snapshot the previous value
				const previousUniverse = queryClient.getQueryData(['views_universe', view_id])

				// Optimistically update to the new value
				queryClient.setQueryData(['views_universe', view_id], (old: any) => [...old, newUniverse])

				// Return a context object with the snapshotted value
				return { previousUniverse }
			},
			onSuccess: () => {
				queryClient.invalidateQueries(['views_universe', view_id]);
				queryClient.invalidateQueries(['views_universe_companies', view_id]);
				queryClient.invalidateQueries(['views_table_data', view_id]);
				queryClient.invalidateQueries(['views_compare_chart_data', view_id]);

			},

		}
	)

}


export function useRemoveViewUniverse(view_id: number) {

	async function removeViewUniverse(deletedUniverse: { id: number, group: string | undefined }) {
		const { data } = await apiClient.delete(`/views/universe/${view_id}`, { data: deletedUniverse });
		return data
	}

	const queryClient = useQueryClient()

	return useMutation((deletedUniverse: { id: number, group: string | undefined }) =>
		removeViewUniverse(deletedUniverse),
		{

			onMutate: async deletedUniverse => {
				// Cancel any outgoing refetches (so they don't overwrite our optimistic update)
				await queryClient.cancelQueries(['views_universe', view_id])

				// Snapshot the previous value
				const previousUniverse = queryClient.getQueryData(['views_universe', view_id])

				// Optimistically update to the new value
				queryClient.setQueryData(['views_universe', view_id], (old: any) => {
					return old.filter((e: any) => !(e.id === deletedUniverse.id && e.group === deletedUniverse.group));
				})

				// Return a context object with the snapshotted value
				return { previousUniverse }
			},

			onSuccess: () => {
				queryClient.invalidateQueries(['views_universe', view_id]);
				queryClient.invalidateQueries(['views_universe_companies', view_id]);
				queryClient.invalidateQueries(['views_table_data', view_id]);
				queryClient.invalidateQueries(['views_compare_chart_data', view_id]);
			},

		}
	)

}




export function useAddViewInput(view_id: number) {

	async function addViewInput(addedInput: ICreateInput) {
		const { data } = await apiClient.post(`/views/input/${view_id}`, addedInput);
		return data
	}

	const queryClient = useQueryClient()

	return useMutation((addedInput: ICreateInput) =>
		addViewInput(addedInput),
		{

			onSuccess: () => {
				queryClient.invalidateQueries(['views_inputs', view_id]);
				queryClient.invalidateQueries(['views_chart_data', view_id]);
				queryClient.invalidateQueries(['views_table_data', view_id]);
			},

		}
	)

}


export function useRemoveViewInput(view_id: number) {

	async function removeViewInput(input_id: number) {
		const { data } = await apiClient.delete(`/views/input/${input_id}/${view_id}`);
		return data
	}

	const queryClient = useQueryClient()

	return useMutation((input_id: number) =>
		removeViewInput(input_id),
		{

			onMutate: async input_id => {
				// Cancel any outgoing refetches (so they don't overwrite our optimistic update)
				await queryClient.cancelQueries(['views_inputs', view_id])

				// Snapshot the previous value
				const previousDatasets = queryClient.getQueryData(['views_inputs', view_id])

				// Optimistically update to the new value
				queryClient.setQueryData(['views_inputs', view_id], (old: any) => {
					return old.filter((e: any) => (e.id !== input_id));
				})

				// Return a context object with the snapshotted value
				return { previousDatasets }
			},

			onSuccess: () => {
				queryClient.invalidateQueries(['views_inputs', view_id]);
				queryClient.invalidateQueries(['views_chart_data', view_id]);
				queryClient.invalidateQueries(['views_table_data', view_id]);
			},

		}
	)

}


export function useViewTableFormatOptionsQuery(view_id: number) {

	async function getViewTableFormatOptions() {
		const { data } = await apiClient.get(`/views/table-options/${view_id}`);
		return data
	}

	return useQuery<ICellFormat[]>(['views_table_options', view_id], () => getViewTableFormatOptions())
}


export function useAllViewsByCompanyQuery(company_id: number) {

	async function getAllViewsByCompany() {
		const { data } = await apiClient.get(`/views/company/${company_id}`);
		return data
	}

	return useQuery<IView[]>(['views_by_company', company_id], () => getAllViewsByCompany())
}





export function useUpdateView(view_id: number) {

	async function updateView(updates: IUpdateView) {
		// only update if one of the fields is set
		if (!updates.name && !updates.display_type && !updates.params && !updates.period_lookback) {
			return
		}
		const { data } = await apiClient.put(`/views/${view_id}`, updates);
		return data
	}

	const queryClient = useQueryClient()

	return useMutation((updates: IUpdateView) =>
		updateView(updates),
		{

			onSuccess: () => {
				queryClient.invalidateQueries(['views']);
			},

			onMutate: async updates => {
				// Cancel any outgoing refetches (so they don't overwrite our optimistic update)
				await queryClient.cancelQueries(['views', view_id])

				// Snapshot the previous value
				const previousDatasets = queryClient.getQueryData(['views', view_id])

				// Optimistically update to the new value
				queryClient.setQueryData(['views', view_id], (old: any) => {
					return { ...old, ...updates }
				})

				// Return a context object with the snapshotted value
				return { previousDatasets }
			},
		}

	)

}

export function useRemoveView() {

	async function removeView(view_id: number) {
		const { data } = await apiClient.delete(`/views/${view_id}`);
		return data
	}

	const queryClient = useQueryClient()

	return useMutation((view_id: number) =>
		removeView(view_id),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['views']);
			},

		}
	)

}