import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';
import apiClient from "../services/apiClient";


export default function AdminPage() {

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [responseMsg, setResponseMsg] = useState<string>("");

	function handleDemoDataReset() {

		setIsLoading(true);
		apiClient.post("/admin/reset-demo-data")
			.then((result) => {
				console.log(result)
				if (result.status == 200) {
					setResponseMsg("Data Cleared Successfully");
				} else {
					setResponseMsg(result?.data?.message);
				}

			}).catch((err) => {
				setResponseMsg(err?.response?.data?.detail || "There was an issue, please try again.");
			}).finally(() => {
				setIsLoading(false);
			})
	}

	return (
		<>
			<Box padding={2} margin={2}>

				{!isLoading ? <Button onClick={handleDemoDataReset} size="large" variant='contained'>RESET DEMO DATA</Button>

					:
					<Box sx={{ width: '100%' }}>
						<CircularProgress />
					</Box>
				}

			</Box>


			<Snackbar open={responseMsg !== ""} autoHideDuration={2500}
				onClose={() => setResponseMsg("")}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			>
				<MuiAlert elevation={6} variant="filled" onClose={() => setResponseMsg("")}
					severity="info" sx={{ width: '100%' }}>
					{responseMsg}
				</MuiAlert>
			</Snackbar>


		</>
	);
}