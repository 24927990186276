import EventIcon from '@mui/icons-material/Event';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import SouthWestIcon from '@mui/icons-material/SouthWest';
import Autocomplete from '@mui/material/Autocomplete';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { blue, cyan, deepPurple, green, grey, orange, red, teal } from '@mui/material/colors';
import Grid, { GridProps } from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { alpha, Box, useTheme } from '@mui/system';
import moment, { Moment } from 'moment';
import React from 'react';
import { useModelEarningsEstimate } from "../../api/models"
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import LinearScaleIcon from '@mui/icons-material/LinearScale';
import LinearProgress from '@mui/material/LinearProgress';


function formatEst(est: number, divMillion: boolean = false) {
	if (divMillion) {
		est = (est / 1000000)
	}
	return est.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function ModelUpcomingEarningsCards(props: { metric_id: number, field_id: number, model_family_id: number }) {

	const theme = useTheme();
	const modelEarningsEstimate = useModelEarningsEstimate(props.metric_id, props.field_id, props.model_family_id);

	if (props.metric_id === 0 || modelEarningsEstimate.isLoading || modelEarningsEstimate.isFetching) {

		return (
			<Box display="flex" flexDirection="row" sx={{
				minHeight: 100, mt: 2, mr: 2
			}}>
				<Card sx={{
					backgroundColor: (theme) => theme.palette.card.background,
					border: "1px solid",
					borderColor: theme.palette.divider,
					borderRadius: "2px",
					minWidth: 350
				}}>
					<Box sx={{
						width: "100%",
						padding: 1
					}}>
						< LinearProgress />
					</Box>
				</Card >
			</Box >
		)

	}

	if (!modelEarningsEstimate.data || modelEarningsEstimate.data.length === 0) {

		return (
			<></>
		)
	}

	// get the minimum estimate across all earnings cards
	let minEst = Math.min(...modelEarningsEstimate.data.map((est) => est.estimate));
	const mm_threshold = 5000000;

	return (
		<Box display="flex" flexDirection="row" sx={{
			height: 100, mt: 2, // note this computes to some margin ?? Don't remove.
		}}>

			{modelEarningsEstimate.data.map((est, idx) => {

				return (
					<Card key={idx} sx={{
						backgroundColor: (theme) => theme.palette.card.background,
						border: "1px solid",
						borderColor: theme.palette.divider,
						borderRadius: "2px",
						marginRight: 2,
					}}>
						<CardContent>

							<Stack direction="row" alignItems="center">

								<Box >


									<Stack direction="row" alignItems="center" sx={{ mb: 1 }}>

										<Typography variant="h5" sx={{ flexGrow: 1 }}>
											{est.period}
										</Typography>

									</Stack>

									<Stack direction="row" alignItems="center">
										<Typography sx={{ mr: 1, fontWeight: 400 }} variant="h6" color="text.secondary">
											Estimate:
										</Typography>
										<Typography variant="h6" color="text.primary">
											{formatEst(est.estimate, minEst > mm_threshold)}
										</Typography>
										{est.est_change !== null &&
											<Typography variant="h6"
												sx={{
													ml: 1.5, color: (theme) => (est.est_change || 0) > 0 ?
														theme.palette.success.light :
														(est.est_change || 0) < 0 ?
															theme.palette.error.light :
															theme.palette.text.disabled
												}}
											>
												{est.est_change > 0 && <><TrendingUpIcon fontSize="small" /> {est.est_change.toFixed(1)}%</>}
												{est.est_change < 0 && <><TrendingDownIcon fontSize="small" /> {est.est_change.toFixed(1)}%</>}
												{est.est_change === 0 && <><LinearScaleIcon fontSize="small" /> {est.est_change.toFixed(1)}%</>}
											</Typography>
										}

									</Stack>

								</Box>
								{est.est_surprise !== null &&
									<Box sx={{ borderLeft: (theme) => "1px solid " + theme.palette.text.disabled, ml: 2, pl: 4 }}>

										<Typography variant="h4"
											sx={{ color: (theme) => (est.est_surprise !== null && est.est_surprise < 0) ? theme.palette.error.light : theme.palette.success.light }}
										>
											{est.est_surprise.toFixed(2)}%
										</Typography>
										<Typography variant="subtitle2" color="text.secondary">
											Surprise % Est.
										</Typography>
									</Box>
								}

							</Stack>

						</CardContent>

					</Card>
				)
			})}

			{/* <Card sx={{
				backgroundColor: (theme) => theme.palette.card.background,
				border: "1px solid",
				borderColor: theme.palette.divider,
				borderRadius: "2px",
				ml: 2,
				pt: 1,

			}}>
				<CardContent>

					<Stack direction="row" alignItems="center">

						<Box sx={{ borderRight: (theme) => "1px solid " + theme.palette.text.disabled, mr: 2, pr: 1 }}>

							<Typography variant="h4" sx={{ color: (theme) => theme.palette.text.primary }}>
								{est.mae.toFixed(1)}%
							</Typography>

							<Typography variant="subtitle2" color="text.secondary">
								Mean Abs. Error
							</Typography>
						</Box>

						<Box>
							<Typography variant="h4" sx={{ color: (theme) => theme.palette.text.primary }}>
								{est.hit_rate}%
							</Typography>

							<Typography variant="subtitle2" color="text.secondary">
								Beat / Miss Hit Rate
							</Typography>
						</Box>

					</Stack>



				</CardContent>

			</Card> */}

		</Box>



	)
}
