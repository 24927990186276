import * as React from 'react';
import moment, { Moment } from 'moment';
import { blue } from '@mui/material/colors';
import { alpha } from '@mui/system';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import { useEarningsDetailsQuery, IEarningsInfo } from "../../api/dashboard"
import { useWatchlistCompaniesWithCoverageQuery, ICompanyWithCoverage } from "../../api/watchlist";
import CompanyCoverageControls from "../CompanyCoverageControls/CompanyCoverageControls"

function EarningsCard(props: { earningsDetails: IEarningsInfo, companyCoverage?: ICompanyWithCoverage }) {

	return (
		<Box
			sx={{
				backgroundColor: (theme) => alpha(theme.palette.background.default, 0.50),
				paddingTop: 1, paddingBottom: 1, paddingLeft: 1, paddingRight: 1,
				marginBottom: 0.5,
				borderRadius: 0.5, border: "1px solid", borderColor: (theme) => alpha(theme.palette.action.disabledBackground, 0.2),

			}}>

			<Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={2} >

				<Avatar variant="rounded" sx={{ bgcolor: blue[300], width: 75, height: 24, fontSize: 14, fontWeight: 700, whiteSpace: "nowrap" }}>{props.earningsDetails.symbol}</Avatar>


				<Typography sx={{ fontSize: 13 }} color="text.primary" align="left" display="inline">
					{moment(props.earningsDetails.date).format('MMM D')}
				</Typography>

				{/* <Typography sx={{ fontSize: 11 }} color="text.secondary" align="left" display="inline">
					{props.earningsDetails.time}
				</Typography> */}

				{props.companyCoverage && <CompanyCoverageControls companyCoverage={props.companyCoverage} />}

			</Stack>
		</Box >
	);
}



export default function EarningsCalendarCards(props: { watchlist_id: number, selectedDate: Moment }) {

	const earningsDetailsQuery = useEarningsDetailsQuery(props.watchlist_id, props.selectedDate);
	// get tickers in watchlist with coverage (has_views, has_models, has_trends)
	const watchlistCompaniesWithCoverageQuery = useWatchlistCompaniesWithCoverageQuery();

	return (
		<Box>

			<Box
				sx={{
					maxHeight: "calc(50vh - 190px)",
					overflowY: "auto",
					// marginTop: 0.5,
					// paddingBottom: 0.5,
					listStyle: "none",
					'&::-webkit-scrollbar': {
						width: '0.65em',
					},
					'&::-webkit-scrollbar-track': {
						boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
						webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
					},
					'&::-webkit-scrollbar-thumb': {
						backgroundColor: 'rgba(0,0,0,.1)',
						outline: '1px solid slategrey'
					}


				}} >

				{earningsDetailsQuery.isLoading &&
					<Box sx={{ width: '100%' }}>
						<LinearProgress />
					</Box>
				}

				{(earningsDetailsQuery.data && earningsDetailsQuery.data.length === 0) &&
					<Box sx={{ width: '100%' }}>
						<Typography sx={{ fontSize: 12 }} color="text.secondary" align="center">
							No earnings for {props.selectedDate.format("MMM D")}.
						</Typography>
					</Box>
				}

				{
					earningsDetailsQuery.data?.map((item, index) => {
						return (
							<EarningsCard
								key={index}
								earningsDetails={item}
								companyCoverage={watchlistCompaniesWithCoverageQuery.data?.find((company) => company.company_id === item.company_id)}
							/>

						)
					}
					)}

			</Box>


		</Box>



	);

}
