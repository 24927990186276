import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { Box } from '@mui/system';
import React from 'react';
import { green, red, grey, blue, teal, orange } from '@mui/material/colors';
import {
	DataChart, StrategyCard
} from "../";
import { Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { getIndexColor } from "../Charts/utils"
import { useStrategyPerformanceMetricsQuery } from "../../api/strategy"
import { getPeriodLookbackChartParam } from "../../components/Charts/chartSelectParams";
import StrategyAlphaCrossCorrelation from "./StrategyAlphaCrossCorrelation"


function PerformanceMetric(props: {
	name: string,
	value: number,
	isPercent: boolean,
	greenRedColor: boolean
}) {

	let color = "inherit";

	if (props.greenRedColor) {
		if (props.value > 0) {
			color = "success.light";
		} else if (props.value < 0) {
			color = "error.light";
		}
	}

	return (
		<>
			<Box sx={{ borderColor: (theme) => theme.palette.text.secondary, borderBottom: "0px solid", pb: 0, mb: 0.5 }}>
				<Typography color="text.secondary">{props.name}:</Typography>
			</Box>
			<Divider />
			<Typography sx={{ mb: 1 }} fontWeight={500} color={color}>{props.value} {props.isPercent ? "%" : ""}</Typography>
		</>
	)
}

function PerformanceMetrics(props: {
	strategy_id: number
}) {

	const strategyPerformanceMetricsQuery = useStrategyPerformanceMetricsQuery(props.strategy_id);

	if (strategyPerformanceMetricsQuery.isLoading || strategyPerformanceMetricsQuery.isFetching) {
		return (
			<Box sx={{ width: '100%' }}>
				<LinearProgress />
			</Box>
		);
	}

	return (

		<Box sx={{ padding: 3 }}>
			{(strategyPerformanceMetricsQuery.data) &&
				<Stack direction="row">
					<Box flexGrow={1}>
						<PerformanceMetric name="Annualized Return" value={strategyPerformanceMetricsQuery.data.ann_return} isPercent={true} greenRedColor={true} />
						<PerformanceMetric name="Annualized Vol." value={strategyPerformanceMetricsQuery.data.ann_vol} isPercent={true} greenRedColor={false} />
						<PerformanceMetric name="Sharpe Ratio" value={strategyPerformanceMetricsQuery.data.sharpe_ratio} isPercent={false} greenRedColor={true} />
					</Box>
					<Box flexGrow={1}>
						<PerformanceMetric name="Daily Turnover" value={strategyPerformanceMetricsQuery.data.turnover} isPercent={true} greenRedColor={false} />
						<PerformanceMetric name="Information Coefficient" value={strategyPerformanceMetricsQuery.data.inf_coeff} isPercent={false} greenRedColor={true} />
						<PerformanceMetric name="Max Drawdown" value={strategyPerformanceMetricsQuery.data.max_drawdown} isPercent={true} greenRedColor={true} />
					</Box>
				</Stack>
			}

		</Box>

	);
}




export default function StrategyPerformanceCharts(props: {
	strategy_id: number,
}) {

	const cardHeight = "calc(50vh - 230px)";

	return (

		<Box flexGrow={1}>

			< Grid container
				sx={{
					mt: 0, // note this computes to some margin ?? Don't remove.
				}}
				// columnGap={2}
				// rowGap={2}
				// gap={2}
				spacing={2}
			// alignItems="stretch"
			// justifyContent="flex-start"
			// alignItems="center"
			// justifyContent="space-between"
			// alignItems="center"
			>

				<Grid item xs={5}>
					<StrategyCard sx={{ height: cardHeight }}>
						<DataChart
							title="Daily Cumulative Returns"
							queryKey={['strategy_performance', props.strategy_id, 'cum_return_charts']}
							xAxisDate={true}
							chartType='line'
							dataUrl={`/strategy/charts/cumulative-returns-chart-data/${props.strategy_id}`}
							getDatasetChartType="line"
							getYAxisId="y"
							getChartColor={(label, index) => label === 'Total Return' ? blue[300] : orange[300]}
							disableAnimations={false}
							chartSelectParams={[

								{
									name: "period_lookback",
									title: "Lookback Period",
									defaultValue: "ITD",
									options: [
										{ label: "3m", value: "3m" },
										{ label: "6m", value: "6m" },
										{ label: "YTD", value: "YTD" },
										{ label: "1y", value: "1y" },
										{ label: "2y", value: "2y" },
										{ label: "ITD", value: "ITD" },
									]
								}
							]}
						/>

					</StrategyCard>
				</Grid>


				<Grid item xs={3}>
					<StrategyCard sx={{ height: cardHeight }}>

						<Typography>Performance Metrics</Typography>
						<PerformanceMetrics strategy_id={props.strategy_id} />
					</StrategyCard>
				</Grid>

				<Grid item xs={4}>
					<StrategyCard sx={{ height: cardHeight }}>
						<Typography>Alpha Cross Correlation</Typography>
						<StrategyAlphaCrossCorrelation strategy_id={props.strategy_id} />
					</StrategyCard>
				</Grid>

				<Grid item xs={3}>
					<StrategyCard sx={{ height: cardHeight }}>
						<DataChart
							title="Returns by Ticker"
							displayLegend={false}
							chartType="bar"
							dataUrl={`/strategy/charts/returns-by-ticker-chart-data/${props.strategy_id}`}
							queryKey={['strategy_performance', props.strategy_id, 'ticker_return_charts']}
							getDatasetChartType="bar"
							getYAxisId="y"
							getChartColor={getIndexColor}
							getDataColor={(value) => value > 0 ? green[400] : value < 0 ? red[400] : grey[400]}
							disableAnimations={false}
							chartSelectParams={[

								{
									name: "ticker_limit",
									title: "Tickers",
									defaultValue: "20",
									options: [
										{ label: "Top 10", value: "10" },
										{ label: "Top 20", value: "20" },
										{ label: "Top 30", value: "30" },
									]
								}
							]}


						/>

					</StrategyCard>
				</Grid>

				<Grid item xs={3}>
					<StrategyCard sx={{ height: cardHeight }}>
						<DataChart
							title="Returns by Sector"
							displayLegend={false}
							chartType="bar"
							dataUrl={`/strategy/charts/returns-by-sector-chart-data/${props.strategy_id}`}
							queryKey={['strategy_performance', props.strategy_id, 'sector_return_charts']}
							getDatasetChartType="bar"
							getYAxisId="y"
							getChartColor={getIndexColor}
							getDataColor={(value) => value > 0 ? green[400] : value < 0 ? red[400] : grey[400]}
							disableAnimations={false}
						/>

					</StrategyCard>
				</Grid>

				<Grid item xs={3}>
					<StrategyCard sx={{ height: cardHeight }}>

						<DataChart
							title="Mean Daily Return by Factor Quantile"
							displayLegend={false}
							chartType="bar"
							dataUrl={`/strategy/charts/return-by-quantile-chart-data/${props.strategy_id}`}
							queryKey={['strategy_performance', props.strategy_id, 'quantile_return_charts']}
							getDatasetChartType="bar"
							getYAxisId="y"
							getChartColor={() => blue[400]}
							disableAnimations={false}
						/>


					</StrategyCard>
				</Grid>


				<Grid item xs={3}>
					<StrategyCard sx={{ height: cardHeight }}>

						<DataChart
							title="Monthly Returns"
							displayLegend={false}
							chartType="bar"
							dataUrl={`/strategy/charts/return-by-month-chart-data/${props.strategy_id}`}
							queryKey={['strategy_performance', props.strategy_id, 'monthly_return_charts']}
							getDatasetChartType="bar"
							getYAxisId="y"
							getChartColor={getIndexColor}
							getDataColor={(value) => value > 0 ? green[400] : value < 0 ? red[400] : grey[400]}
							disableAnimations={false}
						/>

					</StrategyCard>
				</Grid>


			</Grid >
		</Box>



	)


}