import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import ListSubheader from '@mui/material/ListSubheader';
import Popper from '@mui/material/Popper';
import Stack from '@mui/material/Stack';
import { styled, useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useState } from 'react';
import { ListChildComponentProps, VariableSizeList } from 'react-window';
import { ICompany } from "../../interfaces";

const LISTBOX_PADDING = 8; // px

const filterOptions = (options: ICompany[], { inputValue }: { inputValue: string }) => {
    const filteredOptions = options.filter((option) => {
        const symbol = option.symbol.toLowerCase();
        const name = option.name ? option.name.toLowerCase() : "";
        const input = inputValue.toLowerCase();
        return symbol.startsWith(input) || name.startsWith(input);
    });

    return filteredOptions.sort((a, b) => {
        const aSymbolMatch = a.symbol.toLowerCase().startsWith(inputValue.toLowerCase());
        const bSymbolMatch = b.symbol.toLowerCase().startsWith(inputValue.toLowerCase());

        if (aSymbolMatch && !bSymbolMatch) {
            return -1;
        }
        if (!aSymbolMatch && bSymbolMatch) {
            return 1;
        }
        return 0;
    });

}


function renderRow(props: ListChildComponentProps) {
    const { data, index, style } = props;
    const dataSet = data[index];
    const inlineStyle = {
        ...style,
        top: (style.top as number) + LISTBOX_PADDING,
    };

    if (dataSet.hasOwnProperty('group')) {
        return (
            <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
                {dataSet.group}
            </ListSubheader>
        );
    }
    // console.log(dataSet);
    return (
        <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
            {dataSet[1]}
        </Typography>
    );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
    const ref = React.useRef<VariableSizeList>(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData: ICompany[] = [];
    (children as ICompany[]).forEach(
        (item: ICompany & { children?: ICompany[] }) => {
            itemData.push(item);
            itemData.push(...(item.children || []));
        },
    );

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
        noSsr: true,
    });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child: ICompany) => {
        if (child.hasOwnProperty('group')) {
            return 48;
        }

        return itemSize;
    };

    const getHeight = () => {
        if (itemCount > 12) {
            return 12 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index: number) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});


const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: 'border-box',
        '& ul': {
            padding: 0,
            margin: 0,
        },
    },
});



export default function TickerSelect(props: {
    title: string,
    tickerOptions: ICompany[],
    tickersSelected: ICompany[],
    handleTickerSelect: (event: React.SyntheticEvent, newValue: ICompany[]) => void,
    loading: boolean,
    limitTags?: number,
    disableChipDelete?: boolean,
    placeholder?: string,
    minOpenLength?: number
}) {

    const placeholder = props.placeholder || `Select ${props.title.toLowerCase()}`;
    const limitTags = props.limitTags || 15; // default to 15
    const disableChipDelete = props.disableChipDelete === undefined ? true : props.disableChipDelete; // default to true

    const [inputValue, setInputValue] = useState<string>("");

    return (


        <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            sx={{ ml: 2, mb: 2 }}
        >
            <Typography variant="subtitle1" sx={{ color: 'text.primary', fontWeight: 500 }}>
                {props.title}:
            </Typography>



            <Autocomplete
                inputValue={inputValue}
                filterOptions={filterOptions}
                onInputChange={(event, value) => setInputValue(value || "")}
                open={props.minOpenLength === undefined ? undefined : inputValue.length >= props.minOpenLength}
                loading={props.loading}
                disableListWrap
                PopperComponent={StyledPopper}
                ListboxComponent={ListboxComponent}
                multiple
                limitTags={limitTags}
                popupIcon={""}
                disableCloseOnSelect
                disableClearable
                id="ticker-search"
                filterSelectedOptions
                options={props.tickerOptions}
                getOptionLabel={(option) => option.symbol}
                value={props.tickersSelected}
                onChange={(event: React.SyntheticEvent, value: ICompany[]) => props.handleTickerSelect(event, value)}
                isOptionEqualToValue={(option, value) => value.id === option.id && value.group === option.group}
                fullWidth
                ChipProps={disableChipDelete ? { deleteIcon: <></> } : {}}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder={`  ${placeholder}`}
                        variant="outlined"
                        size="small"
                    />)}

                renderOption={(props, option) => [props,
                    <Stack spacing={2} alignItems="center" direction="row">
                        <Typography>{option.symbol}</Typography>
                        <Typography variant="body2" color={option.group === "watchlist" ? "primary" : "text.disabled"}>{option.name}</Typography>
                    </Stack>
                ] as React.ReactNode}
            />


        </Stack>


    );
}
