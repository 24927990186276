import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import clsx from 'clsx'

export type FileUploadProps = {
	imageButton?: boolean
	accept: string
	hoverLabel?: string
	dropLabel?: string
	width?: string
	height?: string
	image?: {
		url: string
		imageStyle?: {
			width?: string
			height?: string
		}
	}
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
	onDrop: (event: React.DragEvent<HTMLElement>) => void
}


export const FileUpload: React.FC<FileUploadProps> = ({
	accept,
	hoverLabel = 'Click or drag to upload file',
	dropLabel = 'Drop file here',
	width = '500px',
	height = '100px',
	onChange,
	onDrop,
}) => {

	const [labelText, setLabelText] = React.useState<string>(hoverLabel)
	const [isDragOver, setIsDragOver] = React.useState<boolean>(false)
	const [isMouseOver, setIsMouseOver] = React.useState<boolean>(false)
	const stopDefaults = (e: React.DragEvent) => {
		e.stopPropagation()
		e.preventDefault()
	}
	const dragEvents = {
		onMouseEnter: () => {
			setIsMouseOver(true)
		},
		onMouseLeave: () => {
			setIsMouseOver(false)
		},
		onDragEnter: (e: React.DragEvent) => {
			stopDefaults(e)
			setIsDragOver(true)
			setLabelText(dropLabel)
		},
		onDragLeave: (e: React.DragEvent) => {
			stopDefaults(e)
			setIsDragOver(false)
			setLabelText(hoverLabel)
		},
		onDragOver: stopDefaults,
		onDrop: (e: React.DragEvent<HTMLElement>) => {
			stopDefaults(e)
			setLabelText(hoverLabel)
			setIsDragOver(false)
			onDrop(e)
		},
	}

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		onChange(event)
	}

	return (
		<Box sx={{

			'& .file-upload-label': {

				cursor: 'pointer',
				textAlign: 'center',
				display: 'flex',
				'&:hover p,&:hover svg,& img': {
					opacity: 1,
				},
				'& p, svg': {
					opacity: 0.4,
				},
				'&:hover img': {
					opacity: 0.3,
				},

				'&.onDragOver': {
					'& img': {
						opacity: 0.3,
					},
					'& p, svg': {
						opacity: 1,
					},
				}
			}
		}}
		>

			<input
				onChange={handleChange}
				accept={accept}
				style={{ display: "none" }}
				id="file-upload"
				type="file"
			/>

			<label
				htmlFor="file-upload"
				className={clsx("file-upload-label", isDragOver && "onDragOver")}
				{...dragEvents}
			>
				<Box
					width={width}
					height={height}
					bgcolor={(theme) => theme.palette.card.background}
					border="1px solid"
					borderRadius={2}
					sx={{
						pointerEvents: 'none', borderColor: (theme) => theme.palette.action.disabledBackground
					}}
				>


					<Box
						height={height}
						width={width}
						sx={{

							display: 'flex',
							justifyContent: 'center',
							flexDirection: 'column',
							alignItems: 'center',
							position: 'absolute',

						}}
					>
						<CloudUploadIcon fontSize="large" />
						<Typography>{labelText}</Typography>
					</Box>

				</Box>
			</label>
		</Box>
	)
}
