import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ViewChart, ViewTickerChartSelect } from "../../components"
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { useViewUniverseCompaniesQuery, useViewQuery } from '../../api/views';
import { getIndexColor } from "../../components/Charts/utils"


export default function ViewTickerChart(props: {
	view_id: number,
	title?: string,
	ToolbarComponent?: JSX.Element,
	compact?: boolean,
}) {

	const [searchParams, setSearchParams] = useSearchParams();
	const viewUniverseCompanies = useViewUniverseCompaniesQuery(props.view_id);
	const viewQuery = useViewQuery(props.view_id);

	const companyIdTabSelect: number = parseInt(searchParams.get("company") || "0");  // default to list view
	const [tabValue, setTabValue] = useState<number>(companyIdTabSelect);

	const compact = props.compact === undefined ? false : props.compact;

	// once we get the data, update the selected ticker tab if no selection is set (e.g. first time on page)
	useEffect(() => {
		const companiesData = viewUniverseCompanies.data;

		if (companiesData && companiesData.length > 0) {

			const tickerExists = companiesData.some((el: any) => el.id === tabValue);

			if (tabValue === 0 || !tickerExists) {
				setTabValue(companiesData[0].id);

				searchParams.set("company", String(companiesData[0].id));
				setSearchParams(searchParams);

			}

		}
	}, [viewUniverseCompanies.data])

	if (tabValue === 0 || viewQuery.isLoading) {
		return <></>;
	}

	return (
		<Box sx={{

			height: "95%",
			width: '100%',

		}}>
			<Stack direction="row" justifyContent="space-between"
				alignItems="center">
				{props.title && <Typography>{props.title}</Typography>}

				<Stack direction="row" spacing={1}>
					{(viewUniverseCompanies.data && viewUniverseCompanies.data.length > 0) &&
						<ViewTickerChartSelect tabValue={tabValue}
							setTabValue={setTabValue}
							selectedCompanies={viewUniverseCompanies.data}
							fontSize={compact ? "small" : "large"}
						/>
					}

					{props.ToolbarComponent && props.ToolbarComponent}
				</Stack>

			</Stack>

			<ViewChart
				chartType='line'
				periodLookback={viewQuery.data?.period_lookback || "1y"}
				view_id={props.view_id}
				company_id={tabValue}
				getDatasetChartType="line"
				getChartColor={getIndexColor}
				displayLegend={true}
				disableAnimations={true}
			/>
		</Box >

	)

}
