import * as React from 'react';
import moment, { Moment } from 'moment';
import { blue, grey, teal, cyan, deepPurple, red, green, orange, yellow } from '@mui/material/colors';
import TextField from '@mui/material/TextField';
import Badge from '@mui/material/Badge';
import { useTheme, alpha } from '@mui/system';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { EarningsCalendarCards } from '../../components';
import { useEarningsCountQuery } from "../../api/dashboard"
import LinearProgress from '@mui/material/LinearProgress';


export default function EarningsCalendar(props: {
	watchlist_id: number,
	ToolbarComponent?: JSX.Element,
}) {

	const [selectedDate, setSelectedDate] = React.useState<Moment | null>(null); // selected date from calendar
	const [monthSelect, setMonthSelect] = React.useState<Moment>(moment()); // selected date from calendar
	const theme = useTheme();

	// default to today's year/month of data. and fetch new data when user changes month
	const earningsCountQuery = useEarningsCountQuery(props.watchlist_id, monthSelect.year(), monthSelect.month() + 1); // moment month starts at 0

	function handleMonthChange(month: Moment) {
		setMonthSelect(month);
	};

	const numEarningsOnSelectedDate = (earningsCountQuery.data && selectedDate !== null) && earningsCountQuery.data[selectedDate.format('YYYY-MM-DD')] || 0;

	return (

		<div>
			<Stack direction="row" justifyContent="space-between" alignItems="center" >
				<Typography>Earnings Calendar</Typography>


				{
					(selectedDate !== null && numEarningsOnSelectedDate === 0) &&
					<Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>No earnings on {selectedDate.format('dddd, MMMM Do')}</Typography>
				}


				{props.ToolbarComponent && props.ToolbarComponent}

			</Stack>


			{(selectedDate === null || numEarningsOnSelectedDate === 0) &&

				<Box sx={{
					marginBottom: 2,

					'& .MuiPickerStaticWrapper-content': {
						// bgcolor: theme.palette.card.background,
						bgcolor: alpha(theme.palette.background.default, 0.30),
						border: "2px solid",
						borderColor: theme.palette.divider,
						borderRadius: 1,
						maxHeight: "calc(50vh - 150px)",
						overflowY: "auto",
					},
					'& .MuiPickersDay-root': {
						borderRadius: 1,
						border: "1px solid",
						borderColor: theme.palette.divider,
						width: 42,
						fontSize: 13,
						'&.MuiPickersDay-today': {
							'&:not(.Mui-selected)': {
								bgcolor: theme.palette.action.disabledBackground,
								color: theme.palette.text.primary,
								borderColor: theme.palette.divider,
							}
						},

					},
				}}>

					{earningsCountQuery.isLoading ?
						<Box sx={{ width: '100%' }}>
							<LinearProgress />
						</Box>
						:

						<LocalizationProvider dateAdapter={AdapterMoment}>
							<StaticDatePicker

								displayStaticWrapperAs="desktop"
								// orientation="landscape"
								showDaysOutsideCurrentMonth={false}
								views={['day']}
								openTo="day"
								// disableOpenPicker={true}
								value={selectedDate}
								onChange={(newValue) => {
									if (newValue) {
										setSelectedDate(newValue);
									}

								}}

								renderInput={(params) => <TextField {...params} />}
								onMonthChange={handleMonthChange}
								defaultCalendarMonth={monthSelect}
								renderDay={(day, _value, DayComponentProps) => {

									const numEarnings = (!DayComponentProps.outsideCurrentMonth && earningsCountQuery.data) ? earningsCountQuery.data[day.format('YYYY-MM-DD')] : 0;

									if (numEarnings > 0) {

										return (
											<Badge
												key={day.toString()}
												overlap="circular"
												badgeContent={numEarnings}
												color="warning"
											>
												<PickersDay {...DayComponentProps} />
											</Badge>
										);

									}

									return <PickersDay {...DayComponentProps} />;

								}}

							/>
						</LocalizationProvider>
					}
				</Box>

			}



			{
				(selectedDate !== null && numEarningsOnSelectedDate > 0) &&
				<>
					<Stack direction="row" justifyContent={"space-between"} alignItems={"center"}>
						<Button
							sx={{ mb: 1 }}
							// size="small"
							variant="text"
							onClick={() => {
								setSelectedDate(null);
							}} >Back</Button>

						<Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>{selectedDate.format('MMMM Do')}</Typography>

					</Stack>

					<EarningsCalendarCards watchlist_id={props.watchlist_id} selectedDate={selectedDate} />
				</>
			}


		</div>

	)
}