import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { addAccessTokenInterceptor } from './services/apiClient'


export default function AuthTokenLayer() {

	const { getAccessTokenSilently } = useAuth0();

	useEffect(() => {
		addAccessTokenInterceptor(getAccessTokenSilently);

	}, [getAccessTokenSilently]);

	return <div></div>;

}

