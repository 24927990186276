import { Box } from '@mui/system';
import React, { useState } from 'react';
import { green, red, grey, blue, teal, orange } from '@mui/material/colors';
import {
	DataTable, StrategyCard
} from "../";
import { Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';



function renderTableCell(params: GridRenderCellParams) {

	if (params.value === undefined || params.value === null) {
		return <></>;
	}

	switch (params.field) {
		case "alpha":
		case "position_perc":
		case "daily_return":
		case "portfolio_return":
			return (
				<Typography sx={{ color: params.value > 0 ? green[400] : params.value < 0 ? red[400] : 'text.secondary' }} variant="body2" >{params.value.toFixed(2) + " %"}
				</Typography>
			);

		default:
			return <div>{params.value}</div>;
	}

}

export default function StrategyPositionDetails(props: {
	strategy_id: number,
}) {

	return (

		<Box flexGrow={1}>
			<Box sx={{ mt: 2 }}>
				<StrategyCard sx={{ height: "calc(90vh - 330px)" }}>

					<DataTable
						title="Positions"
						dataUrl={`/strategy/position-table-data/${props.strategy_id}`}
						queryKey={['strategy_positions', props.strategy_id]}
						renderCell={renderTableCell}
						disableColumnMenu={true}
						filterable={true}
						hideFooter={false}
						showToolbar={true}
						chartSelectParams={[
							{
								name: "ticker_limit",
								title: "Tickers",
								defaultValue: "0",
								options: [
									{ label: "All Tickers", value: "0" },
									{ label: "Top 10", value: "10" },
									{ label: "Top 20", value: "20" },
								]
							},
							{
								name: "period_filter",
								title: "Period",
								defaultValue: "latest",
								options: [
									{ label: "Latest Date", value: "latest" },
									{ label: "3m", value: "3m" },
									{ label: "6m", value: "6m" },
									{ label: "YTD", value: "YTD" },
									{ label: "1y", value: "1y" },
									{ label: "2y", value: "2y" },
								]
							}

						]}
					/>

				</StrategyCard>
			</Box>
		</Box>



	)


}
