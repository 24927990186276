import apiClient from "../services/apiClient";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';



export interface IUserAlpha {
	id: number;
	name: string;
	factor_sign: number;
}


export interface IAlpha {
	id: number;
	name: string;
	title: string;
	description: string;
	group: string;
	allow_multi: boolean;
}


export interface ISubAlpha {
	sub_alpha_id: number;
	name: string;
	field_id: number;
}


export interface ISubAlphaOptions {
	alpha_id: number;
	selection_group: string;
	sub_alphas: ISubAlpha[];


}


export interface INewAlpha {
	name: string;
	factor_sign: number;
	sub_alpha_ids: number[];
}



export function useAlphasQuery() {

	async function getAlphas() {
		const { data } = await apiClient.get(`/alphas/`)
		return data
	}

	return useQuery<IAlpha[]>(['alphas'], getAlphas);
}

export function useSubAlphaOptionsQuery(alpha_id: number) {

	async function getSubAlphaOptions() {
		const { data } = await apiClient.get(`/alphas/sub/${alpha_id}`)
		return data
	}

	return useQuery<ISubAlphaOptions[]>(['alphas_sub_options', alpha_id], getSubAlphaOptions,
		{ enabled: alpha_id !== 0 }
	);
}
