import React from 'react';
import { Outlet } from 'react-router-dom';
import { AppHeader } from "../";


const layoutStyles: React.CSSProperties = {
    width: "100%",
    maxWidth: "100vw",
    // backgroundColor: Colors.DARK_GRAY3,
    flexGrow: 1

}
const AppHeaderWrapperStyles: React.CSSProperties = {
    flexGrow: 0
}

const mainStyles: React.CSSProperties = {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",

}


export default function AppLayout() {


    return (

        <main style={mainStyles}>

            <div style={AppHeaderWrapperStyles}>
                <AppHeader />
            </div>

            <div style={layoutStyles}>
                <Outlet />
            </div>
        </main>
    );
};
