import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
	useModelCompanyMetricsQuery, useModelMetricFieldMapQuery, useModelInputOptionsAllQuery,
	useModelFamilyQuery, ICompanyMetric, useAddPrimaryCompanyMetric, IModelInput
} from "../../api/models";
import { useSnackbar } from "../../components/Snackbar/useSnackbar";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import SettingsIcon from '@mui/icons-material/Settings';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import {
	ModelFeaturesAddDialogMetric
} from "../../components/Model/ModelFeaturesAddDialog";


function ModelSettingsButton(props: {
	companyMetric: ICompanyMetric, companySymbol: string,
	filteredModelFieldIds: number[],
	// setAlertMessage: (message: { message: string, severity?: "success" | "info" | "warning" | "error" }) => void
}) {

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const [modelDatasetDialogOpen, setModelDatasetDialogOpen] = React.useState(false);
	const addPrimaryCompanyMetric = useAddPrimaryCompanyMetric();
	const { setAlertMessage, AlertMessage } = useSnackbar();

	// limit name to 20 characters
	const shortMetricName = props.companyMetric.metric_name.length > 20 ? props.companyMetric.metric_name.substring(0, 20) + "..." : props.companyMetric.metric_name;
	const title = `${props.companySymbol}: ${shortMetricName}`;

	return (
		<>
			<Tooltip title="Forecast Settings">
				<IconButton
					sx={{ ml: 1 }}
					size='large'
					onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}
					aria-haspopup="true">
					<SettingsIcon color="disabled" />
				</IconButton>
			</Tooltip>

			<Menu
				open={open}
				onClose={() => setAnchorEl(null)}
				anchorEl={anchorEl}
			>
				<Box>
					<MenuItem onClick={() => {
						setModelDatasetDialogOpen(true);
						setAnchorEl(null);
					}}>
						Update Model Datasets
					</MenuItem>
					<Divider />
					<MenuItem
						disabled={props.companyMetric.is_primary || !props.companyMetric.is_parent}
						onClick={() => {
							addPrimaryCompanyMetric.mutate({ company_id: props.companyMetric.company_id, metric_id: props.companyMetric.metric_id },
								{
									onSuccess: () => {
										setAlertMessage({ message: `Primary metric updated - ${title}`, severity: "success" });
									}

								});
							setAnchorEl(null);
						}}>
						Set As Primary Metric
					</MenuItem>

				</Box>
			</Menu>

			<ModelFeaturesAddDialogMetric
				metric_id={props.companyMetric.metric_id}
				metricName={props.companyMetric.metric_name}
				companySymbol={props.companySymbol}
				// filter the model input fields to onlyrelevant ones for this metric_id
				filterFieldIds={props.filteredModelFieldIds}
				isOpen={modelDatasetDialogOpen}
				handleClose={() => setModelDatasetDialogOpen(false)} />

			{AlertMessage}

		</>

	)
}



export default function CompanyModelSelections(props: { company_id: number, company_symbol: string | undefined },) {

	const [searchParams, setSearchParams] = useSearchParams();

	const metric_id: number = parseInt(searchParams.get("metric") || "0");
	const model_input_id: number = parseInt(searchParams.get("model_input") || "0");
	const model_family_id: number = parseInt(searchParams.get("model_family") || "0");

	//queries
	const modelCompanyMetricsQuery = useModelCompanyMetricsQuery(props.company_id);
	const selectedMetric = modelCompanyMetricsQuery.data?.find((i) => i.metric_id === metric_id);
	const modelFamilyQuery = useModelFamilyQuery();

	const modelMetricFieldMapQuery = useModelMetricFieldMapQuery(props.company_id);
	const modelInputOptionsQuery = useModelInputOptionsAllQuery(); // fetches all inputs across mappings

	const combinedModel: IModelInput = { id: 0, name: 'ARB COMBINED', title: '', vendor_id: 0, vendor_name: '', is_selected: true, data_type: '' };

	// filter modelOptions, for the field_id, based on the selected metric_id (using modelMetricFieldMapQuery)
	const modelFieldIds = modelMetricFieldMapQuery.data?.filter((el) => el.metric_id === metric_id);
	const filteredModelOptions = modelInputOptionsQuery.data?.filter((el) => modelFieldIds?.some((field) => field.field_id === el.id));

	const modelOptions = filteredModelOptions ? [combinedModel].concat(filteredModelOptions) : [];

	// for metric vendor selection
	const metricVendors = Array.from(new Set(modelCompanyMetricsQuery.data?.map((metric) => metric.vendor_name) || [])).sort();
	const [metricVendor, setMetricVendor] = React.useState<string>("");

	// choose default metric vendor
	useEffect(() => {

		if (selectedMetric && selectedMetric.vendor_name) {
			// set to first
			if (selectedMetric.vendor_name !== metricVendor) {
				setMetricVendor(selectedMetric.vendor_name);

			}
		}

	}, [props.company_id, selectedMetric]);

	// choose default metric id
	useEffect(() => {

		const metricsData = modelCompanyMetricsQuery.data;
		if (metricsData && metricsData.length > 0) {
			const metricExists = metricsData.some((el: any) => el.metric_id === metric_id);
			if (metric_id === 0 || !metricExists) {
				// get primary metric, if available, otherwise use first metric
				const primaryMetric = metricsData.find((el: any) => el.is_primary);
				if (primaryMetric) {
					// if primary metric (user selected) exists, use it
					searchParams.set("metric", String(primaryMetric.metric_id));
				} else {
					// otherwise use first metric (sorted by hgihest correlation)
					searchParams.set("metric", String(metricsData[0].metric_id));
				}
				setSearchParams(searchParams);
			}

		}
	}, [props.company_id, metric_id, modelCompanyMetricsQuery.data]);


	// if the selected model input is not in the filteredModelOptions, change back to ensemble (0)
	useEffect(() => {

		if (model_input_id !== 0 && filteredModelOptions && filteredModelOptions.length > 0 && !modelInputOptionsQuery.isLoading) {

			const fieldExists = filteredModelOptions.some((el: any) => el.id === model_input_id);
			if (!fieldExists) {
				// for forecast page, show Arb Combined (model_input_id=0)
				searchParams.set("model_input", "0");
				setSearchParams(searchParams);
			}

		}
	}, [model_input_id, filteredModelOptions]);


	if (modelCompanyMetricsQuery.isLoading || modelInputOptionsQuery.isLoading) {
		return (
			<></>
		);
	}

	return (

		<Stack direction='row' spacing={2}
			sx={{
				mb: 1,
				borderLeft: "1px solid",
				borderLeftColor: (theme) => theme.palette.divider,
				pl: 2
			}}
		>

			{(modelCompanyMetricsQuery.data && selectedMetric) &&
				<>
					<Autocomplete
						disableClearable
						options={modelCompanyMetricsQuery.data}
						getOptionLabel={(option) => option.metric_name}
						value={selectedMetric}
						groupBy={(option) => option.company_name}
						onChange={(event, newValue) => {

							searchParams.set("metric", String(newValue.metric_id));
							setSearchParams(searchParams);

						}}

						isOptionEqualToValue={(option, value) => value.metric_id === option.metric_id}
						ChipProps={{ deleteIcon: <></> }}
						sx={{ width: 300 }}
						// filter if vendor_name = metricVendor
						filterOptions={
							(options, state) => options.filter((metric) => {
								// match vendor name toggle filter or if the string matches either metric name or company (segment) name
								return (metric.vendor_name === metricVendor) && (
									(metric.metric_name.toLowerCase().trim().includes(state.inputValue.toLowerCase().trim())) ||
									(metric.company_name.toLowerCase().trim().includes(state.inputValue.toLowerCase().trim()))
								);
							})
						}
						renderInput={(params) => (
							<TextField
								{...params}
								// hiddenLabel
								label="Metric"
								// variant="filled"
								// size="small"
								color="info"
								sx={{
									fontSize: "small",
									'.MuiInputBase-input': {
										fontSize: 'small',
									},
								}}
							/>

						)}

						renderOption={(props, option) => (
							<Stack component="li" direction="row" spacing={1}  {...props}>
								<Typography sx={{
									whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"
								}} fontSize={13} color="text.primary">{option.metric_name}</Typography>
								{(option.is_primary && modelCompanyMetricsQuery.data?.length > 1) &&
									<Box display="flex" justifyContent="flex-end" flexGrow={1}>
										<BookmarkIcon color='info' fontSize='inherit' />
									</Box>
								}
							</Stack>
						)}


						PaperComponent={({ children }) => {
							return (
								<Paper
									onMouseDown={event => event.preventDefault()}
									sx={{
										// add back autocompelte style overrides
										border: '1px solid',
										borderColor: "rgba(255, 255, 255, 0.25)",
										width: 'fit-content',
										maxWidth: 400,
										minWidth: 300,


									}}>

									<Box sx={{ paddingTop: 1, paddingBottom: 0.5, paddingLeft: 2, paddingRight: 2 }}>

										<ToggleButtonGroup
											size="small"
											color="standard"
											value={metricVendor}
											exclusive
											onChange={(event, newValue) => {
												if (newValue) {
													setMetricVendor(newValue);
												}
											}}
											fullWidth

											sx={{
												fontSize: 13,
												fontWeight: 500,
												"& .MuiToggleButton-root": {
													pl: 0.75,
													pr: 0.75,
													pt: 0.75,
													pb: 0.75,
												},
											}}

										>

											{metricVendors.map((vendor, vendorIndex) => (
												<ToggleButton sx={{ maxWidth: 140 }} key={vendorIndex} value={vendor || ""}>{vendor}</ToggleButton>
											))}

										</ToggleButtonGroup>

									</Box>

									{children}
								</Paper>
							);
						}}


					/>

				</>

			}

			{modelOptions &&
				<Autocomplete
					disableClearable
					options={modelOptions}
					getOptionLabel={(option) => option.name}
					value={modelOptions.find((option) => option.id === model_input_id) || combinedModel
					}
					onChange={(event, newValue) => {
						searchParams.set("model_input", String(newValue.id));
						setSearchParams(searchParams);
					}}
					groupBy={(option) => option.vendor_name}
					isOptionEqualToValue={(option, value) => value.id === option.id}
					ChipProps={{ deleteIcon: <></> }}
					sx={{ width: 250 }}
					renderInput={(params) => (
						<>
							<TextField
								{...params}
								// hiddenLabel
								label="Model Data"
								// variant="filled"
								// size="small"
								color="info"
								sx={{
									fontSize: "small",

									'.MuiInputBase-input': {
										fontSize: 'small',
									},
								}}
							/>
						</>

					)}

					renderOption={(props, option) => (
						<Stack component="li" direction="row" spacing={1}  {...props}>
							<Typography sx={{
								whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"
							}} fontSize={13} color="text.primary">{option.name}</Typography>

						</Stack>
					)}

				/>

			}

			{(modelFamilyQuery.data && modelFamilyQuery.data.length > 0) &&
				<>
					<Autocomplete
						disableClearable
						options={modelFamilyQuery.data}
						getOptionLabel={(option) => option.title}
						value={modelFamilyQuery.data.find((option) => option.id === model_family_id) || modelFamilyQuery.data[0]}
						onChange={(event, newValue) => {

							searchParams.set("model_family", String(newValue.id));
							setSearchParams(searchParams);

						}}

						isOptionEqualToValue={(option, value) => value.id === option.id}
						ChipProps={{ deleteIcon: <></> }}
						sx={{ width: 175 }}
						renderInput={(params) => (
							<>
								<TextField
									{...params}
									// hiddenLabel
									label="Model"
									// variant="filled"
									// size="small"
									color="info"
									sx={{
										fontSize: "small",

										'.MuiInputBase-input': {
											fontSize: 'small',
										},
									}}
								/>
							</>

						)}

						renderOption={(props, option) => (
							<Stack component="li" direction="row" spacing={1}  {...props}>
								<Typography sx={{
									whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"
								}} fontSize={13} color="text.primary">{option.title}</Typography>

							</Stack>
						)}

					/>


				</>

			}


			{(selectedMetric) &&
				<ModelSettingsButton
					companyMetric={selectedMetric}
					companySymbol={props.company_symbol || ""}
					filteredModelFieldIds={filteredModelOptions?.map((el) => el.id) || []}

				/>
			}

		</Stack>



	);

}