import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { Box, styled, alpha } from '@mui/system';
import React, { FC, useEffect, useState } from 'react';
import { useScreenerInputsQuery, useRemoveScreenerInput } from "../../api/screener"




export default function ScreenerRemoveFactorDialog(props: {
	screener_id: number,
	isOpen: boolean,
	handleClose: ((event: {}, reason: string) => void)
}) {


	const removeScreenerInput = useRemoveScreenerInput(props.screener_id);
	const screenerInputs = useScreenerInputsQuery(props.screener_id);

	return (
		<>
			<Dialog open={props.isOpen} onClose={props.handleClose}
				// fullWidth maxWidth='lg'

				PaperProps={{
					sx: {
						// minHeight: 800,
						backgroundImage: 'none'
					}
				}}
			>
				<DialogTitle>
					<Box>
						Remove Factors
						{/* <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
							Check/uncheck datasets to add or remove them from the combined KPI forecast model.
						</Typography> */}
					</Box>
					<IconButton
						aria-label="close"
						onClick={(event) => props.handleClose(event, 'buttonClose')}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<CloseIcon />
					</IconButton>

				</DialogTitle>


				<DialogContent>


					<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 2, mb: 2, ml: 5, mr: 5 }}>
						{screenerInputs.data?.map((alpha) => (
							<Chip
								key={alpha.id}
								label={alpha.name}
								onDelete={() => removeScreenerInput.mutate(alpha.id)}
							/>
						))}
					</Box>

				</DialogContent>

				{/* <DialogActions>
					<Button sx={{ width: 100 }} variant="outlined"
						onClick={(event) => { props.handleClose(event, "buttonCancel"); }}>Ok</Button>
				</DialogActions> */}

			</Dialog >


		</>

	);

}
