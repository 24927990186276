import React from 'react';
import Button from '@mui/material/Button';
import { useTheme, alpha, styled } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AddIcon from '@mui/icons-material/Add';
import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import MenuList from '@mui/material/MenuList';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import Check from '@mui/icons-material/Check';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TableChartIcon from '@mui/icons-material/TableChart';
import InsightsIcon from '@mui/icons-material/Insights';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';


const ComponentDisplayBox = styled("div")(({ theme }) => ({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	textAlign: "center",
	verticalAlign: "middle",
	borderRadius: 3,
	height: 112,
	backgroundColor: alpha(theme.palette.background.default, 0.40),
	border: "1px solid",
	borderColor: alpha(theme.palette.divider, 0.2),
	cursor: 'pointer',
	'&:hover': {
		borderColor: alpha(theme.palette.info.main, 0.7),
	},
	'&.selected': {
		border: "1.5px solid",
		borderColor: theme.palette.info.main,

		'& .MuiTypography-root': {
			color: theme.palette.info.main,
		}
	}

}))


function AddButton(props: {
	// onClick: () => void
}) {

	// for popper
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	return (
		<>
			<Button variant="outlined" color="success" onClick={handleClick} startIcon={<AddIcon />} >
				Component
			</Button>

			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				<Paper sx={{
					minWidth: 240,
					border: '1px solid',
					borderColor: (theme) => theme.palette.divider,
				}}>
					<MenuList dense>

						<MenuItem disabled>
							<ListItemIcon>
								<TableChartIcon fontSize="small" />
							</ListItemIcon>
							<ListItemText>Model Summary Table</ListItemText>

							<Check fontSize="small" />

						</MenuItem>

						<MenuItem >
							<ListItemIcon>
								<CalendarMonthIcon fontSize="small" />
							</ListItemIcon>
							<ListItemText>Earnings Calendar</ListItemText>

						</MenuItem>

						<MenuItem>
							<ListItemIcon>
								<SsidChartIcon fontSize="small" />
							</ListItemIcon>
							<ListItemText>Strategy Returns</ListItemText>

						</MenuItem>


						<MenuItem>
							<ListItemIcon>
								<EqualizerIcon fontSize="small" />
							</ListItemIcon>
							<ListItemText>Strategy Largest Positions</ListItemText>

						</MenuItem>

						<Divider />


						<MenuItem>
							<ListItemIcon>
								<InsightsIcon fontSize="small" />
							</ListItemIcon>
							<ListItemText>CC Daily Charts</ListItemText>
							<Typography color="text.disabled" sx={{ fontSize: 11 }}>View</Typography>
						</MenuItem>

						<MenuItem>
							<ListItemIcon>
								<InsightsIcon fontSize="small" />
							</ListItemIcon>
							<ListItemText>Mkt Data Dashboard</ListItemText>
							<Typography color="text.disabled" sx={{ fontSize: 11 }}>View</Typography>

						</MenuItem>



					</MenuList>
				</Paper>
			</Popover>
		</>
	)
}



export default function ComponentConfigDialog(
	props: {
		isOpen: boolean,
		handleClose: ((event: {}, reason: string) => void)
	}) {

	const theme = useTheme();

	const [selectedComponent, setSelectedComponent] = React.useState<null | number>(null);

	const currentComponents = [

		{ id: 1, size: 9, order: 0, type: "model_results_table", view_id: null },
		{ id: 3, size: 6, order: 1, type: "view", view_id: 1 },
		{ id: 2, size: 3, order: 2, type: "earnings_calendar", view_id: null },
		{ id: 4, size: 6, order: 3, type: "view", view_id: 2 },
		{ id: 5, size: 3, order: 4, type: "strategy_largest_positions_chart", view_id: null },
		{ id: 6, size: 3, order: 5, type: "strategy_cum_return_chart", view_id: null },

	]

	return (

		<Dialog open={props.isOpen} onClose={props.handleClose} fullWidth maxWidth='md'

			PaperProps={{
				sx: {
					backgroundImage: 'none'
				}
			}}
		>
			<DialogTitle>
				Customize Dashboard
			</DialogTitle>


			<DialogContent>
				<AddButton />

				<Box sx={{ padding: 1 }}>

					<Grid container spacing={2} alignItems="center"
						sx={{
							mt: 1, padding: 2,
							border: "1px solid",
							borderColor: theme.palette.divider,
						}} >

						<Grid item xs={12} flexGrow={1}>

							<Box>

								<IconButton disabled={false}>
									<ChevronLeftIcon />
								</IconButton>

								<IconButton disabled={false}>
									<ChevronRightIcon />
								</IconButton>

								<ToggleButtonGroup
									color="primary"
									exclusive
									sx={{ ml: 2 }}
									value={9}
									size="small"
								>
									<ToggleButton value="3">Web</ToggleButton>
									<ToggleButton value="6">Android</ToggleButton>
									<ToggleButton value="9">iOS</ToggleButton>
									<ToggleButton value="12">iOS</ToggleButton>
								</ToggleButtonGroup>

							</Box>

						</Grid>


						{currentComponents.map((component) => {
							return (
								<Grid item xs={component.size} key={component.id}>
									<ComponentDisplayBox
										className={component.id === selectedComponent ? "selected" : ""}
										onClick={() => setSelectedComponent(component.id)}
									>
										<Typography color="text.disabled" sx={{ fontWeight: 400, fontSize: 14 }}>{component.type}</Typography>
									</ComponentDisplayBox>
								</Grid>
							)
						})
						}

					</Grid>

				</Box>



			</DialogContent>



			<DialogActions>
				<Button size="small" variant="text" onClick={(event) => { props.handleClose(event, "buttonCancel"); }}>Cancel</Button>
				<Button size="small" variant="outlined" onClick={(event) => { props.handleClose(event, "buttonSave"); }}>Save</Button>

			</DialogActions>
		</Dialog >


	);

}
