import { blue } from '@mui/material/colors';
import { useTheme } from '@mui/system';
import {
    BarElement,
    Chart as ChartJS, Legend, LinearScale, LineElement, Title,
    Tooltip
} from 'chart.js';
import React from 'react';
import { Chart } from 'react-chartjs-2';
import { Box } from '@mui/system';


ChartJS.register(
    LinearScale,
    LineElement,
    Title,
    Tooltip,
    Legend,
    BarElement,
);


export default function SparkLine(props: {
    chartType: "line" | "bar",
    labels: string[],
    data: number[],
    color: string
}) {

    const options = {

        responsive: false,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
            },
            tooltip: {
                enabled: false,
                // mode: 'index' as any,
                // // intersect: false,
                // displayColors: false,
                // yAlign: "top" as any,
                // callbacks: {
                //     title: () => "",  // disable title and just render in label below
                //     label: function (context: any) {
                //         return `${context.label} : ${context.parsed.y}`;
                //     }
                // }
            },
        },

        events: [],
        scales: {
            x: {

                grid: {
                    display: false,
                },
                ticks: {
                    display: false,
                }

            },
            y: {
                beginAtZero: false,
                grid: {
                    display: false,
                },
                ticks: {
                    display: false,
                }
            },

        },
        animation: {
            duration: 0 // general animation time
        },
        responsiveAnimationDuration: 0, // animation duration after a resize
        spanGaps: true,

    };

    const chartData = {
        labels: props.labels,
        datasets: [
            {
                data: props.data,
                borderColor: props.color,
                backgroundColor: props.color,
                pointRadius: 2,
                borderWidth: 2,
            }
        ]
    }

    return (
        <div>
            <Chart type={props.chartType} data={chartData} options={options} style={{ height: 25, width: 180 }} />
        </div>

    );

}



