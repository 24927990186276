import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';

const StrategyCard = styled(Box)(({ theme }) => ({
	padding: theme.spacing(2),
	backgroundColor: theme.palette.card.background,
	border: "1px solid",
	borderColor: theme.palette.divider,
}));

export default StrategyCard;
