import { User } from "@auth0/auth0-react";


const auth0Namespace = "https://arbinsights.com";

export function checkUserIsAdmin(user: User): boolean {
	if (!user) return false;
	if (!user[auth0Namespace + "/roles"]) return false;
	return user[auth0Namespace + "/roles"].includes("admin");
}
