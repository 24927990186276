import { Box, useTheme, alpha } from '@mui/system';
import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import { generateRangeOptions } from "../Charts/chartSelectParams";
import ChartSelect from './ChartSelect'
import LinearProgress from '@mui/material/LinearProgress';
import { useQuery } from '@tanstack/react-query';
import Typography from '@mui/material/Typography';
import { IChartResponse } from "../../interfaces";
import apiClient from "../../services/apiClient";
import { Chart } from 'react-chartjs-2';
import { getIndexColor } from "../../components/Charts/utils"
import annotationPlugin from "chartjs-plugin-annotation"
import { green, grey, red } from '@mui/material/colors';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import ExportCSVButton from '../DataExport/ExportCSVButton';
import { convertDataForExport } from '../DataExport/utils';

import {
	Chart as ChartJS,
	Legend,
	LinearScale,
	LineElement,
	TimeScale, Title,
	Tooltip
} from 'chart.js';

ChartJS.register(
	LinearScale,
	TimeScale,
	LineElement,
	Title,
	Tooltip,
	Legend,
	annotationPlugin

);


interface IQTDQueryParams {
	calendar: 'restated' | 'non-restated',
	num_quarters: number,
}


function useQTDDataQuery(company_id: number, field_id: number,
	queryParams: IQTDQueryParams
) {

	async function getQTDData() {
		const { data } = await apiClient.get(
			`/trend-charts/qtd-chart-data/${company_id}/${field_id}`,

			{
				params: queryParams
			}

		);
		return data
	}

	return useQuery<IChartResponse>(['model_charts', 'qtd', field_id, company_id, queryParams], () => getQTDData(),
		{ enabled: company_id > 0 && field_id > 0, refetchOnWindowFocus: false, refetchOnMount: false, staleTime: Infinity }
	)
}



function headerInfoBox(chartInfos: { name: string, value: number, direction: number | null }[]) {

	return (
		<Stack direction="row" spacing={2} alignItems={"center"}>

			{chartInfos.map((info, index) => {
				return (
					<Stack key={index} direction="row" alignItems={"center"}

						// add transparent background to make it easier to read
						sx={{
							backgroundColor: alpha(grey[800], 0.4),
							borderRadius: 1, pt: 0.50, pb: 0.50, pl: 1.5, pr: 1.5,
							maxHeight: 42,
							border: "1px solid", borderColor: grey[800],
						}}
					>
						<Typography>{info.name}:&nbsp;</Typography>
						<Typography fontWeight={700}>{info.value.toFixed(1)}%</Typography>
						{info.direction === 1 && <TrendingUpIcon sx={{ color: green[400], ml: 0.5 }} />}
						{info.direction === -1 && <TrendingDownIcon sx={{ color: red[400], ml: 0.5 }} />}
					</Stack>

				)
			}
			)}


		</Stack>
	)

}




export default function QTDCompanyChart(props: {
	company_id: number,
	field_id: number,
	ToolbarComponent?: JSX.Element,
	numQuartersDefault?: number,
	showExportButton?: boolean,
	exportFileName?: string

}) {

	// Component that displays QTD chart for a single company / field

	const theme = useTheme();

	// default query params
	const [queryParams, setQueryParams] = useState<IQTDQueryParams>({
		calendar: 'restated',
		num_quarters: props.numQuartersDefault || 3,
	});

	const QTDdataQuery = useQTDDataQuery(props.company_id, props.field_id, queryParams);

	// TODO: handle if no models, Dashboard should still load
	if (QTDdataQuery.isLoading || QTDdataQuery.isFetching) {
		return (
			<Box sx={{ width: '100%' }}>
				<LinearProgress />
			</Box>
		)
	}

	const exportData = (props.showExportButton && QTDdataQuery.data) ? convertDataForExport(QTDdataQuery.data) : { rows: [], columns: [] };

	const chartDatasets = QTDdataQuery.data?.datasets.map((x, i) => ({
		...x,
		borderColor: getIndexColor(x.label, 0), // value and forecast are seperate datasets, so use static color across both datasets
		backgroundColor: getIndexColor(x.label, 0),
		pointRadius: 0,
		borderWidth: 2,
	}));

	const chartLabels = QTDdataQuery.data?.labels;

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				display: false,
				position: 'top' as const,
				labels: {
					color: theme.palette.text.primary,
					boxHeight: 5,
					boxWidth: 30,
				},
				onClick: null,

			},
			title: {
				display: false,
			},
			tooltip: {
				mode: 'index',
				position: 'average',
				intersect: false
			},

			annotation: {
				annotations: QTDdataQuery.data?.annotations || []
			},

		},

		scales: {
			x: {

				type: 'time',
				parsing: false,
				time: { unit: 'day', tooltipFormat: 'M/d/yyyy' },

				grid: {
					display: false,
					drawOnChartArea: true,
					color: theme.palette.divider
				},
				ticks: {
					display: true,
					color: theme.palette.text.secondary,
					font: {
						size: 10
					}
				}

			},
			y: {
				grid: {
					display: true,
					color: theme.palette.divider
				},
				ticks: {
					display: true,
					color: theme.palette.text.primary,
					fontSize: 6,
				},

			},

		},

		animation: {
			duration: 0

		},
		responsiveAnimationDuration: 0, // animation duration after a resize


	};


	const chartSelectParams = [

		{
			name: "calendar",
			title: 'Calendar',
			value: queryParams.calendar,
			options: [
				{ label: 'Restated', value: 'restated' },
				{ label: 'Non-Restated', value: 'non-restated' },
			],
		},
		{
			name: "num_quarters",
			title: "Display # Quarters",
			value: queryParams.num_quarters,
			options: generateRangeOptions(1, 6, "Q")
		},
	]




	return (

		<Box
			sx={{
				height: "90%",
			}}
		>

			<Stack direction="row" sx={{ mb: 1 }} justifyContent="space-between" alignItems={"center"}>

				{/* when using QTD with other toolbar components like in Dashboard, shorten title */}
				<Typography>{props.ToolbarComponent === undefined ? "Quarter to Date YoY%" : "QTD YoY"}</Typography>

				{
					headerInfoBox(QTDdataQuery.data?.info || [])
				}

				<Stack direction="row" alignItems={"flex-start"}>
					<Stack direction="row" alignItems={"flex-start"}>



						{
							chartSelectParams.map((select, index) => {
								return (
									<ChartSelect
										key={index}
										menuTitle={select.title}
										options={select.options}
										value={select.value as string}
										setValue={(val) => {
											setQueryParams(prevState => ({
												...prevState,
												[select.name]: val
											}));
										}}
									/>
								);
							})
						}

						{props.ToolbarComponent}

						{(props.showExportButton) &&
							<ExportCSVButton
								fileName={props.exportFileName ? props.exportFileName : "Quarter to Date"}
								rows={exportData.rows}
								columns={exportData.columns}
							/>
						}

					</Stack>
				</Stack>


			</Stack>

			{chartDatasets ? <Chart type="line" options={options} data={{ datasets: chartDatasets || [], labels: chartLabels }} />

				:

				<Typography color="text.disabled">No data available</Typography>
			}

		</Box>

	)

}
