import NotificationsIcon from '@mui/icons-material/Notifications';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/system';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import format from 'date-fns/format';
import React from 'react';
import { useTriggeredAlertsQuery, useSetViewed } from '../../api/alerts';
import { getIndexColor } from '../Charts/utils';
import { IAlertTriggered } from '../../interfaces/alerts'


function AlertList(props: {
	alertData: IAlertTriggered[],
	handleClose?: () => void,
	anchorEl?: null | HTMLElement
}) {

	// get notification data (triggred alerts)
	const setViewed = useSetViewed();

	const setViewedAll = () => {
		// one a user clicks the notifcaiton menu, we set user_viewed, and remove those notifs
		props.alertData.forEach((alert) => {
			setViewed.mutate(alert.id)
		})
	};


	return (

		<Menu
			id="menu-list"
			anchorEl={props.anchorEl}
			// keepMounted
			open={props.anchorEl !== null}
			onClose={props.handleClose}
			PaperProps={{
				style: {
					maxHeight: 600,
				},
			}}

			sx={{

				'& .MuiPaper-root': {
					listStyle: "none",
					border: '1px solid',

					borderColor: (theme) => theme.palette.divider,
					'&::-webkit-scrollbar': {
						width: '0.4em'
					},
					'&::-webkit-scrollbar-track': {
						boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
						webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
					},
					'&::-webkit-scrollbar-thumb': {
						backgroundColor: 'rgba(0,0,0,.1)',
						outline: '1px solid slategrey'
					}
				}

			}}

		>

			<Stack direction="row" alignItems="center" >
				<Box sx={{ flexGrow: 1 }}>
					<Typography sx={{ fontSize: 12, fontWeight: 500, ml: 1 }} color="text.secondary">
						Notifications
					</Typography>
				</Box>

				<Button size="small" sx={{ mr: 1 }} color="primary" onClick={() => setViewedAll()}>
					Clear All
				</Button>
			</Stack>

			{
				props.alertData.slice(0, 20).map((item, item_index) => {
					return (
						<MenuItem key={item.id} sx={{ pu: 0.10, pb: 0.10, pl: 0.5, pr: 0.5 }}
							onClick={() => {
								window.open(item.url, '_blank');
								props.handleClose && props.handleClose();
								setViewed.mutate(item.id);
							}}>

							<Box sx={{
								width: 300, backgroundColor: (theme) => alpha(theme.palette.background.default, 0.50), padding: 1,
								borderRadius: 0.5, border: "1px solid", borderColor: (theme) => alpha(theme.palette.action.disabledBackground, 0.2)
							}}>

								<Grid container direction="row" spacing={2} alignItems="center">


									<Grid item>
										<Avatar variant="rounded" sx={{ bgcolor: getIndexColor(item.title, item_index), width: 60, height: 28, fontSize: 14, fontWeight: 700 }}>{item.symbol}</Avatar>
									</Grid>

									<Grid item flexGrow={1} >

										<Stack direction="row">
											<Box display="flex" flexGrow={1}>

												<Typography sx={{ fontSize: 13 }} color="text.primary" align="left" display="inline">
													{item.title.length > 20 ? item.title.substring(0, 20) : item.title}
												</Typography>

											</Box>


											<Typography sx={{ fontSize: 12 }} color="text.secondary" align="right" display="inline">
												{format(new Date(item.created_at), "M/d")}
											</Typography>


										</Stack>



										<Typography sx={{ fontSize: 12 }} color="text.secondary">
											{item.description}
										</Typography>

									</Grid>





								</Grid>

							</Box>

						</MenuItem>

					)
				})
			}


		</Menu>

	);
}


export default function AppHeaderAlertDropdown() {

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const alertsQuery = useTriggeredAlertsQuery();

	if (alertsQuery.isLoading || alertsQuery.data === undefined) {
		return (
			<></>
		)
	}

	if (alertsQuery.data.length === 0) {
		return (
			<Tooltip title="No new notifications">

				<IconButton
					color="inherit"
					sx={{ mr: 1.5 }}
					disableRipple
					aria-controls='menu-list'
					aria-haspopup="true"
				>
					<NotificationsIcon />
				</IconButton>
			</Tooltip>
		)
	}

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
		// open menu to display alerts
		setAnchorEl(event.currentTarget);
	};

	return (
		<>

			<IconButton
				disableRipple
				aria-controls='menu-list'
				aria-haspopup="true"
				onClick={handleMenu}
			>
				<Badge badgeContent={alertsQuery.data.length} color="error">
					<NotificationsIcon />
				</Badge>
			</IconButton>

			<AlertList alertData={alertsQuery.data} handleClose={handleClose} anchorEl={anchorEl} />
		</>

	)



}