import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import React from 'react';
import { ViewChart, ViewCompareChart, ViewTable } from "../../components";
import { useAllViewsByCompanyQuery } from "../../api/views"
import { getIndexColor } from "../../components/Charts/utils"


const CardItem = styled("div")(({ theme }) => ({
	// height: 470,
	height: "calc(50vh - 110px)",
	padding: theme.spacing(2),
	backgroundColor: theme.palette.card.background,
	border: "1px solid",
	borderColor: theme.palette.divider,
	borderRadius: 2
}))




export default function CompanyViewsCharts(props: {
	company_id: number
}) {

	const allViewsByCompanyQuery = useAllViewsByCompanyQuery(props.company_id)


	if (props.company_id === 0) {
		return <></>
	}

	return (

		<Box flexGrow={1}>
			< Grid container
				sx={{
					mt: 0, // note this computes to some margin ?? Don't remove.
				}}
				spacing={2}
			>

				{(allViewsByCompanyQuery.data && allViewsByCompanyQuery.data.length === 0) &&

					<Box sx={{ padding: 4 }}>
						<Typography color="text.secondary">No user Views found for this company</Typography>
					</Box>
				}

				{allViewsByCompanyQuery.data?.map((view, index) => {
					return (
						<Grid key={index} item xs={6}>
							<CardItem>
								<Box sx={{

									height: "95%",
									width: '100%',

								}}>


									{["list", "compact", "table"].includes(view.display_type || "list") &&
										<>
											<Typography>{view.name}</Typography>
											<ViewChart view_id={view.id} company_id={props.company_id}
												periodLookback={view.period_lookback}
												getDatasetChartType="line"
												chartType='line' disableAnimations={true}
												getChartColor={getIndexColor}
											/>
										</>
									}

									{view.display_type === "compare" &&
										<ViewCompareChart view_id={view.id}
											title={view.name || ""}
											compact={false} />}

								</Box>
							</CardItem>
						</Grid>

					);
				})}


			</Grid >
		</Box >

	)

}