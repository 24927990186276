import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { green, grey } from '@mui/material/colors';
import { alpha, useTheme, styled } from '@mui/system';
import AppBar, { AppBarProps } from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import FitbitIcon from '@mui/icons-material/Fitbit';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useNavigate } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import { DataGrid, GridColDef, GridRenderCellParams, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import {
	AppCard
} from '../components';
import { useAddVendorAccess, useRemoveVendorAccess } from '../api/vendor';


const NavBar = styled(AppBar)<AppBarProps>(({ theme }) => ({
	backgroundColor: theme.palette.background,
	backgroundImage: 'none',
	boxShadow: 'none',
	borderBottomWidth: 1.5,
	borderBottomStyle: 'solid',
	borderBottomColor: theme.palette.divider

}))

interface IUserAccess {
	id: number;
	user_id: number;
	user_name: string;
	company_name: string;
	is_trial: boolean;
	is_active: boolean;
	access_from: string;
	datasets: IDataset[];
}


interface IDataset {
	id: number;
	name: string;
};

const userDatasetAccess: IUserAccess[] = [
	{ id: 1, user_id: 1, user_name: 'Ken Smith', company_name: 'Apollo Capital Management', is_trial: false, access_from: "2021-02-13", is_active: true, datasets: [{ id: 1, name: 'Dataset - Standard' }] },
	{ id: 2, user_id: 2, user_name: 'Jason Owens', company_name: 'XYZ Capital', is_trial: false, access_from: "2022-11-04", is_active: true, datasets: [{ id: 1, name: 'Dataset - Standard' }, { id: 2, name: 'Dataset - Premium' }] },
	{ id: 3, user_id: 3, user_name: 'Mike Cohen', company_name: 'XYZ Capital', is_trial: false, access_from: "2022-11-04", is_active: true, datasets: [{ id: 1, name: 'Dataset - Standard' }, { id: 2, name: 'Dataset - Premium' }] },
	{ id: 4, user_id: 4, user_name: 'Larry McCormick', company_name: 'Alpha Management LLC', is_trial: true, is_active: true, access_from: "2023-02-20", datasets: [{ id: 2, name: 'Dataset - Premium' }] },
	{ id: 5, user_id: 5, user_name: 'David Summers', company_name: 'BlueRock Capital LLC', is_trial: true, is_active: false, access_from: "2023-01-20", datasets: [] },
];

const datasetOptions: IDataset[] = [
	{ id: 1, name: 'Dataset - Standard' },
	{ id: 2, name: 'Dataset - Premium' },
	{ id: 3, name: 'Dataset - Quant Feed' },
]

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


function EditAccessDialog(
	props: {
		userAccess: IUserAccess | null,
		isOpen: boolean,
		handleDataSelect: ((value: IDataset[] | null, user_id: number) => void),
		handleClose: ((event: {}, reason: string) => void)
	}) {

	const [userAccess, setUserAccess] = useState<IDataset[]>(props.userAccess?.datasets || []);

	const addVendorAccess = useAddVendorAccess();
	const removeVendorAccess = useRemoveVendorAccess();

	// TODO: get from react query

	if (props.userAccess == null) {
		return null;
	}

	return (

		<Dialog open={props.isOpen} onClose={props.handleClose} fullWidth maxWidth='sm'

			PaperProps={{
				sx: {
					backgroundImage: 'none',
					minHeight: 250,
				}
			}}
		>
			<DialogTitle>
				{props.userAccess.user_name}
				<Typography display="inline" color="text.secondary" sx={{ ml: 2 }}>{props.userAccess.company_name}</Typography>
			</DialogTitle>

			<DialogContent>

				<Autocomplete
					sx={{ mt: 2 }}
					multiple
					defaultValue={props.userAccess.datasets}
					isOptionEqualToValue={(option, value) => option.id === value.id}
					onChange={(event: React.SyntheticEvent, value: IDataset[] | null) => {
						if (value) {
							setUserAccess(value);
							// 	props.handleDataSelect(value, props.userAccess?.user_id || 0);
						}

					}}

					disableClearable={true}
					options={datasetOptions}
					// disableCloseOnSelect
					getOptionLabel={(option) => option.name}
					renderOption={(props, option, { selected }) => (
						<li {...props}>
							<Checkbox
								icon={icon}
								checkedIcon={checkedIcon}
								style={{ marginRight: 8 }}
								checked={selected}
							/>
							{option.name}
						</li>
					)}

					renderInput={(params) => (
						<TextField {...params} placeholder="Datasets" />
					)}
				/>


			</DialogContent>
			<DialogActions>
				<Button size="small" variant="outlined" onClick={(event) => { props.handleClose(event, "buttonCancel"); }}>Cancel</Button>
				<Button size="small" variant="contained"
					onClick={(event) => {
						props.handleDataSelect(userAccess, props.userAccess?.user_id || 0);
						props.handleClose(event, "buttonSave");

						// UPDATE DB FROM DEMO
						if (props.userAccess?.user_id === 1) {
							if (userAccess.length === 0) {
								removeVendorAccess.mutate({ vendor_id: 4, user_id: 1 });
							} else {
								addVendorAccess.mutate({ vendor_id: 4, user_id: 1 });
							}
						}

					}}>
					Save
				</Button>

			</DialogActions>
		</Dialog >


	);

}






function renderNameCell(params: GridRenderCellParams) {
	return (
		<Typography color="text.primary" variant="body1">{params.value}</Typography>
	)
}


function renderStatusCell(params: GridRenderCellParams) {

	if (params.value === undefined || params.value === null) {
		return <></>;
	}

	return (
		<Box sx={{
			lineHeight: "24px",
			backgroundColor: alpha(params.value ? green[500] : grey[600], 0.75),
			width: "100%",
			borderRadius: 0.5,
			paddingLeft: 1,
			paddingRight: 1,
			paddingTop: 0.4,
			paddingBottom: 0.4,
			display: "flex",
			justifyContent: "center",
			fontWeight: 500

		}}>

			{params.value ? 'ACTIVE' : 'NOT ACTIVE'}
		</Box>
	);

}


function renderDatasetsCell(params: GridRenderCellParams) {

	if (params.value === undefined || params.value === null) {
		return <></>;
	}

	return (
		<Stack direction="row" spacing={1}>
			{params.value?.map((dataset: any) => {
				return (
					<Chip key={dataset.id} label={dataset.name} />
				)
			})
			}
		</Stack>
	);

}





function CustomToolbar() {
	return (
		<GridToolbarContainer>
			<GridToolbarQuickFilter
				debounceMs={500}
				// variant="outlined"
				placeholder="Search"
				// fullWidth={true}
				sx={{ width: "100%" }}
				autoComplete='off'
			/>

		</GridToolbarContainer >
	);
}


function UserAccessTable() {

	const [selectedUserAccess, setSelectedUserAccess] = React.useState<IUserAccess | null>(null);
	const [allUserAccess, setAllUserAccess] = React.useState<IUserAccess[]>(userDatasetAccess);


	function renderControlButtonCell(params: GridRenderCellParams) {

		return (
			<Tooltip title="Edit Access" onClick={() => setSelectedUserAccess((allUserAccess.find((access) => access.user_id === params.row.user_id)) || null)}>
				<IconButton color="primary" sx={{ mr: 2 }} >
					<SettingsIcon />
				</IconButton>
			</Tooltip>
		)
	}

	const columns: GridColDef[] = [
		{ field: 'user_name', headerName: 'User', flex: 1, disableColumnMenu: true, filterable: true, renderCell: renderNameCell },
		{ field: 'company_name', headerName: 'Company', flex: 1, disableColumnMenu: true, filterable: true, renderCell: renderNameCell },
		{ field: 'is_active', headerName: 'Status', flex: 1, disableColumnMenu: true, filterable: true, renderCell: renderStatusCell, maxWidth: 180 },
		{ field: 'access_from', headerName: 'Subscription Start', flex: 1, disableColumnMenu: true, filterable: false },
		{ field: 'datasets', headerName: 'Datasets', flex: 2, disableColumnMenu: true, filterable: false, sortable: false, renderCell: renderDatasetsCell, },
		{ field: 'control', headerName: '', disableColumnMenu: true, filterable: false, sortable: false, renderCell: renderControlButtonCell, align: "right" },

		// { field: 'num_features', headerName: '# Features', flex: 1, disableColumnMenu: true, filterable: false, renderCell: renderNumFeatureCell },
		// { field: 'model_type', headerName: 'Model Type', flex: 1, disableColumnMenu: true, filterable: false },
		// { field: 'run_date', headerName: 'Run Date', flex: 1, disableColumnMenu: true, filterable: false, type: "dateTime", valueGetter: ({ value }) => value && new Date(value), },
		// { field: 'status', headerName: 'Status', flex: 1, disableColumnMenu: true, filterable: false, renderCell: renderStatusCell, align: "center" },
		// { field: 'consensus', headerName: 'Consensus KPI', renderCell: renderValueCell, flex: 1, disableColumnMenu: true, sortable: false, filterable: false },
	];

	return (
		<>
			<DataGrid
				// rows={modelSummaryQuery.data || []}
				rows={allUserAccess}
				columns={columns}
				autoHeight={true}
				density="comfortable"
				// loading={modelSummaryQuery.isLoading || modelSummaryQuery.isFetching}
				disableSelectionOnClick={true}
				components={{ Toolbar: CustomToolbar }}
				initialState={{
					sorting: {
						sortModel: [{ field: 'user_id', sort: 'desc' }],
					},
				}}

				sx={{
					height: "100%",
					'& .MuiDataGrid-cell:focus-within': {
						outline: 'none'
					},
					backgroundColor: (theme) => theme.palette.card.background,

				}}
			/>

			<EditAccessDialog
				isOpen={selectedUserAccess !== null}
				userAccess={selectedUserAccess}
				handleDataSelect={(access, user_id) => {
					if (access) {
						console.log(access, user_id);
						// find user if from array and update datasets key
						const newUserAccess = allUserAccess.find((val) => val.user_id === user_id);
						if (newUserAccess) {

							let newAllUserAccess = [];
							// set it this way to rpeserve order (other table resorts)
							for (let i = 0; i < allUserAccess.length; i++) {
								if (allUserAccess[i].user_id === user_id) {
									newAllUserAccess.push({ ...allUserAccess[i], datasets: access, is_active: access.length > 0 });
								} else {
									newAllUserAccess.push(allUserAccess[i]);
								}
							}

							setAllUserAccess(newAllUserAccess);
						}
					}
				}}
				handleClose={() => setSelectedUserAccess(null)}
			/>

		</>

	)

}




export default function VendorAccess() {

	const theme = useTheme();
	const navigate = useNavigate();

	return (
		<>
			<NavBar position="static">
				<Toolbar>
					<FitbitIcon color="primary" sx={{ mr: 4, fontSize: 34 }} />
					<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
						Dataset Access Management
					</Typography>
					{/* <Button onClick={() => logoutAndRedirect()} color="inherit">Logout</Button> */}
				</Toolbar>
			</NavBar>


			<Box
				//   container
				//   spacing={0}
				// direction="column"
				// flexDirection={"row"}
				// display="flex"
				// alignItems="center"
				// justifyContent="center"

				// minHeight="100vh"
				maxWidth={2000}
			>
				<AppCard>
					<UserAccessTable />
				</AppCard >

			</Box>
		</>
	);
}