

export function getPeriodCompareChartParam(defaultValue: "yoy" | "yo2y" | "yo3y" | "value" = "yoy", includeValue: boolean = true) {

    return {
        name: "period_compare",
        title: "Period Comparison",
        defaultValue: defaultValue,
        options: [
            { label: "YoY %", value: "yoy" },
            { label: "Yo2Y %", value: "yo2y" },
            { label: "Yo3Y %", value: "yo3y" },
            // { label: "Values", value: "value" },
            ...(includeValue && [{ label: "Values", value: "value" }] || [])
        ]
    }

}

export function generateRangeOptions(minNum: number, maxNum: number, postFix: string = "") {
    var list = [];
    for (var i = minNum; i <= maxNum; i++) {
        list.push({ label: String(i) + postFix, value: String(i) });
    }

    return list;


}

export function getNumQuartersChartParam(minValue: number, maxValue: number, defaultValue: number = 8) {

    return {
        name: "num_quarters",
        title: "Display # Quarters",
        defaultValue: String(defaultValue),
        options: generateRangeOptions(minValue, maxValue, "Q")
    }

}

export function getPeriodLookbackChartParam(defaultValue: "3m" | "6m" | "YTD" | "1y" | "2y" = "1y") {

    return {
        name: "period_lookback",
        title: "Lookback Period",
        defaultValue: String(defaultValue),
        options: [
            { label: "3m", value: "3m" },
            { label: "6m", value: "6m" },
            { label: "YTD", value: "YTD" },
            { label: "1y", value: "1y" },
            { label: "2y", value: "2y" },
        ]
    }

}