import axios, { AxiosError } from "axios";


function getAPIBaseUrl() {

    if (process.env.NODE_ENV === "development") {
        return "http://127.0.0.1:8000/api";
    }

    const env = process.env.REACT_APP_ENV;

    if (env === "prod") {
        return "https://api.arbinsights.com/api";
    }

    return `https://${env}.api.arbinsights.com/api`;
}


export function getApiClient() {

    const baseUrl = getAPIBaseUrl();

    const instance = axios.create({
        baseURL: baseUrl,
        headers: { "Content-Type": "application/json" }
    });

    instance.interceptors.response.use((response) => {
        return response;
    }, (error: AxiosError) => {
        // if (error?.response?.status === 401 || error?.response?.status === 403) {
        //     // if not authorized or token expired, redirect to login screen
        //     // TODO: maybe just show not authorized message
        //     // logout();
        //     // window.location.reload();
        //     console.log("error 401 or 403");
        // }
        if (error?.response) {
            // return Promise.reject(error.response);
            return Promise.reject(error);
        }
        // return Promise.reject(error.message);
        return Promise.reject(error);
    });

    return instance;

}


const apiClient = getApiClient();

// token is set in AuthTokenLayer
export const addAccessTokenInterceptor = (getAccessTokenSilently: any) => {

    apiClient.interceptors.request.use(
        async config => {
            const token = await getAccessTokenSilently();

            return {
                ...config,
                headers: { ...config.headers, Authorization: `Bearer ${token}` },
            };
        },
        error => {
            Promise.reject(error);
        },
    );

    return Promise.resolve();

};

export default apiClient;
