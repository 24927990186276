import SearchIcon from '@mui/icons-material/Search';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { useInputDataOptionsQuery, IDataInput } from '../../api/reference';
import React from 'react';
import { Typography } from '@mui/material';


export default function DataSearchField(props: {
    inputValue: string,
    setInputValue: React.Dispatch<React.SetStateAction<string>>,
    minOpenLength: number,
    onSearchSelect: (input: IDataInput) => void

}) {

    const inputOptionsQuery = useInputDataOptionsQuery();

    return (
        <Autocomplete
            id="search-dataset-options"
            open={props.inputValue.length >= props.minOpenLength}
            // open={true}
            inputValue={props.inputValue}
            // onInputChange={(event, value) => props.setInputValue(value || "")}
            onInputChange={(event, newInputValue, reason) => {
                if (reason === 'reset') {
                    props.setInputValue('')
                    return
                } else {
                    props.setInputValue(newInputValue)
                }
            }}
            sx={{
                mb: 2,
            }}
            componentsProps={{
                paper: { style: { border: "1px solid", borderColor: "rgba(255,255,255,1)", borderTop: "none" } },
            }}
            options={inputOptionsQuery.data || []}
            groupBy={(option) => option.group}
            getOptionLabel={(option: any) => option.name + option.title + option.source_name || ""} // concat so we can search either by data name or source name
            isOptionEqualToValue={(option, value) => option.underlying_id === value.underlying_id && option.source_name === value.source_name}
            fullWidth
            size="small"
            popupIcon={""}
            loading={inputOptionsQuery.isLoading}
            onChange={(event: React.SyntheticEvent, value: IDataInput | null) => {
                if (value) {
                    props.onSearchSelect(value);
                }
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    fullWidth
                    placeholder={`Search all datasets`}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            )}

            renderOption={(props, option) => (
                <Stack component="li" direction="row" spacing={1}  {...props}>
                    <Typography sx={{
                        whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"
                    }} fontSize={13} color="text.primary">{option.title}</Typography>

                    <Box display="flex" justifyContent="flex-end" flexGrow={1}>
                        <Typography fontSize={11} variant="body2" color="text.secondary">{option.source_name}</Typography>
                    </Box>
                </Stack>
            )}

        />
    )

}
