import React, { useState } from 'react';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { AxiosError } from 'axios'


function SnackbarError(props: { open: boolean, message: string, onClose: () => void }) {

    return (
        <Snackbar open={props.open} autoHideDuration={5000} onClose={props.onClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <MuiAlert variant="filled" severity="error" onClose={props.onClose}>
                {props.message}
            </MuiAlert>
        </Snackbar>
    )
}


export function useErrorMessageAlert() {

    // See ModelSelection.tsx for examples how to use this hook to display errors.

    const [errorMessage, setErrorMessage] = useState<string>("");
    const ErrorMessageAlert = <SnackbarError open={errorMessage !== ""} message={errorMessage} onClose={() => setErrorMessage("")} />

    function setErrorMessageFromResponse(error: any | AxiosError) {
        const msg = error?.response?.data?.detail;

        if (msg) {
            if (Array.isArray(msg)) {
                setErrorMessage(msg[0].msg);
            } else {
                setErrorMessage(msg);
            }
        } else {
            setErrorMessage('An error occured. Please try again.');
        }
    }

    return { setErrorMessage, ErrorMessageAlert, setErrorMessageFromResponse };
}
