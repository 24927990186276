import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';
import Switch from '@mui/material/Switch';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useDataTransformOptionsQuery, IDataInput } from "../../api/reference"
import { ICreateInput } from "../../interfaces";

const PERIOD_COMP_OPTIONS = [{ title: "None", value: "none" }, {
    title: "% Change from",
    value: "pct_change"
}, { title: 'Difference (Delta) from', value: "difference" }];

const SMOOTH_OPTIONS = [{ title: "None", value: "none" }, {
    title: "Rolling Average",
    value: "rolling_avg"
}, { title: "Rolling Sum", value: "rolling_sum" }, { title: "Exponential Rolling Average", value: "rolling_exp_avg" },
];

const NORM_OPTIONS = [
    { title: "None", value: "none" },
    {
        title: "Scaler (0 - 100)",
        value: "scaler"
    },
    {
        title: "Index (Base 100)",
        value: "index"
    },
    { title: "Z-Score", value: "zscore" }];



interface IPeriodComp {
    type: string;
    value: number | "";
    period: string;

}


interface ISmoothing {
    type: string;
    value: number | "";
}

interface INormalization {
    type: string;
    value?: number | "";
}


interface IInputErrors {
    dailyPeriodCompare?: string, fiscalPeriodCompare?: string, smoothing?: string, normalization?: string, lagDays?: string
}


function DailyPeriodCompareSelect(props: {
    dailyPeriodCompare: IPeriodComp
    setDailyPeriodCompare: React.Dispatch<React.SetStateAction<IPeriodComp>>
    error: string | null
}) {


    return (

        <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
        >

            <Typography>Period Compare</Typography>


            <Select
                id="transform-type-select"
                value={props.dailyPeriodCompare.type}
                // onChange={(event: SelectChangeEvent) => props.setTransform(prevState => {...prevState, type: event.target.value as string} )}
                onChange={(event: SelectChangeEvent) => props.setDailyPeriodCompare(prevState => ({ ...prevState, type: event.target.value }))}
                displayEmpty
                size="small"
            >

                {PERIOD_COMP_OPTIONS.map((i) => {
                    return (<MenuItem key={i.value} value={i.value}>{i.title}</MenuItem>)
                })}

            </Select>

            {
                (props.dailyPeriodCompare.type !== "none") ?

                    <>
                        <TextField value={props.dailyPeriodCompare.value} placeholder="# of periods" size="small"
                            inputProps={{ type: 'number' }}
                            variant="filled" hiddenLabel
                            error={props.error ? true : false}
                            helperText={props.error}
                            sx={{
                                maxWidth: 130,

                                '& input[type=number]': {
                                    'MozAppearance': 'textfield'
                                },
                                '& input[type=number]::-webkit-outer-spin-button': {
                                    'WebkitAppearance': 'none',
                                    margin: 0
                                },
                                '& input[type=number]::-webkit-inner-spin-button': {
                                    'WebkitAppearance': 'none',
                                    margin: 0
                                }


                            }}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {

                                const val = event.target.value ? parseInt(event.target.value) || "" : "";
                                props.setDailyPeriodCompare(prevState => ({ ...prevState, value: val }));


                            }}
                        />

                        <Select
                            id="transform-period-select"
                            value={props.dailyPeriodCompare.period}
                            onChange={(event: SelectChangeEvent) => props.setDailyPeriodCompare(prevState => ({ ...prevState, period: event.target.value }))}
                            displayEmpty
                            size="small"
                        >
                            <MenuItem value={"days"}>Days</MenuItem>
                            <MenuItem value={"weeks"}>Weeks</MenuItem>
                            <MenuItem value={"years"}>Years</MenuItem>
                        </Select>
                    </>

                    : <></>
            }

        </Stack >


    )
}



function FiscalPeriodCompareSelect(props: {
    fiscalPeriodCompare: IPeriodComp
    setFiscalPeriodCompare: React.Dispatch<React.SetStateAction<IPeriodComp>>
    error: string | null
}) {


    return (


        <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
        >

            <Typography>Fiscal Period Compare</Typography>


            <Select
                value={props.fiscalPeriodCompare.type}
                // onChange={(event: SelectChangeEvent) => props.setTransform(prevState => {...prevState, type: event.target.value as string} )}
                onChange={(event: SelectChangeEvent) => props.setFiscalPeriodCompare(prevState => ({ ...prevState, type: event.target.value }))}
                displayEmpty
                size="small"
            >

                {PERIOD_COMP_OPTIONS.map((i) => {
                    return (<MenuItem key={i.value} value={i.value}>{i.title}</MenuItem>)
                })}

            </Select>

            {
                (props.fiscalPeriodCompare.type !== "none") ?

                    <>
                        <TextField
                            value={props.fiscalPeriodCompare.value}
                            placeholder="# of periods"
                            size="small"
                            error={props.error ? true : false}
                            helperText={props.error}
                            inputProps={{ type: 'number' }}
                            variant="filled" hiddenLabel
                            sx={{
                                maxWidth: 130,

                                '& input[type=number]': {
                                    'MozAppearance': 'textfield'
                                },
                                '& input[type=number]::-webkit-outer-spin-button': {
                                    'WebkitAppearance': 'none',
                                    margin: 0
                                },
                                '& input[type=number]::-webkit-inner-spin-button': {
                                    'WebkitAppearance': 'none',
                                    margin: 0
                                }


                            }}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {

                                const val = event.target.value ? parseInt(event.target.value) || "" : "";
                                props.setFiscalPeriodCompare(prevState => ({ ...prevState, value: val }));


                            }}
                        />

                        <Select
                            id="transform-period-select"
                            value={props.fiscalPeriodCompare.period}
                            onChange={(event: SelectChangeEvent) => props.setFiscalPeriodCompare(prevState => ({ ...prevState, period: event.target.value }))}
                            displayEmpty
                            size="small"
                        >
                            <MenuItem value={"years"}>Fiscal Years</MenuItem>
                            <MenuItem value={"quarters"}>Fiscal Quarters</MenuItem>
                        </Select>
                    </>

                    : <></>
            }

        </Stack >


    )
}




function SmoothSelect(props: {
    smoothing: ISmoothing
    setSmoothing: React.Dispatch<React.SetStateAction<ISmoothing>>
    error: string | null
}) {


    return (

        <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
        >

            <Typography>Smoothing&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Typography>


            <Select
                id="transform-type-select"
                value={props.smoothing.type}
                // onChange={(event: SelectChangeEvent) => props.setTransform(prevState => {...prevState, type: event.target.value as string} )}
                onChange={(event: SelectChangeEvent) => props.setSmoothing(prevState => ({ ...prevState, type: event.target.value }))}
                displayEmpty
                size="small"
            >

                {SMOOTH_OPTIONS.map((i) => {
                    return (<MenuItem key={i.value} value={i.value}>{i.title}</MenuItem>)
                })}

            </Select>

            {
                (props.smoothing.type !== "none") ?

                    <>
                        <TextField value={props.smoothing.value}
                            id="smooth_numdays"
                            placeholder="# of"
                            size="small"
                            error={props.error ? true : false}
                            helperText={props.error}
                            InputProps={{
                                type: 'number',
                                endAdornment: <InputAdornment position="end">days</InputAdornment>
                            }}
                            variant="filled"
                            autoComplete="off"
                            hiddenLabel
                            sx={{
                                maxWidth: 110,

                                '& input[type=number]': {
                                    'MozAppearance': 'textfield'
                                },
                                '& input[type=number]::-webkit-outer-spin-button': {
                                    'WebkitAppearance': 'none',
                                    margin: 0
                                },
                                '& input[type=number]::-webkit-inner-spin-button': {
                                    'WebkitAppearance': 'none',
                                    margin: 0
                                }


                            }}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {

                                const val = event.target.value ? parseInt(event.target.value) || "" : "";
                                props.setSmoothing(prevState => ({ ...prevState, value: val }));


                            }}
                        />


                    </>

                    : <></>
            }

        </Stack >


    )
}






function NormSelect(props: {
    normalization: INormalization
    setNormalization: React.Dispatch<React.SetStateAction<INormalization>>
    error: string | null
}) {


    return (

        <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
        >

            <Typography>Normalization&nbsp;&nbsp;&nbsp;</Typography>


            <Select
                id="transform-type-select"
                value={props.normalization.type}
                // onChange={(event: SelectChangeEvent) => props.setTransform(prevState => {...prevState, type: event.target.value as string} )}
                onChange={(event: SelectChangeEvent) => props.setNormalization(prevState => ({ ...prevState, type: event.target.value }))}
                displayEmpty
                size="small"
            >

                {NORM_OPTIONS.map((i) => {
                    return (<MenuItem key={i.value} value={i.value}>{i.title}</MenuItem>)
                })}

            </Select>

            {
                (props.normalization.type === "zscore") ?

                    <>
                        <TextField value={props.normalization.value}
                            id="numdays"
                            placeholder="# of"
                            size="small"
                            error={props.error ? true : false}
                            helperText={props.error}
                            InputProps={{
                                type: 'number',
                                endAdornment: <InputAdornment position="end">periods</InputAdornment>
                            }}
                            variant="filled"
                            autoComplete="off"
                            hiddenLabel
                            sx={{
                                maxWidth: 110,

                                '& input[type=number]': {
                                    'MozAppearance': 'textfield'
                                },
                                '& input[type=number]::-webkit-outer-spin-button': {
                                    'WebkitAppearance': 'none',
                                    margin: 0
                                },
                                '& input[type=number]::-webkit-inner-spin-button': {
                                    'WebkitAppearance': 'none',
                                    margin: 0
                                }


                            }}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                const val = event.target.value ? parseInt(event.target.value) || "" : "";
                                props.setNormalization(prevState => ({ ...prevState, value: val }));
                            }}
                        />


                    </>

                    : <></>
            }

        </Stack >


    )
}



function LagSelect(props: {
    lagDays: number
    setLagDays: React.Dispatch<React.SetStateAction<number>>
    error: string | null
}) {


    const [showLag, setShowLag] = useState(false);

    return (

        <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
        >

            <Typography>Lag Data&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Typography>


            <Select
                id="transform-type-select"
                value={showLag ? "lag" : "None"}
                // onChange={(event: SelectChangeEvent) => props.setTransform(prevState => {...prevState, type: event.target.value as string} )}
                onChange={(event: SelectChangeEvent) => {

                    if (event.target.value === "lag") {
                        setShowLag(true);
                    } else {
                        setShowLag(false);
                    }

                }}
                displayEmpty
                size="small"
            >

                <MenuItem value={"None"}>None</MenuItem>
                <MenuItem value={"lag"}>Add Lag</MenuItem>

            </Select>

            {
                showLag ?

                    <>
                        <TextField
                            value={props.lagDays != 0 ? props.lagDays : ""}
                            id="numdays"
                            placeholder="# of"
                            size="small"
                            error={props.error ? true : false}
                            InputProps={{
                                type: 'number',
                                endAdornment: <InputAdornment position="end">days</InputAdornment>
                            }}
                            variant="filled"
                            autoComplete="off"
                            hiddenLabel
                            sx={{
                                maxWidth: 110,

                                '& input[type=number]': {
                                    'MozAppearance': 'textfield'
                                },
                                '& input[type=number]::-webkit-outer-spin-button': {
                                    'WebkitAppearance': 'none',
                                    margin: 0
                                },
                                '& input[type=number]::-webkit-inner-spin-button': {
                                    'WebkitAppearance': 'none',
                                    margin: 0
                                }


                            }}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                const val = event.target.value ? parseInt(event.target.value) || 0 : 0;
                                props.setLagDays(val);
                            }}
                        />


                    </>

                    : <></>
            }

        </Stack >


    )
}

function FactorSignSelect(props: {
    factorSign: number
    setFactorSign: React.Dispatch<React.SetStateAction<number>>
}) {


    return (

        <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
        >

            <Typography>Factor Sign&nbsp;&nbsp;&nbsp;</Typography>


            <ButtonGroup variant="outlined">
                <Button onClick={() => props.setFactorSign(1)} variant={props.factorSign === 1 ? "contained" : "outlined"}>Positive</Button>
                <Button onClick={() => props.setFactorSign(-1)} variant={props.factorSign === -1 ? "contained" : "outlined"}>Negative</Button>
            </ButtonGroup>


        </Stack >


    )
}




export default function DatasetDialog(
    props: {
        tableType: "screener" | "view",
        dataInput: IDataInput,
        isOpen: boolean,
        addInput: (addedInput: ICreateInput) => void,
        // postUrl: string,
        handleClose: () => void,
        axis?: number
        user_input_id?: number // if provided, update input otherwise post new
        errorMsg?: string,

    }) {

    const [inputErrors, setInputErrors] = useState<IInputErrors>({});

    // transformation select
    const [dailyPeriodCompare, setDailyPeriodCompare] = useState<IPeriodComp>({ type: "none", value: "", period: "years" });
    const [fiscalPeriodCompare, setFiscalPeriodCompare] = useState<IPeriodComp>({ type: "none", value: "", period: "years" });
    const [smoothing, setSmoothing] = useState<ISmoothing>({ type: "none", value: "" });
    const [normalization, setNormalization] = useState<INormalization>({ type: "none", value: "" });
    const [factorSign, setFactorSign] = useState<number>(1);
    const [lagDays, setLagDays] = useState<number>(0);

    const dataTransformOptionsQuery = useDataTransformOptionsQuery(props.dataInput.underlying_id, props.dataInput.type, props.tableType);
    const datasetTransformOptions = dataTransformOptionsQuery.data || [];

    function handleSubmit() {

        setInputErrors({});

        let currentErrors: IInputErrors = {};

        var dataParamsArray = new Array();
        if (dailyPeriodCompare.type !== "none") {
            if (dailyPeriodCompare.value === "") {
                currentErrors['dailyPeriodCompare'] = "Please enter a value";
            } else {
                dataParamsArray.push({ name: 'daily_period_compare', params: dailyPeriodCompare });
            }
        }
        if (fiscalPeriodCompare.type !== "none") {
            if (fiscalPeriodCompare.value === "") {
                currentErrors['fiscalPeriodCompare'] = "Please enter a value";
            } else {
                dataParamsArray.push({ name: 'fiscal_period_compare', params: fiscalPeriodCompare });
            }
        }
        if (smoothing.type !== "none") {
            if (smoothing.value === "") {
                currentErrors['smoothing'] = "Please enter a value";
            }
            else {
                dataParamsArray.push({ name: 'smoothing', params: smoothing });
            }

        }

        if (normalization.type !== "none") {
            if (normalization.type === "zscore" && normalization.value === "") {
                currentErrors['normalization'] = "Please enter a value";
            }
            else {
                if (normalization.value === "") {
                    delete normalization.value;
                }
                dataParamsArray.push({ name: 'normalize', params: normalization });
            }

        }


        if (lagDays != 0) {
            if (lagDays > 1000 || lagDays < -1000) {
                currentErrors['lagDays'] = "Please enter a value between -1000 and 1000";
            }
            else {
                dataParamsArray.push({ name: 'lag_data', params: { type: 'lag_days', value: lagDays } });
            }

        }

        if (Object.keys(currentErrors).length > 0) {
            setInputErrors(currentErrors);
            return;
        }

        let postData = {
            name: props.dataInput.name,
            underlying_id: props.dataInput.underlying_id,
            params: dataParamsArray,
            type: props.dataInput.type,
            ...(props.axis && { axis: props.axis }),
            ...(factorSign !== 1 && { factor_sign: factorSign }),
        }

        props.addInput(postData);

    }
    return (

        <Dialog open={props.isOpen} onClose={props.handleClose} fullWidth maxWidth='md'

            PaperProps={{
                sx: {
                    minHeight: 300,
                    backgroundImage: 'none',
                    border: "1px solid rgba(56, 56, 56, 1)",

                }
            }}
        >
            <DialogTitle>

                <Box>
                    <Typography color="primary" variant="h6"  >{props.dataInput.name}</Typography>
                    {props.dataInput.title !== props.dataInput.name &&
                        <Typography color="text.secondary" variant="subtitle1" >Description:&nbsp;
                            {props.dataInput.title}
                        </Typography>
                    }

                    {props.errorMsg && <Typography color="error" variant="subtitle1" >{props.errorMsg}</Typography>}

                </Box>

            </DialogTitle>


            <DialogContent>

                <Box sx={{ mt: 2 }}>

                    <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom={true}>Data Transformations:</Typography>
                        <Divider />
                    </Box>

                    <Stack direction="column" spacing={2}>

                        {datasetTransformOptions.includes('smoothing') ? <SmoothSelect smoothing={smoothing} setSmoothing={setSmoothing} error={inputErrors?.smoothing || null} /> : <></>}
                        {datasetTransformOptions.includes('fiscal_period_compare') ? <FiscalPeriodCompareSelect fiscalPeriodCompare={fiscalPeriodCompare} setFiscalPeriodCompare={setFiscalPeriodCompare} error={inputErrors?.fiscalPeriodCompare || null} /> : <></>}
                        {datasetTransformOptions.includes('daily_period_compare') ? <DailyPeriodCompareSelect dailyPeriodCompare={dailyPeriodCompare} setDailyPeriodCompare={setDailyPeriodCompare} error={inputErrors?.dailyPeriodCompare || null} /> : <></>}
                        {datasetTransformOptions.includes('normalization') ? <NormSelect normalization={normalization} setNormalization={setNormalization} error={inputErrors?.normalization || null} /> : <></>}

                        {datasetTransformOptions.includes('lag_data') ? <LagSelect lagDays={lagDays} setLagDays={setLagDays} error={inputErrors?.lagDays || null} /> : <></>}
                        {datasetTransformOptions.includes('factor_sign') ? <FactorSignSelect factorSign={factorSign} setFactorSign={setFactorSign} /> : <></>}

                    </Stack>

                </Box>

            </DialogContent>
            <DialogActions>
                <Button size="small" variant="text" onClick={(event) => { props.handleClose(); }}>Cancel</Button>
                <Button size="small" variant="contained" onClick={(event) => { handleSubmit(); }}>Add</Button>
            </DialogActions>
        </Dialog >


    );

}

