import React, { useState } from 'react';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import MuiAlert, { AlertProps, AlertColor } from '@mui/material/Alert';
import { AxiosError } from 'axios'
import { PropaneSharp } from '@mui/icons-material';

interface IMessageAlert {
	message: string;
	severity?: AlertColor;
}

function SnackbarMsg(props: { open: boolean, message: string, severity?: AlertColor, onClose: () => void }) {

	return (
		<Snackbar open={props.open} autoHideDuration={4000} onClose={props.onClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
			<MuiAlert variant="filled" severity={props.severity || 'success'} onClose={props.onClose}>
				{props.message}
			</MuiAlert>
		</Snackbar>
	)
}


export function useSnackbar() {
	const [alertMessage, setAlertMessage] = useState<IMessageAlert>({ message: "" });
	const AlertMessage = <SnackbarMsg open={alertMessage.message !== ""} message={alertMessage.message} severity={alertMessage.severity} onClose={() => setAlertMessage({ message: "" })} />
	return { setAlertMessage, AlertMessage };
}
