
import GridOnIcon from '@mui/icons-material/GridOn';
import GridViewIcon from '@mui/icons-material/GridView';
import ViewListIcon from '@mui/icons-material/ViewList';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import React, { useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useViewQuery, useUpdateView, PeriodLookbackType, useRemoveView } from "../../api/views"
import NotificationAddOutlinedIcon from '@mui/icons-material/NotificationAddOutlined';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import SettingsIcon from '@mui/icons-material/Settings';
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { AxiosError } from 'axios';



const PrimaryIconButtonBox = styled(IconButton)(({ theme }) => ({
    mr: 1,
    ml: 0,
    width: 45,
    height: 45,
    borderRadius: 1,
    border: "1px solid",
    borderColor: theme.palette.action.selected,
    color: theme.palette.text.primary,

}));


function RenameDialog(
    props: {
        view_id: number,
        currentName: string,
        isOpen: boolean,
        handleDialogClose: () => void,
    }) {


    const [name, setName] = useState<string>(props.currentName);

    const [hasError, setHasError] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>("");
    const updateView = useUpdateView(props.view_id);

    function onSubmit() {

        setHasError(false);
        setErrorMsg("");

        updateView.mutate({ name: name },
            {
                onSuccess: () => {
                    setHasError(false);
                    setErrorMsg("");
                    props.handleDialogClose();
                },
                onError: (error: any | AxiosError) => {
                    setHasError(true);
                    const msg = error.response?.data?.detail;
                    if (msg) {
                        if (Array.isArray(msg)) {
                            setErrorMsg(msg[0].msg);
                        } else {
                            setErrorMsg(msg);
                        }
                    } else {
                        setErrorMsg("An error occurred. Please try again.");
                    }
                }
            }
        );


    }

    return (

        <Dialog open={props.isOpen} onClose={props.handleDialogClose} maxWidth='md'

            PaperProps={{
                sx: {
                    minHeight: 200,
                    backgroundImage: 'none'
                }
            }}
        >
            <DialogTitle>
                Rename View
            </DialogTitle>

            <DialogContent>

                <TextField
                    autoComplete="off"
                    label="Name" variant="outlined" size="small"
                    sx={{ mt: 2, minWidth: 240 }}
                    helperText={errorMsg}
                    error={hasError}
                    value={name}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setName(event.target.value)
                    }
                />

            </DialogContent>
            <DialogActions>
                <Button size="small" variant="text" onClick={() => {
                    props.handleDialogClose();
                    setHasError(false);
                    setErrorMsg("");
                    setName(props.currentName);
                }}>Cancel</Button>
                <Button size="small" variant="contained" onClick={() => { onSubmit(); }}>Save</Button>
            </DialogActions>
        </Dialog >


    );

}



function SettingsButton(props: { view_id: number, onRenameClick: () => void }) {


    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const removeView = useRemoveView();
    const navigate = useNavigate();

    return (
        <>
            <IconButton
                sx={{ ml: 1 }}
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}
                aria-haspopup="true">
                <SettingsIcon color="disabled" />
            </IconButton>

            <Menu
                open={open}
                onClose={() => setAnchorEl(null)}
                anchorEl={anchorEl}
            >
                <Box>



                    <MenuItem onClick={() => {
                        props.onRenameClick();
                        setAnchorEl(null);
                    }}>
                        Rename View
                    </MenuItem>
                    <Divider />

                    <MenuItem onClick={() => {
                        removeView.mutate(props.view_id, { onSuccess: () => navigate('/dashboard') });
                        setAnchorEl(null);
                    }}>
                        Delete View
                    </MenuItem>

                </Box>
            </Menu>
        </>

    )
}


export default function ViewToolbar(props: {
    view_id: number,
    displayValue: string,
    displayOnChange: (value: "list" | "compact" | "table" | "compare") => void,
    handleOpenAlertsDialog?: () => void

}) {

    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    const isViewer = pathname.split('/')[1] === 'view-display';
    const displaySwitchNavigate = isViewer ? `/view-builder/${props.view_id}` : `/view-display/${props.view_id}`;
    const viewQuery = useViewQuery(props.view_id);
    const updateView = useUpdateView(props.view_id);

    const [renameDialogOpen, setRenameDialogOpen] = useState<boolean>(false);

    if (viewQuery.isLoading) {
        return <></>
    }

    return (

        <>

            <Stack direction="row" spacing={2}
                sx={{
                    mb: 2
                }}>

                <Box display="flex" justifyContent="flex-start" flexGrow={1}>
                    <Typography sx={{ mb: 2 }} variant="h6" color="primary">{viewQuery.data?.name || ""}</Typography>
                    {isViewer === false && <Typography variant="h6" color="text.secondary" sx={{ fontWeight: 400, ml: 2.5 }}>View Builder</Typography>}
                </Box>

                <Box display="flex" justifyContent="flex-end" flexGrow={0}>

                    {!isViewer &&

                        <SettingsButton
                            view_id={props.view_id}
                            onRenameClick={() => setRenameDialogOpen(true)}
                        />

                    }


                    {!isViewer &&

                        <Tooltip title="Set Alerts">
                            <IconButton
                                sx={{
                                    color: (theme) => theme.palette.text.disabled,
                                    // on hover change color to primary
                                    '&:hover': {
                                        color: (theme) => theme.palette.primary.main,
                                        // backgroundColor: (theme) => theme.palette.action.selected
                                    },
                                }}
                                // variant="outlined"
                                // size="small"
                                // startIcon={<NotificationAddOutlinedIcon />}
                                onClick={(event: React.MouseEvent<HTMLButtonElement>) => props.handleOpenAlertsDialog && props.handleOpenAlertsDialog()}
                            >
                                <NotificationAddOutlinedIcon />
                            </IconButton>
                        </Tooltip>


                    }


                    <ToggleButtonGroup
                        sx={{ ml: 2 }}
                        value={props.displayValue}
                        exclusive
                        size="small"
                        onChange={(event, newValue) => {
                            if (newValue !== props.displayValue && newValue) {
                                props.displayOnChange(newValue);
                            }

                        }}
                        aria-label="text alignment"
                    >

                        <ToggleButton value="list">
                            <Tooltip title="Ticker view">
                                <ViewListIcon />
                            </Tooltip>

                        </ToggleButton>

                        <ToggleButton value="compact">
                            <Tooltip title="Compact view">
                                <GridViewIcon />
                            </Tooltip>
                        </ToggleButton>

                        <ToggleButton value="table">
                            <Tooltip title="Table view">
                                <GridOnIcon />
                            </Tooltip>
                        </ToggleButton>

                        <ToggleButton value="compare">
                            <Tooltip title="Comparison view">
                                <StackedLineChartIcon />
                            </Tooltip>
                        </ToggleButton>

                    </ToggleButtonGroup>


                    <Select
                        value={viewQuery.data?.period_lookback || "1y"}
                        variant="outlined"
                        size="small"
                        // disableUnderline={true}
                        sx={{ ml: 2, mr: 2, }}
                        onChange={(event: SelectChangeEvent<string>) => {
                            const periodLookback = event.target.value as PeriodLookbackType;
                            if (periodLookback) {
                                updateView.mutate({ period_lookback: periodLookback });
                            }
                        }}
                    >
                        <MenuItem value={"1m"}>1m</MenuItem>
                        <MenuItem value={"3m"}>3m</MenuItem>
                        <MenuItem value={"6m"}>6m</MenuItem>
                        <MenuItem value={"ytd"}>YTD</MenuItem>
                        <MenuItem value={"1y"}>1y</MenuItem>
                        <MenuItem value={"2y"}>2y</MenuItem>
                        <MenuItem value={"3y"}>3y</MenuItem>

                    </Select>

                    {isViewer ?
                        <Tooltip title="Edit View">
                            <PrimaryIconButtonBox
                                onClick={(event: React.MouseEvent<HTMLButtonElement>) => navigate({ pathname: displaySwitchNavigate, search: searchParams.toString() })}
                                aria-haspopup="true">
                                <EditIcon />
                            </PrimaryIconButtonBox>
                        </Tooltip>
                        :

                        <Tooltip title="Fullscreen">
                            <PrimaryIconButtonBox
                                size="small"
                                onClick={(event: React.MouseEvent<HTMLButtonElement>) => navigate({ pathname: displaySwitchNavigate, search: searchParams.toString() })}
                                aria-haspopup="true">
                                <FullscreenIcon />
                            </PrimaryIconButtonBox>
                        </Tooltip>

                    }



                </Box>

            </Stack>

            <RenameDialog
                view_id={props.view_id}
                currentName={viewQuery.data?.name || ""}
                isOpen={renameDialogOpen}
                handleDialogClose={() => setRenameDialogOpen(false)} />
        </>

    );
}
