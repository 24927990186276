import AddIcon from '@mui/icons-material/Add';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { alpha, Box, styled } from '@mui/system';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useModelInputOptionsMappingQuery } from "../../api/models";


const FeatureBlocks = styled('div')(({ theme }) => ({
	margin: "12px 12px",
	display: "flex",
	flexDirection: "row",
	padding: 15,
	overflowX: "auto",

}))

const FeatureCard = styled('div')(({ theme }) => ({
	margin: "12px",
	// cursor: "pointer",
	paddingTop: 8,
	paddingBottom: 8,
	paddingLeft: 10,
	paddingRight: 10,
	borderRadius: 5,
	backgroundColor: alpha(theme.palette.card.background, 0.73),
	border: "2px solid",
	borderColor: theme.palette.info.main,
	// // borderRight: "$pt-intent-primary 8px solid",
	minWidth: 200,
	// maxWidth: 350,
	// textAlign: "center",
	// justifyContent: "center",

	// '&.info': {
	//     // padding: "5px 5px",
	//     fontWeight: 700
	// },
	'& .title': {
		color: theme.palette.text.primary,
		// fontSize: 16,
		fontWeight: 500
	},
	'& .subtitle': {
		color: theme.palette.text.secondary,
		// fontSize: 14,
		// fontWeight: 500
	},
	// '&.details': {
	//     color: grey[200],
	//     fontSize: 12
	// }
	// '&.success' {
	//     borderRight: "$pt-intent-success 8px solid"
	// }

	// '&.danger' {
	//     borderRight: "$pt-intent-danger 8px solid"
	// }
}))





export default function ModelFeaturesBlocks() {

	const urlParams = useParams(); // model id
	const model_map_id = parseInt(urlParams.model_map_id !== undefined ? urlParams.model_map_id : "0");
	const modelInputsQuery = useModelInputOptionsMappingQuery(model_map_id);
	const selectedInputs = modelInputsQuery.data?.filter(x => x.is_selected) || [];
	const maxNamelength = 20;

	function FeatureCardNew(props: { id: string, name: string, vendor_name: string }) {

		const trimmedNameString = props.name.length > maxNamelength ? props.name.substring(0, maxNamelength - 3) + "..." : props.name;

		return (
			<FeatureCard id={props.id} >

				<Box sx={{
					display: 'flex',
					alignItems: 'center',
					flexWrap: 'wrap',

				}}>
					<Typography variant="subtitle1" className="title">{trimmedNameString}</Typography>

				</Box>

				<Typography variant="subtitle2" className="subtitle">{props.vendor_name}</Typography>

			</FeatureCard>
		)
	}

	return (
		<FeatureBlocks>
			{selectedInputs?.map((dataset, index) => (

				<div key={dataset.id} style={{ display: 'flex' }} >

					<FeatureCardNew id={"feature-" + index.toString()} name={dataset.name} vendor_name={dataset.vendor_name} />

					<Grid container
						direction="column"
						justifyContent="center"
						alignItems="center">

						{/* when not the last item, then add MoreHorizIcon */}
						{index < selectedInputs.length - 1 &&
							<MoreHorizIcon />
						}

					</Grid>

				</div>

			))}

		</FeatureBlocks>

	);

};