import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';
import apiClient from "../../services/apiClient";
import { NavigateFunction } from 'react-router-dom';





export default function NewDialog(
    props: {
        title: string,
        postApiUrl: string,
        successNavigatetUrl: string,
        navigate: NavigateFunction,
        isOpen: boolean,
        handleDialogClose: () => void,
        onSuccess: () => void
    }) {


    const [name, setName] = useState<string>("");

    const [hasError, setHasError] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>("");

    function onSubmit() {

        setHasError(false);
        setErrorMsg("");

        apiClient.post(props.postApiUrl, {
            name: name
        })
            .then((result) => {
                setHasError(false);
                setErrorMsg("");

                props.onSuccess && props.onSuccess();

                const new_id = result.data['id'];
                props.navigate(`${props.successNavigatetUrl}${new_id}`);
                props.handleDialogClose();


            }).catch((err) => {
                setHasError(true);
                const msg = err?.response?.data?.detail;
                if (msg) {
                    if (Array.isArray(msg)) {
                        setErrorMsg(msg[0].msg);
                    } else {
                        setErrorMsg(msg);
                    }
                }

            })

    }

    return (

        <Dialog open={props.isOpen} onClose={props.handleDialogClose} maxWidth='md'

            PaperProps={{
                sx: {
                    minHeight: 200,
                    backgroundImage: 'none'
                }
            }}
        >
            <DialogTitle>
                {props.title}
            </DialogTitle>

            <DialogContent>

                <TextField autoComplete="off"
                    label="Name" variant="outlined" size="small"
                    sx={{ mt: 2, minWidth: 240 }}
                    helperText={errorMsg}
                    error={hasError}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setName(event.target.value)
                    }
                />

            </DialogContent>
            <DialogActions>
                <Button size="small" variant="text" onClick={() => { props.handleDialogClose(); }}>Cancel</Button>
                <Button size="small" variant="contained" onClick={() => { onSubmit(); }}>Add</Button>
            </DialogActions>
        </Dialog >


    );

}

