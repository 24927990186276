import apiClient from "../services/apiClient";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ISymbol } from "../interfaces";
import { INewAlpha, IUserAlpha } from "./alphas";
import { GridColumns } from '@mui/x-data-grid';

import React, { useState, useEffect } from 'react';

export type StrategyNormType = "zscore" | "rank_norm" | "none";
export type StrategyPositionSizingType = "signal_weighted" | "quantiles";
export type PortfolioType = "long_short" | "long_only";


export interface IStrategy {
	id: number;
	name: string;
	portfolio_type: PortfolioType;
	norm_method: StrategyNormType;
	position_sizing: StrategyPositionSizingType;
	backtest_start_date: string;
	data_lag: number;
	num_quantiles: number;

}

export interface IUpdateStrategy {
	name?: string;
	portfolio_type?: PortfolioType;
	norm_method?: StrategyNormType;
	position_sizing?: StrategyPositionSizingType;
	backtest_start_date?: string;
	data_lag?: number;
	num_quantiles?: number;

}

export interface IStrategyMetrics {
	ann_return: number;
	ann_vol: number;
	sharpe_ratio: number;
	turnover: number;
	inf_coeff: number;
	max_drawdown: number;
}


export interface IStrategyRun {
	id: number;
	strategy_id: number;
	status: "submitted" | "running" | "success" | "failed";
	notes: string;
	created_at: string;
	updated_at: string;
}

// generic key: value records dataset
interface ITableData {
	[key: string]: string | number;
}



type UniverseData = { strategy_id: number, watchlist_id: number, name?: string };


export function useStrategyQuery(strategy_id: number) {

	async function getStrategy() {
		const { data } = await apiClient.get(`/strategy/${strategy_id}`);
		return data
	}

	return useQuery<IStrategy>(['strategy', strategy_id], () => getStrategy())
}


export function useStrategiesQuery() {

	async function getStrategies() {
		const { data } = await apiClient.get(`/strategy/`);
		return data
	}

	return useQuery<IStrategy[]>(['strategies'], () => getStrategies())
}




export function useStrategyPerformanceMetricsQuery(strategy_id: number) {

	async function getStrategyPerformanceMetrics() {
		const { data } = await apiClient.get(`/strategy/performance-metrics/${strategy_id}`);
		return data
	}

	return useQuery<IStrategyMetrics>(['strategy_performance', strategy_id, 'metrics'],
		() => getStrategyPerformanceMetrics(),
		{ refetchOnWindowFocus: false, refetchOnMount: true, staleTime: Infinity }
	)
}



export function useStrategyAlphasQuery(strategy_id: number) {

	async function getStrategyAlphas() {
		const { data } = await apiClient.get(`/strategy/alpha/${strategy_id}`);
		return data
	}

	return useQuery<IUserAlpha[]>(['strategy_alphas', strategy_id], () => getStrategyAlphas(),
		{ refetchOnWindowFocus: false, refetchOnMount: false })

}



export function useStrategyUniverseQuery(strategy_id: number) {
	// get selected universe for this strategy_id (watchlists)
	async function getStrategyUniverse() {
		const { data } = await apiClient.get(`/strategy/universe/${strategy_id}`);
		return data
	}

	return useQuery<UniverseData>(['strategy_universe', strategy_id], () => getStrategyUniverse())
}



export function useStrategyUniverseOptionsQuery() {
	// currently stratgy universe options are only allowed to add watchlists
	async function getStrategyUniverseOptions() {
		const { data } = await apiClient.get(`/watchlist/`)
		return data
	}

	return useQuery<ISymbol[]>(['strategy_universe_options'], () => getStrategyUniverseOptions())
}



export function useAddStrategyUniverse(strategy_id: number) {

	async function addStrategysUniverse(watchlist_id: number) {
		const { data } = await apiClient.post(`/strategy/universe/${strategy_id}/${watchlist_id}`, {})
		return data
	}

	const queryClient = useQueryClient()

	return useMutation((watchlist_id: number) =>
		addStrategysUniverse(watchlist_id),
		{

			onMutate: async watchlist_id => {

				// Cancel any outgoing refetches (so they don't overwrite our optimistic update)
				await queryClient.cancelQueries(['strategy_universe', strategy_id]);

				// Snapshot the previous value
				const previousUniverse = queryClient.getQueryData(['strategy_universe', strategy_id]);

				// Optimistically update to the new value
				queryClient.setQueryData(['strategy_universe', strategy_id], { strategy_id: strategy_id, watchlist_id: watchlist_id })

				// Return a context object with the snapshotted value
				return { previousUniverse }
			},
			onSuccess: () => {
				queryClient.invalidateQueries(['strategy_universe', strategy_id]);
			},

		}
	)

}


export function useAddStrategyAlpha(strategy_id: number) {

	async function addStrategyAlpha(newAlpha: INewAlpha) {
		const { data } = await apiClient.post(`/strategy/alpha/${strategy_id}`, newAlpha);
		return data
	}

	const queryClient = useQueryClient()

	return useMutation((newAlpha: INewAlpha) =>
		addStrategyAlpha(newAlpha),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['strategy_alphas', strategy_id]);

			},

		}
	)

}




export function useRemoveStrategyAlpha(strategy_id: number) {

	async function removeStrategyAlpha(user_alpha_id: number) {
		const { data } = await apiClient.delete(`/strategy/alpha/${strategy_id}/${user_alpha_id}`);
		return data
	}

	const queryClient = useQueryClient()

	return useMutation((user_alpha_id: number) =>
		removeStrategyAlpha(user_alpha_id),
		{

			onMutate: async user_alpha_id => {
				// Cancel any outgoing refetches (so they don't overwrite our optimistic update)
				await queryClient.cancelQueries(['strategy_alphas', strategy_id])

				// Snapshot the previous value
				const previousAlphas = queryClient.getQueryData(['strategy_alphas', strategy_id])

				// Optimistically update to the new value
				queryClient.setQueryData(['strategy_alphas', strategy_id], (old: any) => {
					return old.filter((e: any) => (e.id !== user_alpha_id));
				})

				// Return a context object with the snapshotted value
				return { previousAlphas }
			},

			onSuccess: () => {
				queryClient.invalidateQueries(['strategy_alphas', strategy_id]);
			},

		}
	)

}




export function useUpdateStrategy(strategy_id: number) {

	async function updateStrategy(strategy: IUpdateStrategy) {
		const { data } = await apiClient.put(`/strategy/${strategy_id}`, strategy);
		return data
	}

	const queryClient = useQueryClient()

	return useMutation((strategy: IUpdateStrategy) =>
		updateStrategy(strategy),
		{

			onMutate: async strategy => {
				// Cancel any outgoing refetches (so they don't overwrite our optimistic update)
				await queryClient.cancelQueries(['strategy', strategy_id])

				// Snapshot the previous value
				const previousData = queryClient.getQueryData(['strategy', strategy_id])

				// Optimistically update to the new value
				queryClient.setQueryData(['strategy', strategy_id], (old: any) => {
					return Object.assign(old, strategy);
				})

				// Return a context object with the snapshotted value
				return { previousData }
			},

			onSettled: () => {
				queryClient.invalidateQueries(['strategy', strategy_id]);
			},

		}
	)

}


export function useAlphaCrossCorrelationQuery(strategy_id: number) {

	async function getAlphaCrossCorrelation() {
		const { data } = await apiClient.get(`/strategy/charts/alphas-cross-correlation/${strategy_id}`);
		return data
	}

	return useQuery<{ rows: ITableData[], columns: GridColumns<ITableData> }>(['strategy_performance', strategy_id, 'alpha_corr'],
		() => getAlphaCrossCorrelation(), { refetchOnWindowFocus: false, refetchOnMount: true, staleTime: Infinity })
}



export function useStrategiesInProgressRunsQuery(strategy_id: number) {

	async function getStrategyInProgressRuns() {
		const { data } = await apiClient.get(`/strategy/run/in_progress/${strategy_id}`);
		return data
	}

	return useQuery<IStrategyRun[]>(['strategy_run_in_progress', strategy_id], () => getStrategyInProgressRuns(),
		{ refetchOnWindowFocus: false, refetchOnMount: false, staleTime: Infinity })
}


export function useRunStrategy(
	strategy_id: number,
	{
		onSuccess,
		onError
	}: {
		onSuccess: (data: IStrategyRun) => void,
		onError: (error: any) => void

	}

) {

	const [runId, setRunId] = useState<number>(0);
	const [isRunning, setIsRunning] = useState<boolean>(false);

	const strategiesInProgressRunsQuery = useStrategiesInProgressRunsQuery(strategy_id);

	async function runStrategy() {
		const { data } = await apiClient.post(`/strategy/run/${strategy_id}`);
		return data
	}

	async function getStrategyRunStatus(run_id: number) {
		const { data } = await apiClient.get(`/strategy/run/${strategy_id}/${run_id}`);
		return data
	}

	// POST to run backtest, to start the process
	const runBacktestMutation = useMutation(runStrategy, {
		onMutate: () => {
			setIsRunning(true);
		},
		onError: error => {
			setIsRunning(false);
			onError(error);
		},
		onSuccess: data => {
			setRunId(data.id);
		},
	});


	// Fetch backtest status until received status is finished
	const { isLoading, data } = useQuery<IStrategyRun>(['strategy_run_status', strategy_id, runId], () => getStrategyRunStatus(runId), {
		onSuccess: data => {
			if (data.status === 'success') {
				setIsRunning(false);
				setRunId(0);
				onSuccess(data);
			} else if (data.status === 'failed') {
				setIsRunning(false);
				setRunId(0);
				onError(data);
			}
		},
		onError: error => {
			setIsRunning(false);
			setRunId(0);
			onError(error);
		},
		// don't enable pinging, until we first check if there is an in progress run
		enabled: runId > 0 && isRunning && !strategiesInProgressRunsQuery.isLoading && !strategiesInProgressRunsQuery.isFetching,
		refetchInterval: !isRunning ? false : 4000, // 4 seconds
		refetchIntervalInBackground: true,
		refetchOnWindowFocus: false,
		retry: 100, // override default to not retry on 404
	});

	// if there is an in progress run, default the run Id to continue pinging
	useEffect(() => {

		if (strategiesInProgressRunsQuery.data && strategiesInProgressRunsQuery.data.length > 0) {
			setRunId(strategiesInProgressRunsQuery.data[0].id);
			setIsRunning(true);
		}

	}, [strategy_id, strategiesInProgressRunsQuery.data]);


	return { runBacktestMutation, data, isRunning };

}



export function useStrategyLastSuccessRunQuery(strategy_id: number) {

	async function getStrategyLastSuccessRun() {
		const { data } = await apiClient.get(`/strategy/run/success/${strategy_id}`);
		return data
	}

	return useQuery<IStrategyRun>(['strategy_run_success', strategy_id], () => getStrategyLastSuccessRun(),
		{ refetchOnWindowFocus: false, refetchOnMount: false, staleTime: Infinity })
}
