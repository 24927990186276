import { Box, styled } from '@mui/system';
import React from 'react';
import Grid from '@mui/material/Grid';
import { ViewTickerChart, ViewCompareChart, ViewTable, EarningsCalendar, QTDChart } from "../components";
import Button from '@mui/material/Button';
import ModelResultsTable from '../components/Model/ModelResultsTable';
import {
	useDashboardComponentsQuery, useUpdateDashboardComponentOrder, useUpdateDashboardComponentSize,
	useDashboardComponentOptionsQuery, useAddDashboardComponent, useRemoveDashboardComponent,
} from '../api/dashboard';
import LinearProgress from '@mui/material/LinearProgress';

import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import IconButton from '@mui/material/IconButton';
import { Typography } from '@mui/material';
import ShowChartSharpIcon from '@mui/icons-material/ShowChartSharp';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Popover from '@mui/material/Popover';
import MenuList from '@mui/material/MenuList';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import CircularProgress from '@mui/material/CircularProgress';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TableChartIcon from '@mui/icons-material/TableChart';
import InsightsIcon from '@mui/icons-material/Insights';
import Check from '@mui/icons-material/Check';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import TimelineIcon from '@mui/icons-material/Timeline';
import { useWatchlistsQuery } from "../api/watchlist";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useUserSelectionQuery, useUpdateUserSelection } from "../api/user_selection";


const CardItem = styled("div")(({ theme }) => ({
	// height: 550,
	height: "calc(50vh - 90px)",
	padding: theme.spacing(2),
	backgroundColor: theme.palette.card.background,
	border: "1px solid",
	borderColor: theme.palette.divider,
	borderRadius: 2,
}))


function getTypeTitle(type: string) {
	// replace underscore with space and make title case
	return type.replace(/_/g, " ").replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
}

function ComponentIcon(props: { name: string }) {
	let Icon = ShowChartSharpIcon; // default if unknown

	switch (props.name) {
		case "model_results_table":
			Icon = TableChartIcon;
			break;
		case "earnings_calendar":
			Icon = CalendarMonthIcon;
			break;
		case "model_quarterly_predictions":
			Icon = TimelineIcon;
			break;
		case "model_data_quarter_to_date":
			Icon = ShowChartSharpIcon;
			break;
		default:
			// throw Error('Invalid month');
			Icon = ShowChartSharpIcon;
	}
	return <Icon fontSize="small" />;

}


function AddButton(props: {
	// onClick: () => void
}) {

	const dashboardComponentOptionsQuery = useDashboardComponentOptionsQuery();
	const addDashboardComponent = useAddDashboardComponent();

	const viewOptions = dashboardComponentOptionsQuery.data?.filter((component) => component.type === "view");
	const standardOptions = dashboardComponentOptionsQuery.data?.filter((component) => component.type !== "view");

	// for popper
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	return (
		<>
			<Button variant="outlined" color="info" onClick={handleClick} startIcon={<DashboardCustomizeIcon />} >
				New Component
			</Button>

			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				<Paper sx={{
					minWidth: 340,
					border: '1px solid',
					borderColor: (theme) => theme.palette.divider,
				}}>
					<MenuList dense>
						{standardOptions && standardOptions.map((component, index) => {
							return (
								<MenuItem
									disabled={component.is_selected}
									key={index}
									onClick={() => {
										addDashboardComponent.mutate({ type_id: component.type_id });
										handleClose();
									}}
								>
									<ListItemIcon>
										<ComponentIcon name={component.type} />
									</ListItemIcon>
									<ListItemText>{getTypeTitle(component.type)}</ListItemText>
									{component.is_selected && <Check fontSize="small" />}

								</MenuItem>
							);

						})}

						<Divider />

						{viewOptions && viewOptions.map((component, index) => {
							return (
								<MenuItem
									disabled={component.is_selected}
									key={index}
									onClick={() => {
										addDashboardComponent.mutate({ type_id: component.type_id, view_id: component.view_id });
										handleClose();
									}}
								>
									<ListItemIcon>
										<InsightsIcon fontSize="small" color="warning" />
									</ListItemIcon>
									<ListItemText>{component.view_name}</ListItemText>

									{component.is_selected ? <Check fontSize="small" /> :
										<Typography sx={{ fontSize: 11, color: (theme) => theme.palette.warning.light }}>View</Typography>
									}


								</MenuItem>
							);

						})}


					</MenuList>
				</Paper>
			</Popover>
		</>
	)
}


function ConfigButton(props: {
	component_id: number,
	isFirst: boolean,
	isLast: boolean,
	isMaxSize: boolean,
	isMinSize: boolean,
}) {

	// mutations for component config
	const updateDashboardComponentOrder = useUpdateDashboardComponentOrder(props.component_id)
	const updateDashboardComponentSize = useUpdateDashboardComponentSize(props.component_id)
	const removeDashboardComponent = useRemoveDashboardComponent()

	// for popper
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	const isLoading = updateDashboardComponentOrder.isLoading || updateDashboardComponentSize.isLoading;

	if (isLoading) {
		return (
			<CircularProgress size={18} color="primary" />
		)
	}

	return (

		<>

			<IconButton size="small" onClick={handleClick}
			// sx={{ ml: 2 }}
			>
				<MoreVertIcon color="disabled" />
			</IconButton>

			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
			>
				<Paper sx={{
					border: '1px solid',
					borderColor: (theme) => theme.palette.divider,
				}}>
					<MenuList dense>

						<MenuItem disabled={props.isFirst} onClick={() => {
							updateDashboardComponentOrder.mutate(-1);
							handleClose();
						}}>
							<ListItemIcon>
								<MoveUpIcon fontSize="small" />
							</ListItemIcon>
							<ListItemText sx={{ color: "text.disabled" }}>Move Up</ListItemText>
						</MenuItem>

						<MenuItem disabled={props.isLast} onClick={() => {
							updateDashboardComponentOrder.mutate(1);
							handleClose();
						}}>
							<ListItemIcon>
								<MoveDownIcon fontSize="small" />
							</ListItemIcon>
							<ListItemText sx={{ color: "text.disabled" }}>Move Down</ListItemText>
						</MenuItem>

						<Divider />

						<MenuItem disabled={props.isMaxSize} onClick={() => {
							updateDashboardComponentSize.mutate(1);
							handleClose();
						}}>
							<ListItemIcon>
								<UnfoldMoreIcon sx={{ transform: "rotateZ(90deg)" }}
									fontSize="small" />
							</ListItemIcon>
							<ListItemText sx={{ color: "text.disabled" }}>Larger</ListItemText>
						</MenuItem>

						<MenuItem disabled={props.isMinSize} onClick={() => {
							updateDashboardComponentSize.mutate(-1);
							handleClose();
						}}>
							<ListItemIcon>
								<UnfoldLessIcon sx={{ transform: "rotateZ(90deg)", }} fontSize="small" />
							</ListItemIcon>
							<ListItemText sx={{ color: "text.disabled" }}>Smaller</ListItemText>
						</MenuItem>

						<Divider />

						<MenuItem onClick={() => {
							removeDashboardComponent.mutate(props.component_id);
							handleClose();
						}}>
							<ListItemIcon>
								<DeleteOutlineIcon fontSize="small" />
							</ListItemIcon>
							<ListItemText sx={{ color: "text.disabled" }}>Remove</ListItemText>
						</MenuItem>



					</MenuList>
				</Paper>
			</Popover>
		</>
	)
}


function ControlSelect(props: { watchlist_id: number }) {

	const watchlistsQuery = useWatchlistsQuery();
	const watchlistOptions = watchlistsQuery.data ? [{ id: 0, name: 'All Tickers' }].concat(watchlistsQuery.data) : [];

	const updateSelection = useUpdateUserSelection();

	return (
		<>
			{
				(watchlistOptions && watchlistOptions.length > 0) &&
				<Autocomplete
					id="dashboard_watchlist"
					size="small"
					disableClearable
					options={watchlistOptions}
					getOptionLabel={(option) => option.name}
					value={watchlistOptions.find((option) => option.id === props.watchlist_id) || watchlistOptions[0]}
					onChange={(event, newValue) => {

						const selection = { dashboard_watchlist: newValue.id };
						updateSelection.mutate(selection);

					}}

					isOptionEqualToValue={(option, value) => value.id === option.id}
					ChipProps={{ deleteIcon: <></> }}
					sx={{ width: 200 }}
					renderInput={(params) => (
						<>
							<TextField
								{...params}
								label="Watchlist"
								color="info"
							/>
						</>

					)}

				/>
			}

		</>

	);


}




export default function Dashboard() {

	const dashboardComponentsQuery = useDashboardComponentsQuery();
	const userSelectionQuery = useUserSelectionQuery(); // get saved user selection for watchlist

	if (userSelectionQuery.data === undefined || userSelectionQuery.isLoading || dashboardComponentsQuery.isLoading) {
		return (
			<Box sx={{ width: '100%' }}>
				<LinearProgress />
			</Box>
		);
	}

	const watchlist_id: number = userSelectionQuery.data['dashboard_watchlist'] || 0;

	return (


		<Box flexGrow={1} sx={{
			marginTop: 2,
			marginBottom: 2,
			marginLeft: 2,
			marginRight: 2,
		}}>
			<Grid container
				spacing={2}>

				{/* top toolbar */}
				<Grid item xs={12}>
					<Stack direction="row" justifyContent="space-between">
						<AddButton />
						<ControlSelect watchlist_id={watchlist_id} />
					</Stack>
				</Grid>


				{dashboardComponentsQuery.data?.map((component, index) => {

					const ToolbarComponent =
						<ConfigButton
							component_id={component.id}
							isFirst={index === 0}
							isLast={index === dashboardComponentsQuery.data?.length - 1}
							isMaxSize={component.size === 12}
							isMinSize={component.size === 3}
						/>;

					return (
						<Grid item xs={component.size} key={component.id}>
							<CardItem>

								{(component.type === "view" && component.view_id && ["list", "compact"].includes(component.view_display_type || "list")
								) && <ViewTickerChart view_id={component.view_id}
									title={component.view_name || ""}
									ToolbarComponent={ToolbarComponent} compact={true} />}


								{(component.type === "view" && component.view_id && component.view_display_type === "compare")
									&& <ViewCompareChart view_id={component.view_id}
										title={component.view_name || ""}
										ToolbarComponent={ToolbarComponent} compact={true} />}

								{(component.type === "view" && component.view_id && component.view_display_type === "table")
									&& <ViewTable view_id={component.view_id} selectedInputs={[]} showTitle={true}
										ToolbarComponent={ToolbarComponent}
										compact={true}
									/>}

								{(component.type === "model_results_table") && <Box height="92%"><ModelResultsTable density="compact" title="Company KPI Forecasts" ToolbarComponent={ToolbarComponent} watchlist_id={watchlist_id} /></Box>}
								{component.type === "earnings_calendar" && <EarningsCalendar ToolbarComponent={ToolbarComponent} watchlist_id={watchlist_id} />}
								{(component.type === "model_data_quarter_to_date") && <QTDChart ToolbarComponent={ToolbarComponent} watchlist_id={watchlist_id} />}

							</CardItem>
						</Grid>
					)
				})}

			</Grid>

		</Box>


	);

};
