import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { AxiosError } from 'axios'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import React, { useState, useEffect } from 'react';
import { green, red } from '@mui/material/colors';
import apiClient from "../../services/apiClient";
import { useParams } from 'react-router-dom';
import { ChartType, FormatType, ICellFormat } from "../../interfaces"
import { useViewTableFormatOptionsQuery } from "../../api/views"

function parseValue(value: string | number | null | undefined) {

    if (value === 0) {
        // if 0, we dont want to return "", should return 0
        return "0";
    }

    if (!value) {
        // null or undefined
        return "";
    }

    return String(value);

}


function FormatExampleBox(props: {
    color: string;
    variant: "outlined" | "filled"
}) {

    return (
        <Box display="flex" sx={{
            alignItems: "center", justifyContent: "center", borderRadius: 1,
            color: props.variant === "filled" ? "white" : props.color,
            backgroundColor: props.variant === "filled" ? props.color : "inherit",
            width: 50,
            border: "1px solid", borderColor: (theme) => theme.palette.action.selected
        }}>
            <Typography fontWeight={600} variant="body2" color="inherit" component="p">X.X</Typography>
        </Box>
    )
}


function ColumnSelect(props: {
    name: string,
    view_input_id: number,
    cellFormat?: ICellFormat,
    setCellFormat: (format: ICellFormat | null) => void

}) {

    let defaultSelect: FormatType = props.cellFormat?.format || "none";
    const [selectType, setSelectType] = useState<FormatType>(defaultSelect);

    return (

        <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
        >

            <Typography>{props.name} :</Typography>


            <Select
                value={selectType}
                onChange={(event: SelectChangeEvent) => {
                    setSelectType(event.target.value as FormatType);
                    // Clear all params
                    // props.setCellFormat(null);
                    props.setCellFormat({ format: "none", view_input_id: props.view_input_id });

                }}
                size="small"
            >

                <MenuItem value="none">None</MenuItem>
                {/* <MenuItem value="chart">Chart</MenuItem> */}
                <MenuItem value="highlight">Highlight</MenuItem>

            </Select>

            {
                (selectType === "chart") ?
                    <Select
                        value={props?.cellFormat?.format_chart_params?.chart_type || ""}
                        onChange={(event: SelectChangeEvent) => {
                            props.setCellFormat({ format: "chart", format_chart_params: { chart_type: event.target.value as ChartType }, view_input_id: props.view_input_id })
                        }}
                        size="small"
                        displayEmpty
                    >
                        <MenuItem disabled value="">
                            <em>Select Chart Type</em>
                        </MenuItem>

                        <MenuItem value={"line"}>Line</MenuItem>
                        <MenuItem value={"bar"}>Bar</MenuItem>
                    </Select>

                    :

                    (selectType === "highlight") ?
                        <Box display={"flex"} flexDirection={"column"}>
                            <Stack direction="row" spacing={1}>

                                <TextField size="small" sx={{ width: 140 }} variant="outlined" placeholder="lower"
                                    autoComplete="off"
                                    value={parseValue(props.cellFormat?.format_highlight_params?.lower_outlier)}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        const prevHighlightParams = props.cellFormat?.format_highlight_params;
                                        props.setCellFormat({
                                            format: "highlight",
                                            view_input_id: props.view_input_id,
                                            format_highlight_params: { ...prevHighlightParams, lower_outlier: event.target.value }
                                        })
                                    }}
                                    InputProps={{
                                        startAdornment: <InputAdornment disableTypography position="start">
                                            <FormatExampleBox color={red[400]} variant="filled" />
                                        </InputAdornment>,
                                    }}

                                />

                                <TextField size="small" sx={{ width: 140 }} variant="outlined" placeholder="lower"
                                    autoComplete="off"
                                    value={parseValue(props.cellFormat?.format_highlight_params?.lower)}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        const prevHighlightParams = props.cellFormat?.format_highlight_params;
                                        props.setCellFormat({
                                            format: "highlight",
                                            view_input_id: props.view_input_id,
                                            format_highlight_params: { ...prevHighlightParams, lower: event.target.value }
                                        })
                                    }}
                                    InputProps={{
                                        startAdornment: <InputAdornment disableTypography position="start">
                                            <FormatExampleBox color={red[400]} variant="outlined" />
                                        </InputAdornment>,
                                    }}

                                />

                            </Stack>

                            <Stack direction="row" spacing={1}>

                                <TextField size="small" sx={{ width: 140 }} variant="outlined" placeholder="upper"
                                    autoComplete="off"
                                    value={parseValue(props.cellFormat?.format_highlight_params?.upper)}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        const prevHighlightParams = props.cellFormat?.format_highlight_params;
                                        props.setCellFormat({
                                            format: "highlight",
                                            view_input_id: props.view_input_id,
                                            format_highlight_params: { ...prevHighlightParams, upper: event.target.value }
                                        })
                                    }}
                                    InputProps={{
                                        startAdornment: <InputAdornment disableTypography position="start">
                                            <FormatExampleBox color={green[400]} variant="outlined" />
                                        </InputAdornment>,
                                    }}

                                />

                                <TextField size="small" sx={{ width: 140 }} variant="outlined" placeholder="upper"
                                    autoComplete="off"
                                    value={parseValue(props.cellFormat?.format_highlight_params?.upper_outlier)}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        const prevHighlightParams = props.cellFormat?.format_highlight_params;
                                        props.setCellFormat({
                                            format: "highlight",
                                            view_input_id: props.view_input_id,
                                            format_highlight_params: { ...prevHighlightParams, upper_outlier: event.target.value }
                                        })
                                    }}
                                    InputProps={{
                                        startAdornment: <InputAdornment disableTypography position="start">
                                            <FormatExampleBox color={green[400]} variant="filled" />
                                        </InputAdornment>,
                                    }}

                                />
                            </Stack>
                        </Box>

                        : <></>

            }

        </Stack>


    )
}


export default function ViewTableFormatDialog(
    props: {
        datasets: { id: number, name: string }[],
        isOpen: boolean,
        onAddSuccess: () => void,
        handleClose: () => void,
        setLoading: (val: boolean) => void
    }) {

    const [hasError, setHasError] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>("");

    const urlParams = useParams(); // view id
    const view_id = parseInt(urlParams.view_id !== undefined ? urlParams.view_id : "0");

    const viewTableFormatOptionsQuery = useViewTableFormatOptionsQuery(view_id);

    // select
    const [cellFormat, setCellFormat] = useState<ICellFormat[]>([]);

    // get any saved table options from DB
    useEffect(() => {

        if (viewTableFormatOptionsQuery.data) {
            setCellFormat(viewTableFormatOptionsQuery.data);
        }

    }, [viewTableFormatOptionsQuery.data]);


    function handleSubmit() {

        setHasError(false);
        setErrorMsg("");

        if (cellFormat.length === 0) {
            props.handleClose();
            return;
        }

        props.setLoading(true);

        apiClient.post(`/views/table-options/${view_id}`, cellFormat)
            .then((result) => {

                setHasError(false);
                setErrorMsg("");

                const resultData = result.data;

                props.onAddSuccess();
                props.handleClose();

            }).catch((err) => {
                setHasError(true);
                const msg = err?.data?.detail;
                if (msg) {
                    if (Array.isArray(msg)) {
                        setErrorMsg(msg[0].msg);
                    } else {
                        setErrorMsg(msg);
                    }
                }


            }).finally(() => {
                props.setLoading(false);
            })
    }





    return (

        <Dialog open={props.isOpen} onClose={props.handleClose} fullWidth maxWidth='md'

            PaperProps={{
                sx: {
                    minHeight: 300,
                    backgroundImage: 'none',
                    border: "1px solid rgba(56, 56, 56, 1)",

                }
            }}
        >
            <DialogTitle> Cell Formatting
            </DialogTitle>

            <DialogContent>

                {hasError ? <Typography color="error">{errorMsg}</Typography> : <></>}

                <Box sx={{ mt: 2, }} display={"flex"}>

                    <Stack direction="column" spacing={4} alignItems="flex-start">


                        {props.datasets.map((dataset) => {
                            return (

                                <ColumnSelect
                                    key={String(dataset.id)}
                                    view_input_id={dataset.id}
                                    name={dataset.name}
                                    cellFormat={cellFormat.find((i) => i.view_input_id === dataset.id)}
                                    setCellFormat={(format: ICellFormat | null) => {

                                        if (format !== null) {
                                            setCellFormat(prevState => [...prevState.filter(i => i.view_input_id !== dataset.id), {
                                                view_input_id: dataset.id,
                                                format: format.format,
                                                format_highlight_params: format.format_highlight_params,
                                                format_chart_params: format.format_chart_params,
                                            }]);
                                        } else {
                                            setCellFormat(prevState => prevState.filter(i => i.view_input_id !== dataset.id));
                                        }

                                    }}

                                />
                            )
                        })}


                    </Stack>

                </Box>

            </DialogContent>
            <DialogActions>
                <Button size="small" variant="text" onClick={(event) => {
                    props.handleClose();
                }}>Cancel</Button>
                <Button size="small" variant="contained" onClick={(event) => {
                    handleSubmit();
                }}>Save</Button>
            </DialogActions>
        </Dialog>


    );

}

