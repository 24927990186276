import React from 'react';
import Typography from '@mui/material/Typography';

export function DataInputDisplayName(props: { name: string }) {

    if (!props.name || props.name.length === 0) {
        return <></>;
    }

    return (
        <div>
            {props.name.split(':').map((line, i) => {

                return (
                    <Typography key={i} sx={{ fontWeight: 500, fontSize: 13 }} color={i === 0 ? "text.primary" : "text.secondary"} >
                        {line.trim()}
                    </Typography>
                );
            })
            }
        </div>

    );

}