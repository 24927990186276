import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { orange } from '@mui/material/colors';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { alpha, Box, useTheme } from '@mui/system';
import React from 'react';
import { useMetricSensitivityQuery } from "../../api/models"



export default function MetricSensitivityCard(props: { metric_id: number }) {

	const theme = useTheme();
	const metricSensitivityQuery = useMetricSensitivityQuery(props.metric_id)
	const metric_ses = metricSensitivityQuery.data;

	if (props.metric_id === 0 || !metric_ses) {
		return (
			<></>
		)
	}


	let color: string = orange[500];
	let text: string = 'MED';

	if (metric_ses > 66) {
		color = '#4caf50';
		text = 'HIGH';
	} else if (metric_ses < 33) {
		color = '#ef5350';
		text = 'LOW';
	}



	return (
		<Box display="flex" flexDirection="row" sx={{
			height: 100, mt: 2, // note this computes to some margin ?? Don't remove.
		}}>

			<Card sx={{
				backgroundColor: (theme) => theme.palette.card.background,
				border: "1px solid",
				borderColor: theme.palette.divider,
				borderRadius: "2px",
				marginRight: 2,
			}}>
				<CardContent>

					<Stack direction="row" alignItems="center">

						<Box >


							<Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 1 }}>

								<Typography fontSize={16} >
									Metric Sensitivity:
								</Typography>
								<Typography color={color} fontSize={18} fontWeight={500} >
									{metric_ses}
								</Typography>
							</Stack>

							<Stack direction="row" alignItems="center">

								<Box sx={{
									backgroundColor: alpha(color, 0.50),
									width: "100%",
									borderRadius: 0.5,
									paddingLeft: 1,
									paddingRight: 1,
									paddingTop: 0.4,
									paddingBottom: 0.4,
									display: "flex",
									justifyContent: "center",

								}}>

									<Typography fontWeight={500} fontSize={13}>{text}</Typography>
								</Box>

							</Stack>

						</Box>

					</Stack>

				</CardContent>

			</Card>

		</Box>



	)
}
