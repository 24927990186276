import apiClient from "../services/apiClient";
import moment, { Moment } from 'moment';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { IAlertTriggered, IAlert } from "../interfaces/alerts"


type EarningsCount = { [key: string]: number }; // date: count

export interface IEarningsInfo {
	company_id: number;
	symbol: string;
	date: string;
	time: string;
	status?: string;
}

interface IDashboardComponentDetails {
	id: number;
	size: number;
	order: number;
	owner_id: number;
	type_id: number;
	type: string;
	view_id?: number | null;
	view_name?: string | null;
	view_display_type?: "list" | "compact" | "table" | "compare";
}


interface IDashboardComponentOption {
	type_id: number;
	type: string;
	view_id?: number | null
	view_name?: string | null
	is_selected: boolean;
}

interface INewComponent {
	type_id: number;
	view_id?: number | null;
}


export function useDashboardComponentsQuery() {

	async function getDashboardComponents() {
		const { data } = await apiClient.get('/dashboard/components')
		return data
	}

	return useQuery<IDashboardComponentDetails[]>(['dashboard_components'], getDashboardComponents);
}

export function useDashboardComponentOptionsQuery() {

	async function getDashboardComponentOptions() {
		const { data } = await apiClient.get('/dashboard/components/options')
		return data
	}

	return useQuery<IDashboardComponentOption[]>(['dashboard_components_options'], getDashboardComponentOptions);
}



export function useEarningsCountQuery(watchlist_id: number, year?: number, month?: number) {

	async function getEarningsCount() {
		const { data } = await apiClient.get(`/dashboard/earnings/count/${watchlist_id}/${year}/${month}`)
		return data
	}

	return useQuery<EarningsCount>(['earnings', 'count', watchlist_id, year, month], getEarningsCount);
}

export function useEarningsDetailsQuery(watchlist_id: number, date: Moment) {

	async function getEarningsInfo() {
		const { data } = await apiClient.get(`/dashboard/earnings/details/${watchlist_id}/${date.format('YYYY-MM-DD')}`)
		return data
	}

	return useQuery<IEarningsInfo[]>(['earnings', "details", watchlist_id, date], getEarningsInfo);
}


export function useUpdateDashboardComponentOrder(component_id: number) {

	async function updateDashboardComponentOrder(change: 1 | -1) {
		const { data } = await apiClient.put(`/dashboard/components/order/${component_id}/${change}`);
		return data
	}

	const queryClient = useQueryClient()

	return useMutation((change: 1 | -1) =>
		updateDashboardComponentOrder(change),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['dashboard_components']);
			},

		}
	)

}
export function useUpdateDashboardComponentSize(component_id: number) {

	async function updateDashboardComponentSize(change: 1 | -1) {
		const { data } = await apiClient.put(`/dashboard/components/size/${component_id}/${change}`);
		return data
	}

	const queryClient = useQueryClient()

	return useMutation((change: 1 | -1) =>
		updateDashboardComponentSize(change),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['dashboard_components']);
			},

		}
	)

}

export function useAddDashboardComponent() {

	async function addDashboardComponent(newComponent: INewComponent) {
		const { data } = await apiClient.post('/dashboard/components', newComponent);
		return data
	}

	const queryClient = useQueryClient()

	return useMutation((newComponent: INewComponent) =>
		addDashboardComponent(newComponent),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['dashboard_components']);
				queryClient.invalidateQueries(['dashboard_components_options']);
			},

		}
	)

}
export function useRemoveDashboardComponent() {

	async function removeDashboardComponent(component_id: number) {
		const { data } = await apiClient.delete(`/dashboard/components/${component_id}`);
		return data
	}

	const queryClient = useQueryClient()

	return useMutation((component_id: number) =>
		removeDashboardComponent(component_id),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['dashboard_components']);
				queryClient.invalidateQueries(['dashboard_components_options']);
			},

		}
	)

}