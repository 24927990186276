import React, { FC } from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { Box, styled } from '@mui/system';
import { Navigate } from 'react-router-dom';
import logo_with_text_white from '../assets/logo_with_text_white.png';
import LoginAuth0Button from '../components/Login/LoginAuth0Button';
import { useAuth0 } from "@auth0/auth0-react";
import LinearProgress from '@mui/material/LinearProgress';


const FlexBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
}))

const JustifyBox = styled(FlexBox)(() => ({
    justifyContent: 'center',
}))


const JWTRoot = styled(JustifyBox)(() => ({
    minHeight: "100vh",
    '& .card': {
        maxWidth: 800,
        minWidth: 450,
        borderRadius: 4,
        padding: "20px 2px",
    },

}))


export const Login: FC = () => {

    const { isAuthenticated, isLoading } = useAuth0();

    if (isLoading) {
        return (
            <Box sx={{ width: '100%' }}>
                <LinearProgress />
            </Box>
        )
    }

    if (isAuthenticated) {
        return (<Navigate to="/dashboard" />)
    }

    return (

        <JWTRoot>
            <Card className="card">
                <Grid container justifyContent="center" direction="column" spacing={2}>


                    <Grid item lg={6} md={6} sm={6} xs={12}>

                        <Stack direction="column"
                            justifyContent="center"
                            alignItems="center"
                            spacing={3}>

                            <Box
                                component="img"
                                sx={{ height: 100, mb: 1 }}
                                alt="Logo"
                                src={logo_with_text_white}
                            />
                            <LoginAuth0Button />

                        </Stack>

                    </Grid>

                </Grid>
            </Card>
        </JWTRoot >

    );
};