import apiClient from "../services/apiClient";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ISymbol } from "../interfaces";
import { ICreateInput } from "../interfaces";


interface IVendorPermission {
	vendor_id: number;
	user_id: number;
}

export function useAddVendorAccess() {

	async function addVendorAccess(permission: IVendorPermission) {
		const { data } = await apiClient.post(`/vendor/permissions/${permission.vendor_id}/${permission.user_id}`, {})
		return data
	}

	const queryClient = useQueryClient()

	return useMutation((permission: IVendorPermission) =>
		addVendorAccess(permission),
		{

			// onSuccess: () => {
			// 	queryClient.invalidateQueries(['strategy_universe', strategy_id]);
			// },

		}
	)

}


export function useRemoveVendorAccess() {

	async function removeVendorAccess(permission: IVendorPermission) {
		const { data } = await apiClient.delete(`/vendor/permissions/${permission.vendor_id}/${permission.user_id}`, {})
		return data
	}

	const queryClient = useQueryClient()

	return useMutation((permission: IVendorPermission) =>
		removeVendorAccess(permission),
		{

			// onSuccess: () => {
			// 	queryClient.invalidateQueries(['strategy_universe', strategy_id]);
			// },

		}
	)

}