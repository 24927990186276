import React, { useState } from 'react';
import { alpha, Box, styled } from '@mui/system'
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { red, green } from '@mui/material/colors';
import { FeatureCardNew, FeatureBlocks } from '../../components/Toolbar/DataBlocks'
import { IUserAlpha } from "../../api/alphas"

export default function StrategyFactorBlocks(props: {
	alphas?: IUserAlpha[],
	disabled?: boolean,
	handleRemove: (id: number) => void,
	setIsAddDialogOpen: (value: boolean) => void
}) {

	return (

		<Stack
			direction="row"
			// justifyContent="flex-start"
			alignItems="center"
			spacing={2}
			sx={{ ml: 2, mb: 2 }}

		>
			<Typography variant="subtitle1" sx={{ color: 'text.primary', fontWeight: 500 }}>
				Alphas:
			</Typography>


			<FeatureBlocks
			>
				{props.alphas && props.alphas.map((alpha, index) => (

					<div key={alpha.id}  >

						<FeatureCardNew
							id={alpha.id}
							name={alpha.name}
							color={alpha.factor_sign > 0 ? green[300] : red[300]}
							handleRemove={props.handleRemove}
							disableRemove={props.disabled || false}
						/>

					</div>

				))}


				<Button disabled={props.disabled || false} sx={{ ml: 2, mr: 2 }} variant="outlined" color="info" onClick={() => props.setIsAddDialogOpen(true)}  >
					Add Factor
				</Button>

			</FeatureBlocks>

		</Stack >

	);

};


