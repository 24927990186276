import React, { useState } from 'react';
import apiClient from "../../services/apiClient";
import { useQuery, QueryKey } from '@tanstack/react-query';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { DataGrid, GridColumns, GridToolbarExport, GridDensity, GridToolbarContainer, GridToolbarQuickFilter, GridRenderCellParams } from '@mui/x-data-grid';
import ChartSelect from '../Charts/ChartSelect'
import LinearProgress from '@mui/material/LinearProgress';
import { IChartOption } from "../../interfaces";
import ExportCSVButton from '../DataExport/ExportCSVButton';


// generic key: value records dataset
interface ITableData {
	[key: string]: string | number;
}


function useDataQuery(dataUrl: string, queryKey: QueryKey, params: URLSearchParams) {

	const queryKeyCombined = [...queryKey, Object.fromEntries(params)];

	async function getData() {

		const { data } = await apiClient.get(
			dataUrl,
			{
				params: params
			}
		);


		return data
	}

	return useQuery<{ rows: ITableData[], columns: GridColumns<ITableData> }>(queryKeyCombined, () => getData(),
		{ refetchOnWindowFocus: false, refetchOnMount: true, staleTime: Infinity });

}




export default function DataTable(props: {
	title: string,
	dataUrl: string,
	queryKey: QueryKey,
	disableColumnMenu: boolean,
	filterable: boolean,
	renderCell: (params: GridRenderCellParams) => JSX.Element,
	hideFooter?: boolean,
	chartSelectParams?: IChartOption[],
	tableDensity?: GridDensity,
	ToolbarComponent?: JSX.Element,
	showToolbar?: boolean,
	showExportButton?: boolean,
	exportFileName?: string

}) {

	const chartSelectParams = props.chartSelectParams || []; // default prop to empty list to map below.
	const hideFooter = props.hideFooter === undefined ? true : props.hideFooter; // default prop to true

	// param option for view displaying charts
	// get default params to default state
	var defaults: { [key: string]: string } = {};
	chartSelectParams.forEach((param: IChartOption) => {
		defaults[param.name] = param.defaultValue;
	});

	const [params, setParams] = useState<URLSearchParams>(new URLSearchParams({ ...defaults }));

	const query = useDataQuery(props.dataUrl, props.queryKey, params);

	if (query.isLoading || query.isFetching) {
		return (
			<Box sx={{ width: '100%' }}>
				<LinearProgress />
			</Box>
		);
	}

	const tableCols: (GridColumns<ITableData> | undefined) = query.data?.columns.map((x: any, i: any) => ({
		...x,
		flex: x.field === "name" ? 1 : 1,
		renderCell: props.renderCell,
		disableColumnMenu: true,
		filterable: false
	}));


	function CustomToolbar() {
		return (
			<GridToolbarContainer>
				<GridToolbarQuickFilter
					debounceMs={500}
					// variant="outlined"
					placeholder="Search all tickers and metrics ..."
					fullWidth={true}
					sx={{ width: "40%" }}
					autoComplete='off'
				/>

				<Box sx={{ marginLeft: "auto" }}>
					<GridToolbarExport size="medium" printOptions={{ disableToolbarButton: true }} csvOptions={{ fileName: props.exportFileName }} />
				</Box>

			</GridToolbarContainer>
		);
	}


	return (
		<Box
			sx={{
				height: "85%",
			}}>

			<Stack direction="row" sx={{ mb: 2 }}>

				<Typography>{props.title}</Typography>

				<Box sx={{ marginLeft: "auto" }}>

					{
						chartSelectParams.map((select: IChartOption, index) => {
							return (
								<ChartSelect
									key={index}
									menuTitle={select.title}
									options={select.options}
									value={params.get(select.name) || ""}
									setValue={(val) => {
										params.set(select.name, val);
										setParams((new URLSearchParams(params)));

										select.onChange && select.onChange(val);

									}}
								/>
							);
						})
					}

					{props.ToolbarComponent && props.ToolbarComponent}

					{(props.showExportButton && !props.showToolbar) &&
						<ExportCSVButton
							fileName={props.exportFileName ? props.exportFileName : props.title}
							rows={query.data?.rows || []}
							columns={tableCols || []}
						/>
					}

				</Box>


			</Stack>


			<DataGrid
				rows={query.data?.rows || []}
				columns={tableCols || []}
				hideFooter={hideFooter}
				density={props.tableDensity || "compact"}
				loading={query.isLoading || query.isFetching}
				disableSelectionOnClick={true}
				components={{
					Toolbar: props.showToolbar ? CustomToolbar : undefined

				}}
				sx={{
					'& .MuiDataGrid-cell:focus-within': {
						outline: 'none'
					},
				}}

			/>


		</Box>
	);

}