import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

export default function Home() {

    const { isAuthenticated, isLoading } = useAuth0();

    if (isLoading) {
        return null
    }

    if (isAuthenticated) {
        return (<Navigate to="/dashboard" />)
    }

    return (<Navigate to="/login" />)
};