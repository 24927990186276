import React from 'react';
import { alpha, Box, styled } from '@mui/system'
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { getIndexColor } from "../Charts/utils"
import CloseIcon from '@mui/icons-material/Close';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import { useDrop } from 'react-dnd';
import { FeatureCardNew, FeatureBlocks } from '../../components/Toolbar/DataBlocks'
import { IViewInput } from "../../api/views"
import { IDataInput } from "../../api/reference"


function NoDatasetsDisplay(props: { label: string }) {
	return (
		<Stack direction="row" alignItems="center" spacing={1}>
			<SelectAllIcon fontSize="large" sx={{ color: "text.disabled" }} />
			<Typography color="text.disabled" display="inline">Drag datasets here ({props.label})</Typography>
		</Stack>
	)
}


function DropDiv(props: {
	onDrop: (input: IDataInput) => void,
	children: JSX.Element
}) {

	const ItemTypes = {
		BOX: 'box',
	}

	const [{ canDrop, isOver }, drop] = useDrop(() => ({
		accept: ItemTypes.BOX,
		drop: (input: IDataInput, monitor) => {

			if (input != null) {
				props.onDrop(input);
			}
			return { name: 'DatasetDragTarget' };
		},
		collect: (monitor) => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop(),
		}),

	}))

	return (
		<Box ref={drop}
			className={canDrop && isOver ? "dropActive" : ""}
			sx={{
				'&.dropActive .dropBox': {
					backgroundColor: (theme) => alpha(theme.palette.action.disabledBackground, 0.15)
				},
			}}
		>
			{props.children}
		</Box>
	)

}




export default function ViewDataBlocks(props: {
	datasets: IViewInput[],
	onDrop: (input: IDataInput, axis: number) => void,
	handleRemove: (id: number) => void,
	showAxes: boolean
}) {

	var color: { [id: string]: string } = {};
	props.datasets.filter(dataset => dataset.type !== "event").forEach((dataset, index) => {
		color[String(dataset.id)] = getIndexColor(dataset.name, index);
	})

	props.datasets.filter(dataset => dataset.type === "event").forEach((dataset, index) => {
		color[String(dataset.id)] = "rgb(144 202 249 / 40%)";
	})

	let leftInputs: IViewInput[] = [];
	let rightInputs: IViewInput[] = [];

	if (props.showAxes) {
		leftInputs = props.datasets.filter(i => i.axis === 1);
		rightInputs = props.datasets.filter(i => i.axis === 2);
	} else {
		leftInputs = props.datasets;

	}


	return (

		<Stack
			direction="column"
			// justifyContent="flex-start"
			// alignItems="center"
			spacing={2}
			sx={{ ml: 2, mb: 2 }}

		>
			<Typography variant="subtitle1" sx={{ color: 'text.primary', fontWeight: 500 }}>
				Datasets:
			</Typography>

			<Box display="flex">

				<Box flexGrow={1} sx={{ mr: 1.5 }}>
					<DropDiv
						onDrop={(input: IDataInput) => {
							props.onDrop(input, 1);

						}}
					>

						<FeatureBlocks className="dropBox"
						>
							{leftInputs.map((dataset, index) => (

								<div key={index}>

									<FeatureCardNew
										id={dataset.id}
										name={dataset.name}
										color={color[String(dataset.id)]}
										handleRemove={props.handleRemove}
									/>

								</div>

							))}

							{leftInputs.length === 0 ?
								<NoDatasetsDisplay label="left axis" /> : <></>}

						</FeatureBlocks>

					</DropDiv>
				</Box>

				{props.showAxes &&

					<Box flexGrow={1} sx={{ ml: 1.5 }}>

						<DropDiv
							onDrop={(input: IDataInput) => {
								props.onDrop(input, 2);

							}}
						>

							<FeatureBlocks className="dropBox"
							>
								{rightInputs.map((dataset, index) => (

									<div key={dataset.id}  >

										<FeatureCardNew
											id={dataset.id}
											name={dataset.name}
											color={color[String(dataset.id)]}
											handleRemove={props.handleRemove}
										/>

									</div>

								))}

								{rightInputs.length === 0 ?
									<NoDatasetsDisplay label="right axis" /> : <></>}

							</FeatureBlocks>

						</DropDiv>

					</Box>
				}


			</Box>
		</Stack >

	);

};