import React from 'react';
import { alpha, Box, styled } from '@mui/system'
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { getIndexColor } from "../Charts/utils"
import CloseIcon from '@mui/icons-material/Close';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import { useDrop } from 'react-dnd';
import { DataInputDisplayName } from '../../utils/dataInput'

export const FeatureBlocks = styled(Box)(({ theme }) => ({
    // margin: "12px 12px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    backgroundColor: alpha(theme.palette.action.disabledBackground, 0.08),
    borderRadius: 2,
    minHeight: 80

}));

export const FeatureCard = styled('div')(({ theme }) => ({
    margin: 6,
    // minHeight: 60,
    // cursor: "pointer",
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 3,
    backgroundColor: theme.palette.card.background,
    borderLeft: "6px solid",
    // borderColor: theme.palette.info.main,
    // // borderRight: "$pt-intent-primary 8px solid",
    // width: 225,
    // maxWidth: 350,
    // textAlign: "center",
    // justifyContent: "center",

    // '&.info': {
    //     // padding: "5px 5px",
    //     fontWeight: 700
    // },
    '& .title': {
        color: theme.palette.text.primary,
        fontSize: 14,
        fontWeight: 500
    },
    '& .subtitle': {
        color: theme.palette.text.secondary,
        fontSize: 12,
        // fontWeight: 500
    },
    // '&.details': {
    //     color: grey[200],
    //     fontSize: 12
    // }
    // '&.success' {
    //     borderRight: "$pt-intent-success 8px solid"
    // }

    // '&.danger' {
    //     borderRight: "$pt-intent-danger 8px solid"
    // }
}))



export function FeatureCardNew(props:
    { id: number, name: string, color: string, handleRemove: (id: number) => void, disableRemove?: boolean }

) {

    return (
        <FeatureCard id={String(props.id)} style={{ borderColor: props.color, }}  >

            <Stack direction="row" flexWrap="nowrap" alignItems="center"
            >
                {/* <Typography className="title">{props.name}</Typography> */}
                <DataInputDisplayName name={props.name} />


                <IconButton
                    aria-label="close"
                    size="small"
                    onClick={() => props.handleRemove(props.id)}
                    disabled={props.disableRemove || false}

                >
                    <CloseIcon sx={{
                        fontSize: 14,
                        color: (theme) => theme.palette.grey[500],
                    }} />
                </IconButton>


            </Stack>
        </FeatureCard>


    )
}



export function NoDatasetsDisplay() {
    return (
        <Stack direction="row" alignItems="center" spacing={1}>
            <SelectAllIcon fontSize="large" sx={{ color: "text.disabled" }} />
            <Typography color="text.disabled" display="inline">Drag datasets here</Typography>
        </Stack>
    )
}
