import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import React, { ReactNode } from 'react';


export default function DialogChart(props: {
	title: string,
	children: ReactNode
	isOpen: boolean,
	handleClose: ((event: {}) => void)
}) {

	return (

		<Dialog open={props.isOpen} onClose={props.handleClose} fullWidth maxWidth='lg'

			PaperProps={{
				sx: {
					backgroundImage: 'none'
				}
			}}
		>
			<DialogTitle>
				<Typography color="primary" sx={{ fontSize: 18, fontWeight: 500 }}>{props.title}</Typography>
				<IconButton
					aria-label="close"
					onClick={props.handleClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent sx={{ display: 'flex', height: 600 }}>


				<div style={{ flexGrow: 1, height: '100%' }} >
					{props.children}
				</div>


			</DialogContent>


		</Dialog>




	);

}
