import React from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';



export default function ChartSelectMulti(props: {
	menuTitle: string,
	value: string[],
	setValue: (val: string[]) => void,
	options: { label: string, value: string }[],
}) {

	// get list of label options that are selected


	return (
		<FormControl size="small" sx={{}}>
			<Select
				multiple
				value={props.value}
				variant="outlined"
				// size="small"
				sx={{ mr: 1, fontSize: "small" }}
				input={<OutlinedInput />}
				renderValue={(selected) => {
					return `${selected.length} ${props.menuTitle}`;
				}}
				onChange={(event: SelectChangeEvent<string[]>) => {

					const {
						target: { value },
					} = event;

					props.setValue(typeof value === 'string' ? value.split(',') : value,);

				}}
				MenuProps={{
					style: {
						maxHeight: 400,
					},
				}}
			>
				<MenuItem disabled value="">
					<em>{props.menuTitle}</em>
				</MenuItem>

				{props.options.map((option) => {
					return (
						<MenuItem key={option.value} value={option.value}>
							<Checkbox checked={props.value.indexOf(option.value) > -1} />
							<ListItemText primary={option.label} />
						</MenuItem>
					)
				})}

			</Select>
		</FormControl>
	)
}
