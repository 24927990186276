import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import { useWatchlistCompaniesWithCoverageQuery, ICompanyWithCoverage } from "../../api/watchlist";
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router-dom';
import CompanyCoverageControls from "../CompanyCoverageControls/CompanyCoverageControls"


const filterOptions = (options: ICompanyWithCoverage[], { inputValue }: { inputValue: string }) => {
	const filteredOptions = options.filter((option) => {
		const symbol = option.symbol.toLowerCase();
		const name = option.name ? option.name.toLowerCase() : "";
		const input = inputValue.toLowerCase();
		return symbol.startsWith(input) || name.startsWith(input);
	});

	return filteredOptions.sort((a, b) => {
		const aSymbolMatch = a.symbol.toLowerCase().startsWith(inputValue.toLowerCase());
		const bSymbolMatch = b.symbol.toLowerCase().startsWith(inputValue.toLowerCase());

		if (aSymbolMatch && !bSymbolMatch) {
			return -1;
		}
		if (!aSymbolMatch && bSymbolMatch) {
			return 1;
		}
		return 0;
	});

}


export default function AppHeaderSearch() {

	const watchlistCompaniesWithCoverageQuery = useWatchlistCompaniesWithCoverageQuery();
	const minOpenLength = 1;
	const [inputValue, setInputValue] = useState<string>("");
	const navigate = useNavigate();

	function handleClose() {
		// clear input/value and close
		setInputValue("");
	}

	return (

		<Autocomplete
			loading={watchlistCompaniesWithCoverageQuery.isLoading}
			value={null}
			defaultValue={null}
			filterOptions={filterOptions}
			options={watchlistCompaniesWithCoverageQuery.data || []}
			getOptionLabel={(option: ICompanyWithCoverage) => option.symbol}
			inputValue={inputValue}
			onInputChange={(event, value) => {
				setInputValue(value || "");
			}}
			open={inputValue.length >= minOpenLength}
			onChange={(event, newValue) => {

				if (newValue) {

					if (newValue.has_trends) {
						navigate(`/company/${newValue.company_id}?display=trends`);
					} else if (newValue.has_models) {
						navigate(`/company/${newValue.company_id}?display=forecasts`);
					} else {
						navigate(`/company/${newValue.company_id}?display=views`);
					}
				}

				handleClose();

			}}

			isOptionEqualToValue={(option, value) => value.company_id === option.company_id}
			popupIcon={""}

			renderInput={(params) => (

				<TextField
					{...params}
					hiddenLabel
					placeholder='Search tickers...'
					variant="filled"
					color="info"
					InputProps={{
						...params.InputProps,
						disableUnderline: true,
						startAdornment: (<InputAdornment position="start"> <SearchIcon />  </InputAdornment>),
					}}
					sx={{
						width: 500,
						'.MuiInputBase-input': {
							width: '100%',
						},
					}}
				/>

			)}

			renderOption={(props, option) => (

				<Stack component="li" direction="row" spacing={1} {...props}>

					<Typography sx={{
						whiteSpace: "nowrap", minWidth: 55
					}} fontSize={15} color="text.primary">{option.symbol}</Typography>

					<Typography sx={{
						whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"
					}} fontSize={13} color="text.disabled">{option.name.substring(0, 40)}</Typography>

					<CompanyCoverageControls companyCoverage={option}
						onClickCallback={(e) => { e.stopPropagation(); handleClose(); }}
					/>

				</Stack>
			)}

		/>


	)
}

