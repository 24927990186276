
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { GridRenderCellParams } from '@mui/x-data-grid';
import React from 'react';
import { green, red, grey, blue, yellow } from '@mui/material/colors';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/system';
import clsx from 'clsx';



function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
	return (
		<Box sx={{ display: 'flex', alignItems: 'center' }}>
			<Box sx={{ width: '100%', mr: 1 }}>
				<LinearProgress variant="determinate" {...props} />
			</Box>
			<Box sx={{ minWidth: 35 }}>
				<Typography variant="body2" color="text.secondary">{`${Math.round(
					props.value,
				)}%`}</Typography>
			</Box>
		</Box>
	);
}


export function renderScoreCell(params: GridRenderCellParams) {

	if (params.value === undefined || params.value === null) {
		return <></>;
	}

	return (
		<Box sx={{ width: '100%' }}>
			<LinearProgressWithLabel value={(params.value * 100)} />
		</Box>
	)
}


const ProgressBarRoot = styled("div")(({ theme }) => ({
	border: `1px solid ${theme.palette.divider}`,
	position: "relative",
	overflow: "hidden",
	width: "100%",
	height: 26,
	borderRadius: 2
}))


const ProgressBarValue = styled("div")(({ theme }) => ({
	position: "absolute",
	lineHeight: "24px",
	width: "100%",
	display: "flex",
	justifyContent: "center"
}))

const ProgressBarHighlight = styled("div")(({ theme }) => ({
	height: "100%",
	"&.low": {
		backgroundColor: blue[500]
	},
	"&.medium": {
		backgroundColor: blue[500]
	},
	"&.high": {
		backgroundColor: blue[500]
	}
}))


const ProgressBar = React.memo(function ProgressBar(props: { value: number, fillPercent: number }) {

	const valueInPercent = props.value;

	return (
		<ProgressBarRoot>
			<ProgressBarValue>{`${valueInPercent} %`}</ProgressBarValue>
			<ProgressBarHighlight
				className={clsx({
					low: valueInPercent < 40,
					medium: valueInPercent >= 40 && valueInPercent <= 80,
					high: valueInPercent > 80
				})}
				style={{ maxWidth: `${props.fillPercent}%` }}
			/>
		</ProgressBarRoot>
	);
});



export function renderProgressCell(params: GridRenderCellParams) {

	if (params.value === undefined || params.value === null) {
		return <></>;
	}
	return <ProgressBar value={params.value} fillPercent={params.value} />;
}



export function renderHighlightPercentCell(params: GridRenderCellParams) {

	if (params.value === undefined || params.value === null) {
		return <></>;
	}

	let bgcolor: string = "inherit";
	let color: string = "white";

	if (params.value >= 1) {
		bgcolor = green[500];

	} else if (params.value <= -1) {
		bgcolor = red[500];
	} else if (params.value > 0) {
		color = green[500];
		// color = 'white';
	} else if (params.value < 0) {
		color = red[500];
		// color = red[300];
		// color = 'white';
	}

	return (
		<Box sx={{
			lineHeight: "24px",
			backgroundColor: bgcolor,
			width: "100%",
			paddingLeft: 1,
			paddingRight: 1,
			paddingTop: 0.4,
			paddingBottom: 0.4,
			display: "flex",
			justifyContent: "center",
			fontWeight: 600,
			// color: color,
			borderRadius: 0.5,
			color: color,

		}}>
			<Typography fontSize={13} fontWeight={600}>{params.value.toFixed(1)}%</Typography>

		</Box>
	);
}
