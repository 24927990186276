import {
    ViewChart
} from "../../components";
import React, { useState } from 'react';
import Grid, { GridProps } from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { getIndexColor } from "../../components/Charts/utils"
import { Box, alpha } from '@mui/system';
import { styled } from '@mui/material/styles';
import { ICompany } from "../../interfaces";
import { FixedSizeList } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer";
import { useViewUniverseCompaniesQuery, useViewQuery, PeriodLookbackType } from '../../api/views';


const ChartCard = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: theme.palette.card.background,
    border: "1px solid",
    borderColor: theme.palette.divider,

    height: "95%",
    width: '100%',
    flexGrow: 1,

}))


const chunks = (a: any[], size: number) =>
    Array.from(
        new Array(Math.ceil(a.length / size)),
        (_, i) => a.slice(i * size, i * size + size)
    );




function Chart(props: {
    title: string,
    view_id: number,
    company_id: number,
    periodLookback: PeriodLookbackType;

}) {

    return (

        <ChartCard>
            <ViewChart
                title={props.title}
                view_id={props.view_id}
                company_id={props.company_id}
                chartType='line'
                periodLookback={props.periodLookback}
                getDatasetChartType="line"
                getChartColor={getIndexColor}
                displayLegend={false}
                displayXAxis={false}
                displayYAxis={false}
                disableAnimations={true}
            />
        </ChartCard>


    )
}


function ChartRow(props: { style: React.CSSProperties, view_id: number, companies: ICompany[], periodLookback: PeriodLookbackType }) {

    return (

        // <Stack style={props.style} direction="row" display='flex' spacing={2} mb={2}>
        <Grid container style={props.style} direction="row" display='flex' spacing={2} mb={2}>
            {props.companies.map((value) => {

                return (
                    <Grid item xs={4} key={value.id} >
                        <Chart key={value.id} title={value.symbol} view_id={props.view_id} company_id={value.id} periodLookback={props.periodLookback} />
                    </Grid>
                )
            })}
        </Grid>

    )
}

const MemoChartRow = React.memo(ChartRow);


function CompactCharts(props: {
    view_id: number
}) {

    const viewUniverseCompanies = useViewUniverseCompaniesQuery(props.view_id);
    const viewQuery = useViewQuery(props.view_id);

    const data = chunks(viewUniverseCompanies.data || [], 3);
    const itemCount = data.length;

    if (viewQuery.isLoading || viewUniverseCompanies.isLoading) {
        return <></>
    }

    return (


        <AutoSizer>


            {({ height, width }) => (
                <FixedSizeList
                    itemData={data}
                    itemCount={itemCount}
                    itemSize={260} // height of each item (pixels)
                    height={height} // total height/width of the list
                    width={width}
                    overscanCount={1}

                >

                    {({ data, index, style }) => {
                        return <MemoChartRow key={index} style={style}
                            view_id={props.view_id} companies={data[index]} periodLookback={viewQuery.data?.period_lookback || "1y"}
                        />;
                    }}

                </FixedSizeList >
            )}
        </AutoSizer>
    );

}



export default CompactCharts;
