import { blue, grey, teal, cyan, deepPurple, purple, red, green, orange } from '@mui/material/colors';
import { alpha } from '@mui/system';


function getColorList() {

    type shadeType = 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
    var colorList: string[] = [];
    const baseColors = [cyan, deepPurple, red, green, orange, grey, teal];
    const shadeNums: shadeType[] = [300, 600, 400, 500]; // lowest next to highest for better contrast

    shadeNums.forEach((shade: shadeType) => {
        baseColors.forEach((color) => {
            colorList.push(color[shade]);
        })
    })

    return colorList

}




const colorList = getColorList();

export function getIndexColor(label: string, index: number) {
    return colorList[index % colorList.length];
}


export function getModelChartColor(label: string, index: number) {
    switch (label) {
        case "Reported":
            return green[400];
        case "Actual Surprise %":
            return purple[200];
        case "Consensus":
            return orange[400];
        case "Percent Error":
            return alpha(grey[600], 0.3);
        case "Price Impact %":
            return orange[300];
        default:
            return blue[400]
    }
}