import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Box, styled, alpha } from '@mui/system';
import React, { FC, useEffect, useState } from 'react';
import { useAlphasQuery, useSubAlphaOptionsQuery, IAlpha, ISubAlphaOptions, INewAlpha } from "../../api/alphas"
import LinearProgress from '@mui/material/LinearProgress';
import Grid from '@mui/material/Grid';
import { Divider } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TextField from '@mui/material/TextField';
import ButtonGroup from '@mui/material/ButtonGroup';
import { blue, grey, yellow, teal, cyan, deepPurple, purple, red, green, orange } from '@mui/material/colors';



const FeatureCard = styled('div')(({ theme }) => ({
	margin: "12px",
	cursor: "pointer",
	paddingTop: 8,
	paddingBottom: 8,
	paddingLeft: 10,
	paddingRight: 10,
	borderRadius: 5,
	backgroundColor: alpha(theme.palette.card.background, 0.73),
	border: "2px solid",
	// // borderRight: "$pt-intent-primary 8px solid",
	width: 330,
	minHeight: 80,

	'& .title': {
		color: theme.palette.text.primary,
		// fontSize: 16,
		fontWeight: 500
	},
	'& .subtitle': {
		color: theme.palette.text.secondary,
		// fontSize: 14,
		// fontWeight: 500
	},

	borderColor: grey[500],

	'&:hover': {
		borderColor: alpha(theme.palette.info.main, 0.50),
		boxShadow: `0 0 0 1px ${alpha(theme.palette.info.main, 0.50)}`,
	},


	'&.selected': {
		borderColor: theme.palette.info.main,
		backgroundColor: alpha(theme.palette.info.main, 0.07),
		boxShadow: `0 0 0 1px ${theme.palette.info.main}`,
		'& .title': {
			color: theme.palette.info.main,
		},

	}

}));



function SubAlphaSelect(props: {
	subAlphaOptions: ISubAlphaOptions,
	selectedSubAlphaId: number[],
	setSelectedSubAlphaId: React.Dispatch<React.SetStateAction<number[]>>,
}) {

	return (


		<Stack
			direction="row"
			justifyContent="flex-start"
			alignItems="center"
			spacing={2}
		>


			<Typography>{props.subAlphaOptions.selection_group}:</Typography>

			<FormControl sx={{ m: 1, width: 400 }}>

				<Select
					size="small"
					multiple
					displayEmpty
					value={props.selectedSubAlphaId}
					onChange={(event) => props.setSelectedSubAlphaId(event.target.value as number[])}
					input={<OutlinedInput />}
					MenuProps={{

						PaperProps: {
							style: {
								maxHeight: 300,
								width: 220,
								// maxHeight: 48 * 4.5 + 8,
								// width: 250,

							},
						},

					}}
					renderValue={(selected) => {
						if (selected.length === 0) {
							return <em>Select Parameter</em>;
						}

						// get field name from field_ids
						const selectedFields = props.subAlphaOptions.sub_alphas.filter((subAlpha) => selected.indexOf(subAlpha.sub_alpha_id) > -1).map((field) => field.name);

						return (
							<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
								{selectedFields.map((value) => (
									<Chip key={value} label={value} />
								))}
							</Box>
						);


					}}

				>
					<MenuItem disabled value="">
						<em>Select Parameter</em>
					</MenuItem>

					{props.subAlphaOptions.sub_alphas.map((subAlpha) => (
						<MenuItem dense
							key={subAlpha.sub_alpha_id} value={subAlpha.sub_alpha_id}>
							<Checkbox checked={props.selectedSubAlphaId.indexOf(subAlpha.sub_alpha_id) > -1} />
							<ListItemText primary={subAlpha.name} />
						</MenuItem>
					))}




				</Select>
			</FormControl>


		</Stack>


	);
}



function getAlphaGroupColor(group: string) {
	switch (group) {
		case "Technicals":
			return orange[300];
		case "Fundamentals":
			return blue[300];
		case "KPI Models":
			return green[300];
		case "Credit Card":
			return cyan[300];
		case "Crowding":
			return red[300];
		case "Ecommerce":
			return teal[300];
		case "Mobile App":
			return cyan[300];
		default:
			return grey[400]
	}
}



export default function AddFactorDialog(props: {
	onAddFactor: (newAlpha: INewAlpha) => void,
	isOpen: boolean,
	handleClose: ((event: {}, reason: string) => void),
	isLoading: boolean,
	setErrorMessage: (message: string) => void,
}) {


	// const { setErrorMessage, ErrorMessageAlert, setErrorMessageFromResponse } = useErrorMessageAlert();

	const [selectedAlpha, setSelectedAlpha] = useState<IAlpha | null>(null);
	const [selectedGroupFilter, setSelectedGroupFilter] = useState<string>("");
	const [selectedSubAlphaIds, setSelectedSubAlphaIds] = useState<number[]>([]);
	const [createdAlphaName, setCreatedAlphaName] = useState<string>("");
	const [factorSign, setFactorSign] = useState<number>(1);

	const alphasQuery = useAlphasQuery();
	const filteredAlphas = alphasQuery.data?.filter((alpha) => selectedGroupFilter === "" || alpha.group === selectedGroupFilter);
	const alphaGroups = alphasQuery.data?.map((alpha) => alpha.group).filter((value, index, self) => self.indexOf(value) === index);

	const subAlphaOptionsQuery = useSubAlphaOptionsQuery(selectedAlpha?.id ?? 0);

	// clear all selections when dialog is closed/open
	useEffect(() => {
		if (!props.isOpen) {
			setSelectedAlpha(null);
			setSelectedSubAlphaIds([]);
			setCreatedAlphaName("");
			setSelectedGroupFilter("");
			setFactorSign(1);
		}
	}, [props.isOpen]);

	function submitAddAlpha(event: {}) {
		props.setErrorMessage("");

		if (selectedSubAlphaIds.length === 0 || selectedAlpha === null) {
			props.setErrorMessage("Please select a parameter.");
			return;
		}

		if (createdAlphaName === "") {
			props.setErrorMessage("Please enter a name for the new factor.");
			return;
		}

		props.onAddFactor({
			name: createdAlphaName,
			factor_sign: factorSign,
			sub_alpha_ids: selectedSubAlphaIds
		});

	}

	function FactorCard(props: { factor: IAlpha }) {

		const chipColor = getAlphaGroupColor(props.factor.group);

		return (
			<FeatureCard
				// add selected class if this factor is selected
				className={selectedAlpha?.id === props.factor.id ? "selected" : ""}
				onClick={(event) => {
					// clear params selection
					setSelectedSubAlphaIds([]);
					setSelectedAlpha(props.factor);
					setCreatedAlphaName(props.factor.name);

				}}
			>
				<Box
					sx={{
						alignItems: 'center',
						flexWrap: 'wrap',

					}}>
					<Box>
						<Box display="flex" flexDirection={"row"} width="100%">
							<Box sx={{ flexGrow: 1 }}>
								<Typography sx={{ ml: 0 }} variant="subtitle1" className="title">{props.factor.name}</Typography>
							</Box>

							<Chip
								sx={{ color: chipColor, borderColor: chipColor }}
								variant="outlined" label={props.factor.group} size="small" />
						</Box>

						<Divider />

						<Typography
							variant="body2"
							sx={{
								fontSize: 12,
								mt: 0.5
							}}
							color="text.secondary" >
							{props.factor.description}
						</Typography>

					</Box>
				</Box>

				{/* <Typography variant="subtitle2" className="subtitle">{props.vendor_name}</Typography> */}

			</FeatureCard>
		)
	}

	return (
		<>
			<Dialog open={props.isOpen} onClose={props.handleClose} fullWidth maxWidth={selectedAlpha == null ? "xl" : "md"}

				PaperProps={{
					sx: {
						height: selectedAlpha == null ? 700 : 450,
						backgroundImage: 'none',

					}
				}}
			>
				<DialogTitle>
					<Box>
						Select Factor
						{/* <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
							Check/uncheck datasets to add or remove them from the combined KPI forecast model.
						</Typography> */}
					</Box>
					<IconButton
						aria-label="close"
						onClick={(event) => props.handleClose(event, 'buttonClose')}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<CloseIcon />
					</IconButton>

				</DialogTitle>


				<DialogContent>




					{selectedAlpha == null ?

						<>
							<Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 1 }}>
								<Tabs value={selectedGroupFilter}
									onChange={(event: React.SyntheticEvent, newValue: string) => {

										// clear any selection
										setSelectedSubAlphaIds([]);
										setSelectedAlpha(null);
										setCreatedAlphaName("");
										setFactorSign(1);

										setSelectedGroupFilter(newValue);
									}}
								>
									<Tab label="All" value="" />
									{alphaGroups?.map((group) => (
										<Tab key={group} label={group} value={group} />
									))}
								</Tabs>
							</Box>

							<Grid container>

								{filteredAlphas && filteredAlphas.map((factor, index) => (

									<Grid item xs={3} key={factor.id} style={{ display: 'flex' }} >

										<FactorCard factor={factor} />

									</Grid>

								))}
							</Grid>
						</>
						:

						<Box sx={{ mt: 1 }}>


							{(subAlphaOptionsQuery.isLoading || subAlphaOptionsQuery.isFetching) ?
								<Box sx={{ width: '100%' }}>
									<LinearProgress />
								</Box>

								:

								<Box sx={{ mt: 2 }}>

									<Typography variant="subtitle1" sx={{ fontWeight: 500, mb: 2 }}>Parameters</Typography>


									<Stack
										direction="row"
										justifyContent="flex-start"
										alignItems="center"
										spacing={2}
										sx={{ mb: 2 }}
									>

										<Typography>Name:</Typography>


										<TextField
											value={createdAlphaName}
											onChange={(event) => setCreatedAlphaName(event.target.value)}
											size="small"
											sx={{ minWidth: 300 }}
											variant="outlined"
										/>
									</Stack>


									<Stack
										direction="row"
										justifyContent="flex-start"
										alignItems="center"
										spacing={2}
										sx={{ mb: 2 }}

									>

										<Typography>Factor Sign&nbsp;&nbsp;&nbsp;</Typography>


										<ButtonGroup variant="outlined">
											<Button onClick={() => setFactorSign(1)} variant={factorSign === 1 ? "contained" : "outlined"}>Positive</Button>
											<Button onClick={() => setFactorSign(-1)} variant={factorSign === -1 ? "contained" : "outlined"}>Negative</Button>
										</ButtonGroup>


									</Stack >



									{(subAlphaOptionsQuery.data && subAlphaOptionsQuery.data.length > 0) &&

										<SubAlphaSelect subAlphaOptions={subAlphaOptionsQuery.data[0]}
											selectedSubAlphaId={selectedSubAlphaIds}
											setSelectedSubAlphaId={setSelectedSubAlphaIds}
										/>
									}


								</Box>
							}

						</Box>
					}

				</DialogContent>

				<DialogActions>
					{(props.isLoading) ?

						<Box sx={{ width: '100%', mb: 1 }}>
							<LinearProgress />
						</Box>
						:
						selectedAlpha !== null &&
						<>
							<Button sx={{ width: 100 }} variant="outlined" onClick={() => { setSelectedAlpha(null); }}>Back</Button>
							<Button
								disabled={props.isLoading || selectedSubAlphaIds.length === 0 || selectedAlpha === null || createdAlphaName === ""}
								sx={{ width: 100 }} variant="contained" onClick={(event) => { submitAddAlpha(event) }}>Add</Button>
						</>

					}
				</DialogActions>

			</Dialog >


		</>

	);

}
