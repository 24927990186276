import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import TreeItem, { treeItemClasses, TreeItemProps } from '@mui/lab/TreeItem';
import TreeView from '@mui/lab/TreeView';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { SvgIconProps } from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useDrag } from 'react-dnd';
import { DataSearchField } from "../../components";
import { IDataInputByCategory, IDataInput, useInputDataOptionsGroupedQuery } from "../../api/reference";
import { getCategoryIcon } from "../../utils/icons";
import { alpha } from '@mui/system'
import { AnyNsRecord } from 'dns';
import { IDataset } from '../../interfaces';


declare module 'react' {
    interface CSSProperties {
        '--tree-view-color'?: string;
        '--tree-view-bg-color'?: string;
    }
}

interface ISubFolder {
    name: string;
    inputs: IDataInput[];
}


type StyledTreeItemProps = TreeItemProps & {
    bgColor?: string;
    color?: string;
    labelIcon?: React.ElementType<SvgIconProps>;
    labelInfo?: string;
    labelText: string;
};


const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
    color: theme.palette.text.secondary,
    [`& .${treeItemClasses.content}`]: {
        paddingRight: 0,
    },

}));


function StyledTreeFolder(props: StyledTreeItemProps & { iconColor?: "inherit" | "primary" | "info" | "warning" | "secondary" }) {

    const {
        bgColor,
        color,
        labelIcon: LabelIcon,
        labelInfo,
        labelText,
        iconColor,
        ...other
    } = props;

    return (
        <StyledTreeItemRoot
            label={
                <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }}>
                    {LabelIcon && <LabelIcon color={iconColor || "inherit"} sx={{ mr: 1 }} />}
                    <Typography variant="body2" color={color} sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
                        {labelText}
                    </Typography>
                    <Typography variant="caption" color="inherit">
                        {labelInfo}
                    </Typography>
                </Box>
            }
            style={{
                '--tree-view-color': color,
                '--tree-view-bg-color': bgColor,
            }}
            {...other}
        />
    );
}


function StyledTreeItem(props: StyledTreeItemProps & { dataInput: IDataInput }) {
    const {
        dataInput,
        bgColor,
        color,
        labelIcon: LabelIcon,
        labelInfo,
        labelText,
        ...other
    } = props;

    const [{ isDragging }, drag] = useDrag(() => ({
        type: "box",
        item: dataInput,
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult<IDataInput>()
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
            handlerId: monitor.getHandlerId(),
        }),
    }))

    const opacity = isDragging ? 0.4 : 1;

    return (
        <StyledTreeItemRoot

            label={
                // <Button className="drag-item" ref={drag}>{labelText}</Button>
                <Box sx={{ p: 0.25, pr: 0, pl: 0 }} display="flex" alignItems="center">

                    <Button size="small"
                        ref={drag}
                        disableRipple
                        color="inherit"
                        startIcon={LabelIcon && <LabelIcon />}
                        sx={{
                            flexGrow: 1, justifyContent: "flex-start",
                            fontWeight: 400
                        }}
                    >
                        {/* <Box component={LabelIcon} color="primary" sx={{ mr: 1 }} /> */}

                        <Typography variant="body2"
                            sx={{
                                fontWeight: 'inherit', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"
                            }}>
                            {labelText}
                        </Typography>
                    </Button>

                    <Typography variant="caption" color="text.disabled" sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                        {labelInfo}
                    </Typography>

                </ Box>
            }
            style={{
                '--tree-view-color': color,
                '--tree-view-bg-color': bgColor,
                opacity
            }}
            {...other}
        />
    );
}


export default function DataSidebar(props: {
    onSearchSelect: (input: IDataInput) => void
}) {

    // query to get all data options
    const inputOptionsQuery = useInputDataOptionsGroupedQuery();

    // state for autocomplete (used to determine if tree selection is visible)
    const minOpenLength = 1;
    const [inputValue, setInputValue] = useState<string>("");
    const searchIsOpen = inputValue.length >= minOpenLength;

    function renderTree(item: IDataInput | ISubFolder, cat_idx: string) {

        if ('inputs' in item) {

            if (item.inputs.length > 0) {
                return (

                    <StyledTreeFolder
                        key={String(cat_idx) + "-" + String(item.name)}
                        nodeId={String(cat_idx) + "-" + String(item.name)}
                        labelText={item.name}
                        color={alpha("#ffff", 0.5)}
                    >
                        {item.inputs.map((node, item_idx) => renderTree(node, cat_idx + "-" + String(item_idx)))}

                    </StyledTreeFolder>

                )
            }

        } else {

            return (

                <StyledTreeItem
                    dataInput={item}
                    key={String(cat_idx) + String(item.name)}
                    nodeId={String(cat_idx) + String(item.name)}
                    labelText={item.title}
                    labelIcon={DragIndicatorIcon}
                    labelInfo={item.source_name}
                    color="#3c8039"
                />

            )

        }



    }


    if (!inputOptionsQuery.data) {
        return <></>
    }

    return (
        <Box >

            <DataSearchField inputValue={inputValue}
                setInputValue={setInputValue} minOpenLength={minOpenLength}
                onSearchSelect={props.onSearchSelect}
            />

            <Box sx={{
                height: "calc(100vh - 190px)",
                overflow: "hidden",
                overflowY: "auto",

                listStyle: "none",
                '&::-webkit-scrollbar': {
                    width: '0.4em'
                },
                '&::-webkit-scrollbar-track': {
                    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(0,0,0,.1)',
                    outline: '1px solid slategrey'
                }

            }}>

                <TreeView
                    // defaultExpanded={['3']}
                    defaultCollapseIcon={<ArrowDropDownIcon />}
                    defaultExpandIcon={<ArrowRightIcon />}
                    defaultEndIcon={<div style={{ width: 24 }} />}
                    sx={{

                        // hide when data search is open
                        visibility: searchIsOpen ? "hidden" : "visible",
                        opacity: searchIsOpen ? "0" : "1",
                        transition: "all .2s",

                        display: "flex",
                        flexDirection: "column",
                        paddingRight: 0,


                    }}
                >

                    {inputOptionsQuery.data.map((ds_category, cat_idx) => {

                        return (

                            <StyledTreeFolder
                                key={cat_idx}
                                nodeId={String('cat-' + String(cat_idx))}
                                labelText={ds_category.category} labelIcon={getCategoryIcon(ds_category.category)}
                                iconColor={ds_category.category === 'KPI Models' ? "primary" : ds_category.category === 'User Defined' ? "warning" : "inherit"}
                                color={ds_category.category === 'KPI Models' ? "primary" : ds_category.category === 'User Defined' ? "warning.light" : "inherit"}
                            >

                                {ds_category.data.map((data) => {


                                    if (ds_category.data.length > 1) {

                                        return (renderTree(data, String(cat_idx)))

                                    }
                                    else {

                                        return ds_category.data[0].inputs.map((node, item_idx) => renderTree(node, cat_idx + "-" + String(item_idx)));

                                    }


                                })}


                            </StyledTreeFolder>
                        )


                    })}
                </TreeView>
            </Box>
        </Box>
    );
}