import Grid, { GridProps } from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { blue, grey, teal, cyan, deepPurple, purple, red, green, orange } from '@mui/material/colors';
import { Box, alpha } from '@mui/system';
import React from 'react';
import { DataChart } from "../../components";
import { getPeriodLookbackChartParam, getPeriodCompareChartParam } from "../../components/Charts/chartSelectParams";
import { getIndexColor } from "../../components/Charts/utils"
import { QTDCompanyChart } from "../../components"
import { useWatchlistsByCompanyQuery } from "../../api/watchlist"
import { useUserSelectionQuery, useUpdateUserSelection } from "../../api/user_selection";
import { useTrendDataOptionsQuery } from "../../api/reference";


const CardItem = styled("div")(({ theme }) => ({
	// height: 470,
	height: "calc(50vh - 110px)",
	padding: theme.spacing(2),
	backgroundColor: theme.palette.card.background,
	border: "1px solid",
	borderColor: theme.palette.divider,
	borderRadius: 2
}))




export default function CompanyDataCharts(props: {
	parent_company_id: number,
	company_id: number,
	field_id: number,
	companySymbol: string

}) {

	// always lookup the watchlists by the parent company
	const watchlistsByCompanyQuery = useWatchlistsByCompanyQuery(props.parent_company_id);
	const defaultWatchlistId = watchlistsByCompanyQuery.data ? watchlistsByCompanyQuery.data[0]?.id : 0;
	const userSelectionQuery = useUserSelectionQuery(); // get saved user selections
	const updateUserSelection = useUpdateUserSelection(); // update saved user selections
	const trendDataOptionsQuery = useTrendDataOptionsQuery(props.parent_company_id);

	const selectedFieldName = trendDataOptionsQuery.data?.find((option) => option.id === props.field_id)?.name;

	if (props.company_id === 0 || props.field_id === 0) {
		return <></>
	}

	if (userSelectionQuery.data === undefined || userSelectionQuery.isLoading) {
		return <></>
	}


	return (

		<Box flexGrow={1}>
			< Grid container
				sx={{
					mt: 0, // note this computes to some margin ?? Don't remove.
				}}
				spacing={2}
			>

				<Grid item xs={6}>
					<CardItem>

						<QTDCompanyChart
							company_id={props.company_id}
							field_id={props.field_id}
							showExportButton={true}
							exportFileName={`${props.companySymbol} - ${selectedFieldName} - Quarter to Date`} />

					</CardItem>
				</Grid>

				<Grid item xs={6}>
					<CardItem>

						<DataChart
							title="Prior Year Comparison"
							chartType='line'
							dataUrl={`/trend-charts/year-comp-chart/${props.company_id}/${props.field_id}`}
							queryKey={['trend_charts', 'gr', props.company_id, props.field_id]}
							getDatasetChartType="line"
							getYAxisId="y"
							getChartColor={getIndexColor}
							showExportButton={true}
							exportFileName={`${props.companySymbol} - ${selectedFieldName} - Prior Year Comparison`}

							chartSelectParams={[
								{
									name: "num_weeks",
									title: "# Weeks Aggregated",
									defaultValue: String(userSelectionQuery.data['years_comp_num_weeks'] || 4),
									options: [
										{ label: "1 Wk", value: "1" },
										{ label: "2 Wk", value: "2" },
										{ label: "4 Wk", value: "4" },
										{ label: "6 Wk", value: "6" },
										{ label: "8 Wk", value: "8" },
										{ label: "10 Wk", value: "10" },
										{ label: "12 Wk", value: "12" },
									],

									onChange: (newValue) => {
										const selection = { years_comp_num_weeks: parseInt(newValue) };
										updateUserSelection.mutate(selection);
									}

								},

								{
									name: "num_years_comp",
									title: "# Comparison Years",
									defaultValue: String(userSelectionQuery.data['years_comp_num_years_comp'] || 3),
									options: [
										{ label: "1Y", value: "1" },
										{ label: "2Y", value: "2" },
										{ label: "3Y", value: "3" },
										{ label: "4Y", value: "4" },
										{ label: "5Y", value: "5" },
									],
									// when the user changes the selection, store in DB
									onChange: (newValue) => {
										const selection = { years_comp_num_years_comp: parseInt(newValue) };
										updateUserSelection.mutate(selection);
									}

								}


							]}
							disableAnimations={true}
						/>
					</CardItem>
				</Grid>



				<Grid item xs={6}>
					<CardItem>
						{/* dont show while loading/fetching watchlist, can cause issues when switching across tickers. */}
						{(watchlistsByCompanyQuery.data && watchlistsByCompanyQuery.data.length > 0 && defaultWatchlistId > 0 && !watchlistsByCompanyQuery.isLoading && !watchlistsByCompanyQuery.isFetching) &&
							<DataChart
								title="Peer Comparison"
								chartType='line'
								xAxisDate={true}
								showExportButton={true}
								exportFileName={`${props.companySymbol} - ${selectedFieldName} - Peer Comparison`}

								dataUrl={`/trend-charts/gr-peers-chart-data/${props.company_id}/${props.field_id}`}
								queryKey={['trend_charts', 'gr_peers', props.company_id, props.field_id]}
								getDatasetChartType="line"
								// getYAxisId="y"
								getChartColor={(label, index) => {
									if (label === "Spread") {
										return alpha(grey[600], 0.3);
									} else {
										return getIndexColor(label, index)
									}

								}}

								// getDatasetChartType={(label, index) => { return (label !== "Spread" ? 'line' : 'bar') }}
								getYAxisId={(label, index) => { return (label !== "Spread" ? 'y' : 'y2') }}

								chartSelectParams={[
									{
										name: "num_weeks",
										title: "# Weeks Aggregated",
										defaultValue: String(4),
										options: [
											{ label: "1 Wk", value: "1" },
											{ label: "2 Wk", value: "2" },
											{ label: "4 Wk", value: "4" },
											{ label: "6 Wk", value: "6" },
											{ label: "8 Wk", value: "8" },
											{ label: "10 Wk", value: "10" },
											{ label: "12 Wk", value: "12" },
										]
									},

									getPeriodLookbackChartParam("6m"),
									getPeriodCompareChartParam("yoy", false),

									{
										name: "weight",
										title: "Group Weighting",
										defaultValue: "equal",
										options: [
											{ label: "Equal Weight", value: "equal" },
											{ label: "MktCap Weight", value: "market_cap" },
											{ label: "Equal Weight - Median", value: "median" },
										]
									},

									{
										name: "watchlist_id",
										title: "Watchlist",
										defaultValue: String(defaultWatchlistId),
										options: watchlistsByCompanyQuery.data.map((watchlist) => {
											return { label: watchlist.name, value: String(watchlist.id) }
										})

									}


								]}
								disableAnimations={true}

							/>
						}
					</CardItem>
				</Grid>


				<Grid item xs={6}>
					<CardItem>

						<DataChart
							title="Growth Rate - 4Wk vs 13Wk"
							chartType='line'
							xAxisDate={true}
							showExportButton={true}
							exportFileName={`${props.companySymbol} - ${selectedFieldName}- Growth Rates`}

							dataUrl={`/trend-charts/gr-short-vs-long-chart-data/${props.company_id}/${props.field_id}`}
							queryKey={['trend_charts', 'gr_long_short', props.company_id, props.field_id]}
							getDatasetChartType="line"
							getYAxisId="y"
							getChartColor={(label, index) => {
								if (index === 0) {
									return cyan[300];
								}
								else if (index === 1) {
									return orange[300];
								}
								return grey[300];
							}}

							chartSelectParams={[
								getPeriodLookbackChartParam("6m"),
								getPeriodCompareChartParam("yoy", false),
							]}
							disableAnimations={true}

						/>
					</CardItem>
				</Grid>


			</Grid >
		</Box >


	)

}