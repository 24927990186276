import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import { Box } from '@mui/system';
import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { CompanyModelCharts, CompanyModelSelections, CompanyDataCharts, CompanyViewsCharts, CompanyDataSelections } from "../components";
import { useWatchlistCompaniesWithCoverageQuery, ICompanyWithCoverage } from "../api/watchlist";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';



type companyPageType = "trends" | "forecasts" | "views";

const filterOptions = createFilterOptions({
	matchFrom: 'start',
	stringify: (option: ICompanyWithCoverage) => option.symbol,
});


function PageTab(props: {
	value: companyPageType,
	disabled: { [key in companyPageType]: boolean },
}) {

	const [searchParams, setSearchParams] = useSearchParams();

	const handleChange = (
		event: React.MouseEvent<HTMLElement>,
		newValue: companyPageType,
	) => {
		if (newValue) {
			searchParams.set("display", newValue);
			setSearchParams(searchParams);
		}
	};
	return (

		<ToggleButtonGroup
			color="info"
			value={props.value}
			exclusive
			onChange={handleChange}
			size="large"

			sx={{
				// bgcolor: "action.disabledBackground",
				bgcolor: "card.background",
				// make button padding smaller
				"& .MuiToggleButton-root": {
					pl: 1,
					pr: 1,
					pt: 0.75,
					pb: 0.75,
					width: 110,
					borderRadius: 0,
				},
			}}

		>
			<ToggleButton disabled={props.disabled.trends} value="trends">Trends</ToggleButton>
			<ToggleButton disabled={props.disabled.forecasts} value="forecasts">Forecasts</ToggleButton>
			<ToggleButton disabled={props.disabled.views} value="views">My Views</ToggleButton>

		</ToggleButtonGroup>

	)
}



export default function Company() {

	const navigate = useNavigate();
	const urlParams = useParams();
	const [searchParams, setSearchParams] = useSearchParams();

	const displaySelect: companyPageType = searchParams.get("display") as companyPageType || "trends";  // default to trends view

	const company_id = (urlParams.company_id != undefined) ? parseInt(urlParams.company_id) : 0;
	const metric_id: number = parseInt(searchParams.get("metric") || "0");

	const trend_field_id: number = parseInt(searchParams.get("trend_field") || "0");
	const segment_id: number = searchParams.get("segment") ? parseInt(searchParams.get("segment") || "0") : company_id; // default to parent company

	const model_input_id: number = parseInt(searchParams.get("model_input") || "0");
	const model_family_id: number = parseInt(searchParams.get("model_family") || "0");

	//queries
	const watchlistCompaniesWithCoverage = useWatchlistCompaniesWithCoverageQuery();
	const selectedCompany = watchlistCompaniesWithCoverage.data?.find((i) => i.company_id === company_id);

	if (watchlistCompaniesWithCoverage.isLoading || watchlistCompaniesWithCoverage.isFetching) {
		return (
			<Box sx={{ width: '100%' }}>
				<LinearProgress />
			</Box>
		);
	}


	if (!selectedCompany) {

		if (watchlistCompaniesWithCoverage.data && watchlistCompaniesWithCoverage.data.length > 0) {
			return (
				<Box sx={{ width: '100%', p: 3 }}>
					<Typography variant='h5'>Company not found. Try adding this company to a watchlist.</Typography>
				</Box>
			);
		}

		return (
			// possibly an error. Decide what to do here
			<></>
		);


	}


	return (

		<Box sx={{

			marginTop: 2,
			marginLeft: 2,
			marginRight: 2,
		}
		} >



			<Stack direction="row" alignItems="center" spacing={2}
				sx={{
					mt: 1.5,
					padding: (theme) => theme.spacing(1.5),
					backgroundColor: (theme) => theme.palette.card.background,
					border: "1px solid",
					borderColor: (theme) => theme.palette.divider,
					borderRadius: 2

				}}


			>

				{selectedCompany &&

					<div>
						<Autocomplete
							disableClearable
							filterOptions={filterOptions}
							options={watchlistCompaniesWithCoverage.data || []}
							getOptionLabel={(option) => option.symbol}
							value={selectedCompany}
							onChange={(event, newValue) => {

								// if redirecting to a ticker and they have that data, go to that ticker page/display
								if ((displaySelect === "forecasts" && newValue.has_models)
									|| (displaySelect === "trends" && newValue.has_trends)
									|| (displaySelect === "views" && newValue.has_views)
								) {

									var searchParams = new URLSearchParams();
									searchParams.set("display", displaySelect);
									// add search params to keep data selection
									if (displaySelect === "forecasts") {
										searchParams.set("model_input", String(model_input_id));
										searchParams.set("model_family", String(model_family_id));
									} else if (displaySelect === "trends") {
										searchParams.set("trend_field", String(trend_field_id));
									}

									navigate({
										pathname: `/company/${newValue.company_id}`,
										search: searchParams.toString()
									});

									// otherwise go to the first available display
								} else {

									if (newValue.has_trends) {
										navigate({
											pathname: `/company/${newValue.company_id}`,
											search: `?display=trends`
										});
									} else if (newValue.has_models) {
										navigate({
											pathname: `/company/${newValue.company_id}`,
											search: `?display=forecasts`
										});
									} else {
										navigate(`/company/${newValue.company_id}?display=views`);
									}

								}

							}}

							isOptionEqualToValue={(option, value) => value.company_id === option.company_id}
							ChipProps={{ deleteIcon: <></> }}
							renderInput={(params) => (
								<>
									<TextField
										{...params}
										hiddenLabel
										// label={"Ticker"}
										variant="filled"
										size="small"
										color="info"
										sx={{
											width: 170,
											'.MuiInputBase-input': {
												fontSize: '1.60rem',
											},
										}}
									/>
								</>

							)}
						/>

						<Typography color="text.primary" fontSize={12} maxWidth={200} textOverflow={"ellipsis"} noWrap={true}  > {selectedCompany?.name || ""}</Typography>
					</div>
				}

				<PageTab value={displaySelect}
					disabled={{
						trends: selectedCompany?.has_trends === false,
						forecasts: selectedCompany?.has_models === false,
						views: selectedCompany?.has_views === false,
					}} />



				{(displaySelect === 'trends') && <CompanyDataSelections parent_company_id={company_id} />}
				{(displaySelect === 'forecasts') && <CompanyModelSelections company_id={company_id} company_symbol={selectedCompany.symbol} />}


			</Stack >

			{displaySelect === 'forecasts' && <CompanyModelCharts company_id={company_id} metric_id={metric_id} field_id={model_input_id} model_family_id={model_family_id} companySymbol={selectedCompany.symbol} />}
			{displaySelect === 'trends' && <CompanyDataCharts parent_company_id={company_id} company_id={segment_id} field_id={trend_field_id} companySymbol={selectedCompany.symbol} />}
			{displaySelect === 'views' && <CompanyViewsCharts company_id={company_id} />}

		</Box >

	);

};
