import React, { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { QTDCompanyChart } from "../../components"
import { useWatchlistCompaniesWithCoverageQuery, useWatchlistCompaniesQuery } from "../../api/watchlist";
import { useTrendDataOptionsQuery } from "../../api/reference";



export default function QTDChart(props: {
	ToolbarComponent?: JSX.Element,
	watchlist_id?: number, // if provided, filter the list of companies to this watchlist only
}
) {

	// QTD Charts, adding a dropdown select for the Comany and Field (to be used in main Dashboard)
	const watchlistCompaniesWithCoverageQuery = useWatchlistCompaniesWithCoverageQuery();
	const watchlistCompaniesQuery = useWatchlistCompaniesQuery(props.watchlist_id || 0)

	const comapnyIdFilter = props.watchlist_id ? watchlistCompaniesQuery.data?.map((company) => company.id) : [];

	// filter only companies where has_trends is true
	let companiesData = watchlistCompaniesWithCoverageQuery.data ? watchlistCompaniesWithCoverageQuery.data?.filter((company) => company.has_trends) || [] : [];

	if (comapnyIdFilter && comapnyIdFilter.length > 0) {
		companiesData = companiesData.filter((company) => comapnyIdFilter.includes(company.company_id));
	}

	const [selectedCompanyId, setSelectedCompanyId] = useState<number>(0);
	const [selectedFieldId, setSelectedFieldId] = useState<number>(0);

	const trendDataOptionsQuery = useTrendDataOptionsQuery(selectedCompanyId);

	// once we get the companies data, set default company_id
	useEffect(() => {

		if (companiesData && companiesData.length > 0) {

			const companyExists = companiesData.some((el: any) => el.company_id === selectedCompanyId);

			if (selectedCompanyId == 0 || !companyExists) {
				setSelectedCompanyId(companiesData[0].company_id);
			}

		}
	}, [companiesData, props.watchlist_id])

	useEffect(() => {

		const fieldsData = trendDataOptionsQuery.data;

		if (fieldsData && fieldsData.length > 0) {

			const fieldExists = fieldsData.some((el: any) => el.id === selectedFieldId);

			if (selectedFieldId == 0 || !fieldExists) {
				setSelectedFieldId(fieldsData[0].id);
			}

		}
	}, [companiesData])

	// TODO: handle if no models, Dashboard should still load
	if (watchlistCompaniesWithCoverageQuery.isLoading || !companiesData || trendDataOptionsQuery.isLoading || !trendDataOptionsQuery.data) {
		return (
			<Box sx={{ width: '100%' }}>
				<LinearProgress />
			</Box>
		)
	}

	const selectedCompany = companiesData.find((company) => company.company_id === selectedCompanyId);
	const selectedField = trendDataOptionsQuery.data.find((field) => field.id === selectedFieldId);

	if (!selectedCompany || selectedCompanyId == 0 || selectedFieldId == 0 || !selectedField) {
		return <></>;
	}

	function CompanyFieldSelect() {
		return (
			<Stack direction="row" spacing={1} sx={{ mr: 1 }}>

				<Autocomplete
					options={companiesData}
					getOptionLabel={(option) => option.symbol || ""}
					isOptionEqualToValue={(option, value) => option.company_id === value.company_id}
					filterSelectedOptions
					disableClearable
					size="small"
					sx={{
						input: {
							fontSize: "small",
						},
					}}
					componentsProps={{
						paper: {
							sx: {
								width: 110
							}
						}
					}}
					defaultValue={selectedCompany}
					onChange={(event: React.SyntheticEvent, newValue) => {
						if (newValue) {
							setSelectedCompanyId(newValue.company_id);
						}
					}}
					renderInput={(params) => (
						<TextField
							sx={{ width: 100, fontSize: "9", lineHeight: "1.2" }}
							{...params}
						/>
					)}

					renderOption={(props, option) => (
						<Stack component="li" direction="row" spacing={1}   {...props}

						// make the dropdown list, wider, but don't show with scrollbar

						>
							<Typography sx={{
								whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis",
							}} fontSize={12} color="text.primary">{option.symbol}</Typography>

						</Stack>
					)}

				/>


				<Autocomplete
					disableClearable
					options={trendDataOptionsQuery.data || []}
					getOptionLabel={(option) => option.name}
					value={selectedField}
					onChange={(event, newValue) => {
						setSelectedFieldId(newValue.id);
					}}
					groupBy={(option) => option.vendor_name}

					isOptionEqualToValue={(option, value) => value.id === option.id}
					ChipProps={{ deleteIcon: <></> }}

					size="small"
					sx={{
						// mr: 0.5,
						// width: 80,
						input: {
							fontSize: "small",
						},
					}}

					componentsProps={{
						paper:
						{
							style: {
								width: 'fit-content',
								maxWidth: 300,
								minWidth: 150,

							}
						}
					}}


					renderInput={(params) => (
						<>
							<TextField
								{...params}
								hiddenLabel
								color="info"
								sx={{
									minWidth: 130,
									fontSize: "small",

									'.MuiInputBase-input': {
										fontSize: 'small',
									},
								}}
							/>
						</>

					)}

					renderOption={(props, option) => (
						<Stack component="li" direction="row"   {...props}>
							<Typography sx={{
								whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis",
							}} fontSize={12} color="text.primary">{option.name}</Typography>

						</Stack>
					)}

				/>



			</Stack>
		)
	}

	function QTDToolBar() {
		return (
			<>
				<CompanyFieldSelect />
				{props.ToolbarComponent}

			</>
		)
	}


	return (

		<QTDCompanyChart company_id={selectedCompanyId} field_id={selectedFieldId} numQuartersDefault={3} ToolbarComponent={<QTDToolBar />} />

	);
}


