import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';


export default function TickerChartSelect(props: {
	selectedCompanies: { id: number, symbol: string }[],
	tabValue: number,
	setTabValue: React.Dispatch<React.SetStateAction<number>>,
	fontSize?: string,
}) {

	const displayCompany = props.selectedCompanies.find(i => i.id === props.tabValue);
	const [searchParams, setSearchParams] = useSearchParams();

	if (displayCompany !== undefined) {
		return (

			<Autocomplete
				options={props.selectedCompanies}
				getOptionLabel={(option) => option.symbol || ""}
				isOptionEqualToValue={(option, value) => option.id === value.id}
				filterSelectedOptions
				disableClearable
				size="small"
				sx={{
					width: props.fontSize === "small" ? 175 : 200,
					input: {
						fontSize: props.fontSize || "medium",
					},
				}}
				componentsProps={{
					paper: {
						sx: {
							minWidth: 200
						}
					}
				}}
				value={displayCompany}
				onChange={(event: React.SyntheticEvent, newValue) => {
					if (newValue) {
						props.setTabValue(newValue.id);

						// set search param so we save the ticker selection
						searchParams.set("company", String(newValue.id));
						setSearchParams(searchParams);
					}
				}}
				renderInput={(params) => (
					<TextField
						{...params}
						placeholder="Select ticker"
					// size="small"
					// hiddenLabel
					// variant="filled"
					/>
				)}
			/>
		)

	}

	return (
		<></>
	)

}

