import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import React, { useState } from 'react';
import { useScreenerInputsQuery, useAddScreenerInput } from "../../api/screener"
import DataSearchField from "../Sidebar/DataSearchField"
import { IDataInput, useInputDataOptionsQuery } from '../../api/reference';
import {
	DatasetDialog,
} from "../../components";
import { AxiosError } from 'axios'
import { Box, styled } from '@mui/system';
import { DataGrid, GridColDef, GridRenderCellParams, GridSelectionModel, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { getCategoryIcon } from "../../utils/icons";
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AddIcon from '@mui/icons-material/Add';
import { ConstructionOutlined } from '@mui/icons-material';

const InputSelectDataGrid = styled(DataGrid)(({ theme }) => ({

	'& .MuiDataGrid-cell:focus-within': {
		outline: 'none'
	},

}));



function getCategoryIconComponent(categoryName: string) {
	const Icon = getCategoryIcon(categoryName);
	return <Icon color="primary" />;

}

function renderCategoryCell(params: GridRenderCellParams) {

	return (
		<Stack direction="row" spacing={2}>
			{getCategoryIconComponent(params.value)}
			<Typography>{params.value}</Typography>
		</Stack>
	)

}





export default function ScreenerAddDataDialog(props: {
	screener_id: number,
	isOpen: boolean,
	handleClose: ((event: {}, reason: string) => void)
}) {

	const addScreenerInput = useAddScreenerInput(props.screener_id);
	const inputOptionsQuery = useInputDataOptionsQuery();
	const [datasetDialog, setDatasetDialog] = useState<{ isOpen: boolean, input?: IDataInput, error?: string }>({ isOpen: false });
	const [selectedGroupFilter, setSelectedGroupFilter] = useState<string>("");

	function handleAddInput(input: IDataInput) {
		if (input.type === "user") {
			addScreenerInput.mutate({ underlying_id: input.underlying_id, name: input.name, type: input.type });
			props.handleClose({}, "buttonSubmit");

		} else {
			setDatasetDialog({ isOpen: true, input: input });
		}

	}


	function CustomToolbar() {
		return (
			<GridToolbarContainer sx={{ mb: 1 }}>
				<GridToolbarQuickFilter
					debounceMs={500}
					// variant="outlined"
					placeholder="Search all datasets ..."
					// fullWidth={true}
					sx={{ width: "99%" }}
					autoComplete='off'
				/>

			</GridToolbarContainer>
		);
	}


	function renderAddButton(params: GridRenderCellParams) {
		return (
			<IconButton
				sx={{
					color: "success.light", border: "1px solid",
					borderColor: "success.light", padding: 0.75
				}}
				onClick={() => {
					handleAddInput(params.row as IDataInput);

				}} ><AddIcon fontSize='small' /></IconButton>
		);
	}

	const columns: GridColDef[] = [
		{ field: 'add', headerName: '', flex: 0, renderCell: renderAddButton },
		{ field: 'source_name', headerName: 'Source', flex: 1 },
		{ field: 'title', headerName: 'Data Field', flex: 1 },
		{ field: 'name', headerName: 'Field ID', flex: 1 },
		{ field: 'group', headerName: 'Data Type', flex: 1, renderCell: renderCategoryCell },

	];


	const tableData = inputOptionsQuery.data?.map((x, i) => ({
		...x,
		id: i,
		source_name: (x.type == "user") ? "User" : x.source_name,

	})).filter(x => ((x.type === "data_field" || x.type === "user") && x.group !== "Macroeconomic")).
		sort(function (a, b) {
			return a.group.localeCompare(b.group) || a.name.localeCompare(b.name);
		});

	// get all unique groups
	const allDataGroups = Array.from(new Set(tableData?.map(x => x.group))).sort();

	return (
		<>
			<Dialog open={props.isOpen} onClose={props.handleClose}
				fullWidth maxWidth='lg'

				PaperProps={{
					sx: {
						// minHeight: 800,
						backgroundImage: 'none'
					}
				}}
			>
				<DialogTitle>
					<Box>
						Select Data to Add to Screener

					</Box>
					<IconButton
						aria-label="close"
						onClick={(event) => props.handleClose(event, 'buttonClose')}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<CloseIcon />
					</IconButton>

				</DialogTitle>


				<DialogContent>

					<Box sx={{ mb: 1 }}>
						<Tabs
							value={selectedGroupFilter}
							scrollButtons="auto"
							variant="scrollable"
							onChange={(event: React.SyntheticEvent, newValue: string) => {

								setSelectedGroupFilter(newValue);
							}}
						>
							<Tab label="All" value="" />
							{allDataGroups?.map((group) => (
								<Tab key={group} label={group} value={group} />
							))}


						</Tabs>
					</Box>

					<Box sx={{ display: 'flex', height: 600 }}>
						<div style={{ flexGrow: 1 }}>

							<InputSelectDataGrid
								hideFooterSelectedRowCount={true}
								loading={inputOptionsQuery.isLoading}
								rows={tableData?.filter(x => selectedGroupFilter === "" || x.group === selectedGroupFilter) || []}
								columns={columns}
								pageSize={100}
								rowsPerPageOptions={[100]}
								// onSelectionModelChange={(newSelectionModel) => {
								// 	setSelectionModel(newSelectionModel);
								// }}
								// selectionModel={selectionModel}
								components={{ Toolbar: CustomToolbar }}
							// density="compact"
							/>


						</div>
					</Box>

					{/* <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 2, mb: 2, ml: 5, mr: 5 }}>
						{screenerAlphas.data?.map((alpha) => (
							<Chip
								key={alpha.id}
								label={alpha.name}
								onDelete={() => removeScreenerAlpha.mutate(alpha.id)}
							/>
						))}
					</Box> */}

				</DialogContent>

				{/* <DialogActions>
					<Button sx={{ width: 100 }} variant="outlined"
						onClick={(event) => { props.handleClose(event, "buttonCancel"); }}>Ok</Button>
				</DialogActions> */}

			</Dialog >

			{
				(datasetDialog.isOpen && props.screener_id !== 0 && datasetDialog.input) &&
				<DatasetDialog
					tableType="screener"
					isOpen={datasetDialog.isOpen}
					handleClose={() => setDatasetDialog({ isOpen: false })}
					dataInput={datasetDialog.input}
					errorMsg={datasetDialog.error}
					addInput={
						(addedInput) => addScreenerInput.mutate(addedInput, {
							onSuccess: () => {
								setDatasetDialog({ isOpen: false });
								props.handleClose({}, "buttonSubmit");
							},
							onError: (error: any | AxiosError) => {
								const errorMsg = "There was an issue adding this input. Please try again.";
								console.log(error)
								setDatasetDialog({ ...datasetDialog, error: errorMsg });
							}
						})
					}
				/>
			}


		</>

	);

}
